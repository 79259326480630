import { gql } from "@apollo/client";

export interface NumbersCountDictionary {
  key: number;
  value: number;
}

export interface GamesReport {
  averageActivePlayerCount: number;
  averageBronzeCount: number;
  averageCreatedTimeHours: number;
  averageDurationHours: number;
  averageDurationSeconds: number;
  averageForfeitPlayersRevenues: number;
  averageFreeCount: number;
  averageGameCreatorRevenues: number;
  averageGoldCount: number;
  averageMaxPlayersCount: number;
  averageMinPlayersCount: number;
  averagePlayersCount: number;
  averageRoyalty: number;
  averageSilverCount: number;
  averageStartHours: number;
  averageSystemFees: number;
  averageTotalGameRevenue: number;
  canceledCount: number;
  completeCount: number;
  createdCount: number;
  featuredGamesCount: number;
  numbersCountDictionary: NumbersCountDictionary[];
  privateGamesCount: number;
  startedCount: number;
  totalGamesCount: number;
}

export const GAMES_REPORT = gql`
  query Report($startTime: DateTime!, $endTime: DateTime!) {
    report(startTime: $startTime, endTime: $endTime) {
      averageActivePlayerCount
      averageBronzeCount
      averageCreatedTimeHours
      averageDurationHours
      averageDurationSeconds
      averageForfeitPlayersRevenues
      averageFreeCount
      averageGameCreatorRevenues
      averageGoldCount
      averageMaxPlayersCount
      averageMinPlayersCount
      averagePlayersCount
      averageRoyalty
      averageSilverCount
      averageStartHours
      averageSystemFees
      averageTotalGameRevenue
      canceledCount
      completeCount
      createdCount
      featuredGamesCount
      numbersCountDictionary {
        key
        value
      }
      privateGamesCount
      startedCount
      totalGamesCount
    }
  }
`;
