import React from "react";
import {
  matchPath,
  NavLink,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useStore } from "effector-react";
import { BsChevronLeft } from "react-icons/bs";
import { useTranslation } from "react-i18next";

import Collections from "src/admin/collections";
import Games from "src/admin/games";
import { USERS } from "src/graphQl/users";
import { $isAuthChecked, $loggedIn } from "src/store/profile";
import PrivateRoute from "src/routes/PrivateRoute";
import "./index.scss";
import Layout from "src/components/common/Layout";
import Transactions from "./transactions";
import Loader from "src/components/common/Loader";
import RangeDatePicker from "src/components/common/Rangepicker";
import { $dateRange, setDateRange } from "src/store/admin";
import GameAnalytics from "./GameAnalitycs";
import GamesAnalytics from "./GamesAnalitycs";

const Admin = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const isAuthChecked = useStore($isAuthChecked);
  const loggedIn = useStore($loggedIn);
  const account = window?.ethereum?.selectedAddress;
  const dateRange = useStore($dateRange);
  const [startDate, endDate] = dateRange;
  const { pathname } = useLocation();

  const { loading, data } = useQuery(USERS, {
    variables: {
      where: {
        address: { eq: account },
      },
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    // skip: !account,
  });

  const accountInfo = data?.users?.nodes?.[0];

  const isCollectionsPage = matchPath("/admin", pathname);
  const isGameAnalyticPage = matchPath(pathname, {
    path: "/admin/game/:gameId/analytics",
    exact: true,
    strict: false,
  });

  return (
    <Layout className="gradient-layout">
      <div className="admin">
        {isGameAnalyticPage && (
          <div className="admin__back-btn" onClick={() => history.goBack()}>
            <BsChevronLeft />
            Back to Games
          </div>
        )}
        {!isGameAnalyticPage && (
          <div className="admin__nav-tabs">
            <NavLink
              className="admin__nav-tab"
              activeClassName="admin__nav-tab_selected"
              to="/admin"
              exact
            >
              Collections
            </NavLink>
            <NavLink
              className="admin__nav-tab"
              activeClassName="admin__nav-tab_selected"
              to="/admin/games"
              exact
            >
              Games
            </NavLink>
            <NavLink
              className="admin__nav-tab"
              activeClassName="admin__nav-tab_selected"
              to="/admin/transactions"
              exact
            >
              Transactions
            </NavLink>
            <NavLink
              className="admin__nav-tab"
              activeClassName="admin__nav-tab_selected"
              to="/admin/games/analytics"
              exact
            >
              Games analytics
            </NavLink>
          </div>
        )}
        {!isCollectionsPage && !isGameAnalyticPage && (
          <RangeDatePicker
            startDate={startDate}
            endDate={endDate}
            onChange={range => setDateRange(range)}
            dateFormat="dd MMM yyyy"
            wrapperClassName="admin__range-wrapper"
            placeholder={t("admin.dateRangePlaceholder")}
            isClearable
            className="admin__range"
            isButton
          />
        )}
        <Switch>
          <PrivateRoute
            isAllowed={
              account &&
              isAuthChecked &&
              loggedIn &&
              accountInfo?.roles?.includes("admin")
            }
            loading={loading || !isAuthChecked}
            loader={<Loader className="page-loader" />}
          >
            <Route exact path="/admin" render={() => <Collections isAdmin />} />
            <Route exact path="/admin/games" component={Games} />
            <Route
              path="/admin/game/:gameId/analytics"
              component={GameAnalytics}
            />
            <Route path="/admin/games/analytics" component={GamesAnalytics} />
            <Route path="/admin/transactions" component={Transactions} />
          </PrivateRoute>
        </Switch>
      </div>
    </Layout>
  );
};

export default Admin;
