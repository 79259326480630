import React, { FC, useEffect, useState } from "react";
import cs from "classnames";
import { useStore } from "effector-react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete } from "react-icons/ai";

import "./index.scss";
import Button from "../../common/Button";
import FileDropzone from "../../common/FileDropzone";
import { deleteAvatar, register } from "src/store/profile";
import { TFileWithPreview } from "src/types/common";
import { $account } from "src/store/wallet";
import { USERS } from "src/graphQl/users";
import HelpInfoIcon from "src/components/common/HelpInfoIcon";

const MAX_FILE_SIZE_MB = 5;

interface IProps {
  isRegister?: boolean;
}

const Profile: FC<IProps> = ({ isRegister }) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<TFileWithPreview | undefined>(undefined);
  const [username, setUsername] = useState("");
  const [isCanadian, setIsCanadian] = useState(false);
  const registering = useStore(register.pending);
  const account = useStore($account);

  const { data } = useQuery(USERS, {
    variables: {
      where: {
        address: { eq: account },
      },
    },
    skip: !account,
  });

  const accountInfo = data?.users?.nodes?.[0];

  useEffect(() => {
    if (accountInfo?.username && !username) {
      setUsername(accountInfo.username);
    }
    if (accountInfo?.isCanadian && !isCanadian) {
      setIsCanadian(accountInfo.isCanadian);
    }
  }, [accountInfo]); // eslint-disable-line

  const onAttachmentChange = async (files: TFileWithPreview[]) => {
    if (files.length) {
      setFile(
        Object.assign(files[0], {
          preview: URL.createObjectURL(files[0]),
        })
      );
    }
  };

  useEffect(
    () => () => {
      file?.preview && URL.revokeObjectURL(file.preview);
    },
    [file]
  );

  const renderFile = (file: TFileWithPreview) => {
    if (file?.type.includes("image") && file?.preview) {
      return <img src={file?.preview} alt="Avatar" />;
    }
  };

  return (
    <div className="profile">
      <div className="profile__field">
        <div className="profile__field-label">
          {t("modals.profile.usernameLabel")}{" "}
          <HelpInfoIcon
            bold
            data-multiline
            data-tip={t("tooltips.myProfile.username")}
          />
        </div>
        <input
          type="text"
          className="profile__field-value"
          value={username}
          onChange={e => setUsername(e.target.value)}
          placeholder={t("modals.profile.usernamePlaceholder")}
        />
      </div>
      <div className="profile__field">
        <div className="profile__field-label">
          {t("modals.profile.avatarLabel")}{" "}
          <HelpInfoIcon
            bold
            data-multiline
            data-tip={t("tooltips.myProfile.avatar")}
          />
        </div>
        <FileDropzone
          className={cs("profile__dropzone", {
            "profile__dropzone_with-file": file || accountInfo?.avatarUrl,
          })}
          child={
            file ? (
              renderFile(file)
            ) : accountInfo?.avatarUrl ? (
              <img src={accountInfo?.avatarUrl} alt="Avatar" />
            ) : (
              <>
                <div>{t("modals.profile.avatarPlaceholderPart1")}</div>
                <div>{t("modals.profile.avatarPlaceholderPart2")}</div>
                <div>
                  {t("modals.profile.avatarPlaceholderPart3", {
                    fileSize: MAX_FILE_SIZE_MB,
                  })}
                </div>
              </>
            )
          }
          settings={{
            accept: "image/*",
            maxFiles: 1,
            maxSize: MAX_FILE_SIZE_MB * 1024 * 1024,
          }}
          onDropFile={files => onAttachmentChange(files)}
        />
        {(file || accountInfo?.avatarUrl) && (
          <div
            className="profile__delete-avatar"
            onClick={() => {
              setFile(undefined);
              deleteAvatar();
            }}
          >
            <span className="profile__delete-avatar-text">
              {t("modals.profile.deleteAvatar")}
            </span>
            <AiOutlineDelete className="profile__delete-avatar-icon" />
          </div>
        )}
      </div>
      <div className="profile__field profile__field-checkbox">
        <div className="profile__field-label">
          {t("modals.profile.canadianLabel")}{" "}
          <HelpInfoIcon
            bold
            data-multiline
            data-tip={t("tooltips.myProfile.canadian")}
          />
        </div>
        <input
          type="checkbox"
          className="profile__checkbox"
          checked={isCanadian}
          onChange={() => setIsCanadian(ps => !ps)}
        />
      </div>
      <Button
        className="profile__btn"
        onClick={() => register({ username, avatar: file, isCanadian })}
        loading={registering}
        disabled={registering}
      >
        {isRegister ? t("modals.profile.register") : t("modals.profile.save")}
      </Button>
      {isRegister && (
        <div className="profile__terms">{t("modals.profile.termsText")}</div>
      )}
    </div>
  );
};

export default Profile;
