import { SelectOption } from "src/types/common";
import { gql, useLazyQuery } from "@apollo/client";
import { useEffect } from "react";

export interface CollectionCategory {
  id: string;
  name: string;
  gamesCount: number;
}

export const COLLECTION_CATEGORIES = gql`
  query CollectionCategories {
    nFTCollectionCategories {
      id
      name
      gamesCount
    }
  }
`;

export const useCollectionCategories = (params: { skip?: boolean } | void) => {
  const [getData, { data, error, loading }] = useLazyQuery(
    COLLECTION_CATEGORIES
  );

  useEffect(() => {
    !params?.skip && getData();
  }, [params?.skip, getData]);

  const categories =
    data?.nFTCollectionCategories
      ?.map((i: CollectionCategory) => ({
        label: i.name,
        value: i.id,
      }))
      .sort((a: SelectOption, b: SelectOption) =>
        a.label.localeCompare(b.label)
      ) || [];

  const categoriesForFilters = data?.nFTCollectionCategories
    ? [
        {
          label: `All (${data?.nFTCollectionCategories?.reduce(
            (acc: number, cur: CollectionCategory) => {
              acc += cur.gamesCount;
              return acc;
            },
            0
          )})`,
          value: "",
        },
        ...data?.nFTCollectionCategories
          ?.filter((i: CollectionCategory) => i.gamesCount > 0)
          ?.map((i: CollectionCategory) => ({
            label: `${i.name} (${i.gamesCount})`,
            value: i.id,
          }))
          .sort((a: SelectOption, b: SelectOption) =>
            a.label.localeCompare(b.label)
          ),
      ]
    : [];

  return {
    categories,
    categoriesForFilters,
    error,
    loadingCategories: loading,
  };
};
