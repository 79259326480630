import React, { FC, ReactNode, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";

import "./index.scss";
import Layout from "src/components/common/Layout";
import Datepicker from "src/components/common/Datepicker";
import { $txsFilterDate, setTxsFilterDate } from "src/store/wallet";

interface IProps {
  children?: string | ReactNode;
}

const Transactions: FC<IProps> = ({ children }) => {
  const { t } = useTranslation();

  const txsFilterDate = useStore($txsFilterDate);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout className="gradient-layout">
      <div className="transactions">
        <div className="transactions__title">
          {t("pages.myTransactions.title")}
        </div>
        <div className="page-description transactions__description">
          {t("pages.myTransactions.pageDescription")}
        </div>
        <div className="transactions__nav-links">
          <NavLink
            className="transactions__nav-link"
            activeClassName="transactions__nav-link_selected"
            to="/games-transactions"
            exact
          >
            {t("pages.myTransactions.inGameTransactions")}
          </NavLink>
          <NavLink
            className="transactions__nav-link"
            activeClassName="transactions__nav-link_selected"
            to="/transactions"
            exact
          >
            {t("pages.myTransactions.blockchainTransactions")}
          </NavLink>
        </div>
        <Datepicker
          selected={txsFilterDate}
          onChange={date =>
            setTxsFilterDate(date instanceof Date ? date : null)
          }
          dateFormat="dd MMM yyyy"
          wrapperClassName="transactions__date-wrapper"
          placeholder={t("pages.myTransactions.filterByDatePlaceholder")}
          isClearable
          isButton
        />
        {children}
      </div>
    </Layout>
  );
};

export default Transactions;
