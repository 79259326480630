import React, { FC } from "react";
import cs from "classnames";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";

import Button from "../../common/Button";
import "./index.scss";
import { $reportMessage, sendReport, setReportMessage } from "src/store/games";

interface IProps {}

const Report: FC<IProps> = () => {
  const { t } = useTranslation();
  const reportMessage = useStore($reportMessage);
  const sending = useStore(sendReport.pending);

  return (
    <div className="report">
      <div className="report__field">
        <div className="report__field-label">Reason</div>
        <textarea
          // cols={100}
          value={reportMessage}
          onChange={e => setReportMessage(e.target.value)}
          className={cs("report__field-value")}
          placeholder="Enter reason..."
        />
      </div>
      <Button
        className="report__btn"
        onClick={() => sendReport()}
        loading={sending}
        disabled={sending}
      >
        {t("buttons.sendReport")}
      </Button>
    </div>
  );
};

export default Report;
