import { createEffect, createEvent, createStore, restore } from "effector";
import { createGate } from "effector-react";

import { INftCollection } from "src/graphQl/nftCollections";
import { ETH_CHAIN_ID, POLYGON_CHAIN_ID, ARBITRUM_CHAIN_ID } from "src/config";
import { bulkNftUpload } from "../nft";

export const CollectionGate = createGate();
export const ImportCollectionModalGate = createGate();

export const $importCollectionForm = createStore<{
  collectionAddress: string;
  royaltyPercent: string;
  categoryId: string;
}>({ collectionAddress: "", royaltyPercent: "", categoryId: "" }).reset(
  ImportCollectionModalGate.close
);

export const importCollectionFormSetField = createEvent<{
  key: string;
  value: string;
}>();

export const setFields = createEvent<{ [key: string]: any }>();
export const addIdsAmountFields = createEvent();
export const removeIdsAmountFields = createEvent<number>();

export const setSelectedChainId = createEvent<string>();
export const $selectedChainId = restore(setSelectedChainId, ETH_CHAIN_ID || POLYGON_CHAIN_ID || ARBITRUM_CHAIN_ID || "");

export const importCollection = createEffect<void, void>();
export const setRoyalty = createEffect<void, void>();
export const setCategory = createEffect<string, void>();

export const $fields = createStore<{
  collection: INftCollection | null;
  royaltyPercent: string;
  nftIds: string;
  categoryId: string;
  contractType: string;
  collectionAddress: string;
  idsAmount: { id: string; amount: string }[];
}>({
  collection: null,
  royaltyPercent: "",
  nftIds: "",
  categoryId: "",
  contractType: "721",
  collectionAddress: "",
  idsAmount: [{ id: "", amount: "" }],
})
  .on(addIdsAmountFields, _ => ({
    ..._,
    idsAmount: [..._.idsAmount, { id: "", amount: "" }],
  }))
  .on(removeIdsAmountFields, (_, index) => {
    const newIdsAmount = [..._.idsAmount];
    newIdsAmount.splice(index, 1);
    return {
      ..._,
      idsAmount: newIdsAmount,
    };
  })
  .reset([setRoyalty.done, CollectionGate.close, bulkNftUpload.done]);
