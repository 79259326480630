import { DISCORD_LINK } from ".";

export const socialsLinks = {
  twitter: "https://twitter.com/puzzledplatform",
  // telegram: "https://t.me/puzzledspace",
  discord: DISCORD_LINK,
  // reddit: "https://www.reddit.com/r/PuzzledGameSpace/",
  youtube: "https://www.youtube.com/channel/UCHmhsiJJ5AZAHaXs7sNEQTw",
  // newsletter: "http://eepurl.com/hY2NpL",
};
export const faqPageLinks = {
  answer1Link1: "https://www.savvynewcanadians.com/buy-polygon-matic-canada/",
};
export const howToCreatePageLinks = {
  mainVideoLink: "https://www.youtube.com/embed/xq5N33FisNY",
  section1VideoLink: "https://www.youtube.com/embed/xq5N33FisNY",
  section2VideoLink: "https://www.youtube.com/embed/xq5N33FisNY?start=169",
  section3VideoLink: "https://www.youtube.com/embed/xq5N33FisNY?start=338",
  section4VideoLink: "https://www.youtube.com/embed/xq5N33FisNY?start=269",
  section5VideoLink: "https://www.youtube.com/embed/xq5N33FisNY?start=884",
  openSea: "https://opensea.io/",
};
export const quickGuidePageLinks = {
  mainVideoLink: "https://www.youtube.com/embed/EfCdZiIHb0g",
};
export const howToPlayPageLinks = {
  mainVideoLink: "https://www.youtube.com/embed/0_l7ALeNUx4",
  section1VideoLink: "https://www.youtube.com/embed/0_l7ALeNUx4",
  section2VideoLink: "https://www.youtube.com/embed/0_l7ALeNUx4?start=139",
  section3VideoLink: "https://www.youtube.com/embed/0_l7ALeNUx4?start=412",
  section4VideoLink: "https://www.youtube.com/embed/0_l7ALeNUx4?start=600",
  section5VideoLink: "https://www.youtube.com/embed/0_l7ALeNUx4?start=857",
  section6VideoLink: "https://www.youtube.com/embed/0_l7ALeNUx4?start=626",
  section7VideoLink: "https://www.youtube.com/embed/0_l7ALeNUx4?start=187",
  downloadMetamask: "https://metamask.io/download/",
  section7Paragraph3Link1: "https://polygon.technology/matic-token/",
  section7Paragraph4Link1: "https://www.binance.com/",
  section7Paragraph4Link2:
    "https://www.kraken.com/prices/matic-polygon-price-chart/",
  section7Paragraph4Link3: "https://www.coinsmart.com/markets/polygon-matic/",
  section7Paragraph4Link4:
    "https://www.kucoin.com/price/MATIC?spm=kcWeb.B1homepage.TrendingCoinsRow.1",
  section7Paragraph5Link1: "https://app.uniswap.org/#/swap?chain=polygon",
  section7Paragraph6Link1: "https://discord.gg/KEnNaKr3xP",
  section7Paragraph7Link1: "https://app.uniswap.org/#/swap?chain=mainnet",
};
