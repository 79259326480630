import $api from "../http";
import { AxiosResponse } from "axios";

export interface ISetRoyaltyParams {
  chainId?: number;
  collectionAddress?: string;
  royaltyAddress?: string | null;
  royaltyPercent?: number;
  categoryId?: string;
}

export interface INftInCustodyWithdrawApiParams {
  nftId: string;
  to: string;
}

export interface INftInCustodyTransferParams {
  nftIds: string[];
  to: string;
}

export interface INftInCustodyWithdrawResponse {
  id: string;
  user: string;
  nftContractAddress: string;
  tokenId: string;
  signature: string;
  validTo: number;
  seq: string;
  value: string;
  type: number;
  chainId: number;
  serverGameId: number;
  royaltyPercent: string;
  royaltyAddress: string;
  platformFeePercent: number;
}

export default class NftInCustodyService {
  static transferToOtherUser(
    params: INftInCustodyTransferParams
  ): Promise<AxiosResponse<string>> {
    return $api.post<string>("/nftInCustody/transfer", params);
  }
  static withdraw(
    params: INftInCustodyWithdrawApiParams
  ): Promise<AxiosResponse<INftInCustodyWithdrawResponse>> {
    return $api.post<INftInCustodyWithdrawResponse>(
      "/nftInCustody/withdraw",
      params
    );
  }
  static setRoyalty(params: ISetRoyaltyParams): Promise<AxiosResponse<void>> {
    return $api.post<void>("/nftInCustody/setRoyalty", params);
  }
}
