import { gql, useQuery } from "@apollo/client";
import { INftCollection } from "./nftCollections";
import { INftOperation } from "./nftOperations";
import { IUser } from "./users";

export enum CustodianNFTStatus {
  DEPOSITED = "DEPOSITED",
  WITHDRAWN = "WITHDRAWN",
  IN_GAME = "IN_GAME",
}

export interface INftInCustody {
  backgroundColor: string;
  blockHash: string;
  blockNumber: string;
  chainId: number;
  changeStatusTx: string;
  collection: INftCollection;
  collectionId: string;
  currentHexRoyalty: string;
  custodianContractAddress: string;
  custodianContractId: string;
  description: string;
  externalUrl: string;
  fail: boolean;
  from: string;
  id: string;
  image: string;
  imageData: string;
  logIndex: string;
  name: string;
  operation: INftOperation;
  operationId: number;
  owner: string;
  status: CustodianNFTStatus;
  title: string;
  tokenId: string;
  transactionHash: string;
  type: string;
  user: IUser;
  value: string;
  youtubeUrl: string;
  image280: string;
  image715: string;
}

export const GET_NFTS_IN_CUSTODY = gql`
  query NftInCustody(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $where: NFTInCustodyFilterInput
    $order: [NFTInCustodySortInput!]
    $searchString: String
  ) {
    nFTsInCustody(
      first: $first
      after: $after
      last: $last
      before: $before
      where: $where
      order: $order
      searchString: $searchString
    ) {
      nodes {
        id
        from
        tokenId
        value
        custodianContractAddress
        chainId
        status
        owner
        title
        name
        type
        description
        image280
        collection {
          collectionAddress
          contractImage
          contractName
          royaltyPercent
          blockchainRoyaltyPercent
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;

// export const NFTS_IN_CUSTODY_ID = gql`
//   query GetNftsInCustody(
//     $first: Int
//     $after: String
//     $last: Int
//     $before: String
//     $where: NFTInCustodyFilterInput
//     $order: [NFTInCustodySortInput!]
//   ) {
//     nFTsInCustody(
//       first: $first
//       after: $after
//       last: $last
//       before: $before
//       where: $where
//       order: $order
//     ) {
//       nodes {
//         id
//       }
//     }
//   }
// `;

export const NFTS_IN_CUSTODY_TOTAL_COUNT = gql`
  query NftInCustody($where: NFTInCustodyFilterInput, $first: Int) {
    nFTsInCustody(where: $where, first: $first) {
      totalCount
    }
  }
`;

export const useTotalNftCount = (owner?: string | null) => {
  const { data, error, loading } = useQuery<{
    nFTsInCustody: {
      totalCount: number;
    };
  }>(NFTS_IN_CUSTODY_TOTAL_COUNT, {
    variables: {
      where: {
        owner: { eq: owner },
      },
    },
  });

  return {
    totalNftsCount: data?.nFTsInCustody?.totalCount || 0,
    error,
    loading,
  };
};
