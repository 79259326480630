import $api from "../http";
import { AxiosResponse } from "axios";

export interface IAuctionGameCreateApiParams {
    nftId: string;
    minPlayersCount: number;
    maxPlayersCount: number;
    startTime: string;
    maxGameDurationSeconds: number;
    mapId: string;
    description: string;
    artist: string;
    featured: boolean;
    entryPrice: number;
}

export interface IAuctionGameJoinApiParams {
    gameId: string;
    entryPriceIndex: number;
    captchaToken: string;
}

export interface IAuctionGameBidApiParams {
    gameId: string;
    lotId: string;
    bid: number;
}

export interface IAuctionGameBidApiResponse {
    success: boolean;
    currentBid: number;
    currentBidder: string;
    winTime: string;
    lotId: string;
}

export interface IAuctionGameSellAllApiParams {
    gameId: string;
}

export default class AuctionGameService {
    static create(
        params: IAuctionGameCreateApiParams
    ): Promise<AxiosResponse<{ id: string }>> {
        return $api.post<{ id: string }>("/PenguinGame/create", params);
    }
    static join(
        params: IAuctionGameJoinApiParams
    ): Promise<AxiosResponse<string>> {
        return $api.post<string>(`/PenguinGame/join`, params);
    }
    static bid(
        params: IAuctionGameBidApiParams
    ): Promise<AxiosResponse<IAuctionGameBidApiResponse>> {
        return $api.post<IAuctionGameBidApiResponse>(
            "/AuctionGame/bid",
            params
        );
    }
    static sellAll(
        params: IAuctionGameSellAllApiParams
    ): Promise<AxiosResponse<string>> {
        return $api.post<string>("/AuctionGame/sellAll", params);
    }
    static cancel(gameId: string): Promise<AxiosResponse<string>> {
        return $api.delete<string>("/AuctionGame/cancel", {
            params: { gameId },
        });
    }
    static firstOpen(gameId: string): Promise<AxiosResponse<string>> {
        return $api.post<string>(`/AuctionGame/firstOpen/${gameId}`);
    }
}
