import React, { useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import { toast } from "react-toastify";
import { useStore } from "effector-react";
import dayjs from "dayjs";
import { BsCheckLg } from "react-icons/bs";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import "./index.scss";
import Button from "src/components/common/Button";
import { setModal } from "src/store/app";
import { $item, cancelGame, setPlatformFee } from "src/store/admin";
import { GameStatus } from "src/types/games";
import { getGameCancelReason, getOnlyNumberValue } from "src/utils/helpers";
import { getValueFromHex } from "src/utils/ethers";
import {
    DEFAULT_DATE_TIME_FORMAT,
    MAX_PLATFORM_FEE,
} from "src/config/constants";
import { PLAYERS_INFO_ADMIN } from "src/graphQl/auctionPlayerInfos";

const GameDetails = () => {
    const game = useStore($item);
    const canceling = useStore(cancelGame.pending);
    const settingFee = useStore(setPlatformFee.pending);
    const [fee, setFee] = useState("");
    const { t } = useTranslation();

    const { data: playersInfo } = useQuery(PLAYERS_INFO_ADMIN, {
        variables: {
            where: {
                gameId: { eq: game?.id },
            },
        },
    });

    const playersData = playersInfo?.auctionPlayerInfos;

    useEffect(() => {
        setFee(game?.platformFee);
    }, [game?.platformFee]);

    if (!game) {
        return null;
    }

    const {
        nFTInCustody,
        startTime,
        description,
        creator,
        featured,
        entryPrices,
        baseBidPrice,
        minBidStep,
        minPlayersCount,
        maxPlayersCount,
        playersCount,
        status,
        numberOfPieces,
        secondsForWinBid,
        maxGameDurationSeconds,
        winner,
        id,
        createdTime,
        statusChangeTime,
        cancelReason,
        royaltyPercent,
        royaltyAddress,
    } = game;

    const gameEnded =
        status === GameStatus.CANCELED || status === GameStatus.COMPLETE;

    const royalty = royaltyPercent
        ? new BigNumber(getValueFromHex(royaltyPercent))
              .dividedBy(100)
              .toNumber()
        : 0;

    return (
        <div className="admin-game-details">
            <div className="admin-game-details__image-wrapper">
                <img src={nFTInCustody?.image280} alt="NFT" />
            </div>
            <div className="admin-game-details__platform-fee">
                <input
                    type="text"
                    placeholder={t(
                        "modals.adminGameDetails.platformFeePlaceholder"
                    )}
                    value={fee}
                    onChange={(e) => {
                        const validValue = getOnlyNumberValue(e.target.value);
                        setFee(validValue);
                    }}
                    disabled={status !== GameStatus.CREATED}
                />
                <Button
                    onClick={() => {
                        if (fee !== "" && +fee <= MAX_PLATFORM_FEE) {
                            setPlatformFee({ gameId: id, fee: +fee });
                        } else {
                            toast.info(
                                t("notifications.admin.platformFeeInfo", {
                                    maxFee: MAX_PLATFORM_FEE,
                                })
                            );
                        }
                    }}
                    className="admin-game-details__btn"
                    disabled={status !== GameStatus.CREATED || settingFee}
                    loading={settingFee}
                >
                    {t("modals.adminGameDetails.setPlatformFeeBtn")}
                </Button>
            </div>
            <div className="admin-game-details__field">
                <div className="admin-game-details__field-label">
                    {t("modals.adminGameDetails.nftAddress")}
                </div>
                <div className="admin-game-details__field-value">
                    {nFTInCustody?.collection?.collectionAddress}
                </div>
            </div>
            <div className="admin-game-details__field">
                <div className="admin-game-details__field-label">
                    {t("modals.adminGameDetails.gameName")}
                </div>
                <div className="admin-game-details__field-value">
                    {nFTInCustody?.collection?.contractName}:{" "}
                    {nFTInCustody?.name}
                </div>
            </div>
            <div className="admin-game-details__field">
                <div className="admin-game-details__field-label">
                    {t("modals.adminGameDetails.nftOwner")}
                </div>
                <div className="admin-game-details__field-value">
                    {nFTInCustody?.owner}
                </div>
            </div>
            <div className="admin-game-details__field">
                <div className="admin-game-details__field-label">
                    {t("modals.adminGameDetails.nftRoyalty")}
                </div>
                <div className="admin-game-details__field-value">
                    {royalty}%
                </div>
            </div>
            <div className="admin-game-details__field">
                <div className="admin-game-details__field-label">
                    {t("modals.adminGameDetails.nftRoyaltyAddress")}
                </div>
                <div className="admin-game-details__field-value">
                    {royaltyAddress}
                </div>
            </div>
            <div className="admin-game-details__field">
                <div className="admin-game-details__field-label">
                    {t("modals.adminGameDetails.gameCreated")}
                </div>
                <div className="admin-game-details__field-value">
                    {dayjs(createdTime).format(DEFAULT_DATE_TIME_FORMAT)}
                </div>
            </div>
            <div className="admin-game-details__field">
                <div className="admin-game-details__field-label">
                    {t("modals.adminGameDetails.gameStart")}
                </div>
                <div className="admin-game-details__field-value">
                    {dayjs(startTime).format(DEFAULT_DATE_TIME_FORMAT)}
                </div>
            </div>
            {gameEnded && (
                <div className="admin-game-details__field">
                    <div className="admin-game-details__field-label">
                        {t("modals.adminGameDetails.gameEnded")}
                    </div>
                    <div className="admin-game-details__field-value">
                        {dayjs(statusChangeTime).format(
                            DEFAULT_DATE_TIME_FORMAT
                        )}
                    </div>
                </div>
            )}
            <div className="admin-game-details__field">
                <div className="admin-game-details__field-label">
                    {t("modals.adminGameDetails.description")}
                </div>
                <div className="admin-game-details__field-value">
                    {description}
                </div>
            </div>
            <div className="admin-game-details__field">
                <div className="admin-game-details__field-label">
                    {t("modals.adminGameDetails.nftProvider")}
                </div>
                <div className="admin-game-details__field-value">{creator}</div>
            </div>
            <div className="admin-game-details__field">
                <div className="admin-game-details__field-label">
                    {t("modals.adminGameDetails.featuredGameFlag")}
                </div>
                <div className="admin-game-details__field-value">
                    {featured && <BsCheckLg />}
                </div>
            </div>
            <div className="admin-game-details__field">
                <div className="admin-game-details__field-label">
                    {t("modals.adminGameDetails.freeUsersFlag")}
                </div>
                <div className="admin-game-details__field-value">
                    {entryPrices?.includes(0) && <BsCheckLg />}
                </div>
            </div>
            <div className="admin-game-details__field">
                <div className="admin-game-details__field-label">
                    {t("modals.adminGameDetails.minimumBidPiece")}
                </div>
                <div className="admin-game-details__field-value">
                    {baseBidPrice}
                </div>
            </div>
            <div className="admin-game-details__field">
                <div className="admin-game-details__field-label">
                    {t("modals.adminGameDetails.minimumBidIncrement")}
                </div>
                <div className="admin-game-details__field-value">
                    {minBidStep}
                </div>
            </div>
            <div className="admin-game-details__field">
                <div className="admin-game-details__field-label">
                    {t("modals.adminGameDetails.minimumPlayers")}
                </div>
                <div className="admin-game-details__field-value">
                    {minPlayersCount}
                </div>
            </div>
            <div className="admin-game-details__field">
                <div className="admin-game-details__field-label">
                    {t("modals.adminGameDetails.maximumPlayers")}
                </div>
                <div className="admin-game-details__field-value">
                    {maxPlayersCount || "Unlimited"}
                </div>
            </div>
            {(status === GameStatus.CREATED ||
                status === GameStatus.STARTED) && (
                <div className="admin-game-details__field">
                    <div className="admin-game-details__field-label">
                        {t("modals.adminGameDetails.registeredPlayers")}
                    </div>
                    <div className="admin-game-details__field-value">
                        {playersCount}
                    </div>
                </div>
            )}
            <div className="admin-game-details__field">
                <div className="admin-game-details__field-label">
                    {t("modals.adminGameDetails.numberOfPieces")}
                </div>
                <div className="admin-game-details__field-value">
                    {numberOfPieces}
                </div>
            </div>
            <div className="admin-game-details__field">
                <div className="admin-game-details__field-label">
                    {t("modals.adminGameDetails.minutesToWinBid")}
                </div>
                <div className="admin-game-details__field-value">
                    {/* {secondsForWinBid}s */}
                    {new BigNumber(secondsForWinBid)
                        .dividedBy(60)
                        .toNumber()}{" "}
                    min
                </div>
            </div>
            <div className="admin-game-details__field">
                <div className="admin-game-details__field-label">
                    {t("modals.adminGameDetails.gameDuration")}
                </div>
                <div className="admin-game-details__field-value">
                    {new BigNumber(maxGameDurationSeconds)
                        .dividedBy(60)
                        .toNumber()}{" "}
                    min
                </div>
            </div>
            <div className="admin-game-details__field">
                <div className="admin-game-details__field-label">
                    {t("modals.adminGameDetails.gameStatus")}Game Status
                </div>
                <div className="admin-game-details__field-value">
                    {status}{" "}
                    {status === GameStatus.CANCELED &&
                        cancelReason &&
                        getGameCancelReason(cancelReason)}
                </div>
            </div>
            {playersData && (
                <div className="admin-game-details__field">
                    <div className="admin-game-details__field-label">
                        {t("modals.adminGameDetails.registeredPlayers")}
                    </div>
                    <div className="admin-game-details__field-value">
                        {playersData.map((i: any) => {
                            const ind = entryPrices.indexOf(i.entryPrice);
                            // const piecesCount = i?.pieces?.length || 0;
                            return (
                                <div key={i.address}>
                                    {i.address} -{" "}
                                    {ind === 2
                                        ? "Gold"
                                        : ind === 1
                                        ? "Silver"
                                        : "Bronze"}
                                    {/* {piecesCount} {piecesCount === 1 ? "piece" : "pieces"} */}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
            {winner && (
                <div className="admin-game-details__field">
                    <div className="admin-game-details__field-label">
                        {t("modals.adminGameDetails.gameWinner")}
                    </div>
                    <div className="admin-game-details__field-value">
                        {winner}
                    </div>
                </div>
            )}
            {(status === GameStatus.CREATED ||
                status === GameStatus.STARTED) && (
                <Button
                    className="admin-game-details__cancel"
                    onClick={() => cancelGame(id)}
                    loading={canceling}
                    disabled={canceling}
                >
                    {t("cancel")}
                </Button>
            )}
            <Button
                onClick={() => setModal(null)}
                className="admin-game-details__btn"
            >
                {t("close")}
            </Button>
        </div>
    );
};

export default GameDetails;
