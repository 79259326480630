import React from "react";
import { useGate, useStore, useStoreMap } from "effector-react";
import cs from "classnames";
import { SiEthereum } from "react-icons/si";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { ReactComponent as PolygonIcon } from "src/assets/PolygonIcon.svg";
import { ReactComponent as ArbitrumIcon } from "src/assets/ArbitrumIcon.svg";
import "./index.scss";
import Button from "../../common/Button";
import {
  $addNftForm,
  $selectedChainId,
  $transferError,
  addNftFormSetField,
  AddNftModalGate,
  setSelectedChainId,
  transferNft,
} from "src/store/nft";
import { getOnlyNumberValue } from "src/utils/helpers";
import { GET_CONTRACTS, ContractTypes } from "src/graphQl/contracts";
import { ARBITRUM_CHAIN_ID, ETH_CHAIN_ID, POLYGON_CHAIN_ID } from "src/config";
import { setModal } from "src/store/app";
import HelpInfoIcon from "src/components/common/HelpInfoIcon";
import { useWhiteListedCollectionsAddressChain } from "src/graphQl/nftCollections";
import { $account } from "src/store/wallet";
import { USERS } from "src/graphQl/users";

const BLOCKCHAINS: any[] = [];
if (ETH_CHAIN_ID) {
  BLOCKCHAINS.push(
    {
      title: "Ethereum",
      icon: <SiEthereum className="add-nft-modal__icon" />,
      key: ETH_CHAIN_ID,
    }
  );
}
if (POLYGON_CHAIN_ID) {
  BLOCKCHAINS.push(
    {
      title: "Polygon",
      icon: <PolygonIcon className="add-nft-modal__icon" />,
      key: POLYGON_CHAIN_ID,
    }
  );
}
if (ARBITRUM_CHAIN_ID) {
  BLOCKCHAINS.push(
    {
      title: "Arbitrum",
      icon: <ArbitrumIcon className="add-nft-modal__icon _not-fill" />,
      key: ARBITRUM_CHAIN_ID,
    }
  );
}

const AddNftModal = () => {
  useGate(AddNftModalGate);
  const { t } = useTranslation();
  const selectedChainId = useStore($selectedChainId);
  const transferError = useStore($transferError);
  const transferringNft = useStore(transferNft.pending);
  const account = useStore($account);

  const nftAddress = useStoreMap({
    store: $addNftForm,
    keys: ["nftAddress"],
    fn: (values: any) => values["nftAddress"] || "",
  });

  const tokenId = useStoreMap({
    store: $addNftForm,
    keys: ["tokenId"],
    fn: (values: any) => values["tokenId"] || "",
  });

  const openSeaLink = useStoreMap({
    store: $addNftForm,
    keys: ["openSeaLink"],
    fn: (values: any) => values["openSeaLink"] || "",
  });

  const { loading: loadingContract, data } = useQuery(GET_CONTRACTS, {
    variables: {
      where: {
        active: { eq: true },
        type: { eq: ContractTypes.BRIDGE },
        // chainId: { eq: +selectedChainId },
      },
    },
  });

  const { data: users, loading: loadingUsers } = useQuery(USERS, {
    variables: {
      where: {
        address: { eq: account },
      },
    },
  });

  const accountInfo = users?.users?.nodes?.[0];
  const unRegistered = accountInfo?.username === null;

  // const { loading, data: whitelistedContracts } = useQuery(
  //   WHITELISTED_CONTRACTS,
  //   {
  //     variables: {
  //       first: 1000,
  //     },
  //   }
  // );

  const { loading, whitelistedCollections } =
    useWhiteListedCollectionsAddressChain();

  const bridgeContracts = data?.contracts?.nodes;
  const systemError = !bridgeContracts?.length && !loadingContract;

  const transferDisabled = loadingContract || systemError;

  const transfer = () => {
    transferNft({ bridgeContracts, whitelistedCollections });
  };

  return (
    <div className="add-nft-modal">
      <div className="add-nft-modal__field">
        <div className="add-nft-modal__field-label">
          {t("modals.depositNft.chooseBlockchain")}
        </div>
        {/* <div className="add-nft-modal__warning">
          <IoWarningOutline /> Lorem ipsum dolor sit amet, consectetur
          adipiscing elit. Proin sed ultricies fames ut sed leo fermentum nullam
          platea.
        </div> */}
        <div className="add-nft-modal__chains">
          {BLOCKCHAINS.map(i => (
            <div
              key={i.key}
              className={cs("add-nft-modal__chain-btn", {
                "add-nft-modal__chain-btn_selected": i.key === selectedChainId,
              })}
              onClick={() => setSelectedChainId(i.key)}
            >
              {i.icon} {i.title}
            </div>
          ))}
        </div>
      </div>
      <div className="add-nft-modal__nft-links">
        <div className="add-nft-modal__field">
          <div className="add-nft-modal__field-label">
            {t("modals.depositNft.nftAddressLabel")}
          </div>
          <input
            name="nftAddress"
            value={nftAddress}
            onChange={e =>
              addNftFormSetField({ key: e.target.name, value: e.target.value })
            }
            className={cs("add-nft-modal__field-value")}
            placeholder={t("modals.depositNft.nftAddressPlaceholder")}
            disabled={openSeaLink}
          />
        </div>
        <div className="add-nft-modal__field">
          <div className="add-nft-modal__field-label">
            {t("modals.depositNft.nftIdLabel")}
          </div>
          <input
            name="tokenId"
            value={tokenId}
            onChange={e => {
              const validValue = getOnlyNumberValue(e.target.value);
              addNftFormSetField({ key: e.target.name, value: validValue });
            }}
            className={cs("add-nft-modal__field-value")}
            placeholder={t("modals.depositNft.nftIdPlaceholder")}
            disabled={openSeaLink}
          />
        </div>
      </div>
      <div className="add-nft-modal__dividers">
        <div className="add-nft-modal__divider"></div>
        OR
        <div className="add-nft-modal__divider"></div>
      </div>
      <div className="add-nft-modal__field-label">
        {t("modals.depositNft.openSeaDescription")}
      </div>
      <div className="add-nft-modal__field">
        <div className="add-nft-modal__field-label">
          {t("modals.depositNft.openSeaLinkLabel")}{" "}
          <HelpInfoIcon
            bold
            data-tip={t("tooltips.depositNft")}
            data-multiline
          />
        </div>
        <input
          name="openSeaLink"
          value={openSeaLink}
          onChange={e =>
            addNftFormSetField({ key: e.target.name, value: e.target.value })
          }
          className={cs("add-nft-modal__field-value")}
          placeholder={t("modals.depositNft.openSeaLinkPlaceholder")}
          disabled={nftAddress || tokenId}
        />
      </div>
      {transferError && (
        <div className="add-nft-modal__error">
          {t("modals.depositNft.transferErrorPart1")}{" "}
          <Link to="/contact-us" onClick={() => setModal(null)}>
            {t("modals.depositNft.transferErrorPart2")}
          </Link>{" "}
          {t("modals.depositNft.transferErrorPart3")}
        </div>
      )}
      {systemError && (
        <div className="add-nft-modal__error">
          {t("modals.depositNft.systemError")}
        </div>
      )}
      {unRegistered ? (
        <Button
          className="add-nft-modal__btn"
          onClick={() => setModal("register")}
          loading={loadingUsers}
        >
          {t("buttons.register")}
        </Button>
      ) : (
        <Button
          onClick={transfer}
          className="add-nft-modal__btn"
          loading={
            transferringNft || loadingContract || loading || loadingUsers
          }
          disabled={
            !((nftAddress && tokenId) || openSeaLink) ||
            transferringNft ||
            transferDisabled ||
            loading
          }
          walletRequired
          ignoreChain
        >
          {t("modals.depositNft.submitBtn")}
        </Button>
      )}
    </div>
  );
};

export default AddNftModal;
