import { ethers, BigNumber } from "ethers";
import erc721Abi from "../contracts/Erc721Abi.json";
import erc1155Abi from "../contracts/Erc1155Abi.json";

export interface IBalanceOf {
  tokenContractAddress: string;
  address: string;
}

export interface IBalanceOf1155 {
  tokenContractAddress: string;
  address: string;
  id: string;
}

export interface IBalanceOfBatch {
  tokenContractAddress: string;
  addresses: string[];
  ids: string[];
}

export interface IOwnerOf {
  tokenContractAddress: string;
  nftId: string;
}

const getContract = (contractAddress: string, is1155?: boolean) => {
  const provider: any = window.ethereum
    ? new ethers.providers.Web3Provider(window?.ethereum, "any")
    : null;
  const signer = provider?.getSigner();
  const abi = is1155 ? erc1155Abi : erc721Abi;
  const contractInstance = new ethers.Contract(contractAddress, abi, provider);
  const contractInstanceWithSigner = contractInstance?.connect(signer);
  return contractInstanceWithSigner;
};

export default class NftContractService {
  static safeTransferFrom(
    nftAddress: string,
    from: string,
    to: string,
    tokenId: string,
    is1155?: boolean
  ): Promise<any> {
    if (is1155) {
      return getContract(nftAddress, true)?.safeTransferFrom(
        from,
        to,
        tokenId,
        1,
        []
      );
    }
    return getContract(nftAddress)?.[
      "safeTransferFrom(address,address,uint256)"
    ](from, to, tokenId);
  }

  static supportsInterface(nftAddress: string, is1155?: boolean): Promise<any> {
    return getContract(nftAddress, is1155)?.supportsInterface(
      is1155 ? "0xd9b67a26" : "0x80ac58cd"
    );
  }

  static balanceOf({
    tokenContractAddress,
    address,
  }: IBalanceOf): Promise<BigNumber> {
    return getContract(tokenContractAddress)?.balanceOf(address);
  }

  static balanceOf1155({
    tokenContractAddress,
    address,
    id,
  }: IBalanceOf1155): Promise<BigNumber> {
    return getContract(tokenContractAddress, true)?.balanceOf(address, id);
  }

  static balanceOfBatch({
    tokenContractAddress,
    addresses,
    ids,
  }: IBalanceOfBatch): Promise<BigNumber> {
    return getContract(tokenContractAddress, true)?.balanceOfBatch(
      addresses,
      ids
    );
  }

  static ownerOf({ tokenContractAddress, nftId }: IOwnerOf): Promise<string> {
    return getContract(tokenContractAddress)?.ownerOf(nftId);
  }

  static safeBatchTransferFrom(
    nftAddress: string,
    from: string,
    to: string,
    ids: string[],
    amounts: string[]
  ): Promise<any> {
    console.log("safeBatchTransferFrom", from, to, ids, amounts);
    return getContract(nftAddress, true)?.safeBatchTransferFrom(
      from,
      to,
      ids,
      amounts,
      []
    );
  }
}
