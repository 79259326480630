import { gql } from "@apollo/client";

export interface IUser {
  address: string;
  avatarUrl?: string;
  expireDate: string;
  loginTime: string;
  nonce?: string;
  roles?: [string];
  timeCreated: string;
  username?: string;
  discordId?: string;
  isCanadian?: boolean;
  isUserOnDiscordServer?: boolean;

  bulkDeposit: boolean;
  bulkGameCreation: boolean;
  bulkTransfer: boolean;
}

export const USERS = gql`
  query User($where: UserFilterInput) {
    users(where: $where) {
      nodes {
        address
        avatarUrl
        username
        roles
        isCanadian
        bulkDeposit
        bulkGameCreation
        bulkTransfer
      }
    }
  }
`;

export const USER_CREATED = gql`
  query User($where: UserFilterInput) {
    users(where: $where) {
      nodes {
        timeCreated
      }
    }
  }
`;
