import React, { ChangeEvent, useEffect, useState } from "react";
import { useGate, useStore } from "effector-react";
import BigNumberJs from "bignumber.js";
import cs from "classnames";
import Switch from "react-switch";
import { useTranslation } from "react-i18next";

import "./index.scss";
import Button from "../../common/Button";
import {
  $bidError,
  $bidUnavailable,
  $selectedGame,
  $selectedPuzzle,
  $yourBid,
  bid,
  BidModalGate,
  setYourBid,
} from "src/store/games";
import { getOnlyNumberValue } from "src/utils/helpers";
import MarketPuzzle from "../../game/MarketPuzzle";
import { ReactComponent as PolygonIcon } from "src/assets/PolygonIcon.svg";
import { ReactComponent as ArbitrumIcon } from "src/assets/ArbitrumIcon.svg";
import HelpInfoIcon from "src/components/common/HelpInfoIcon";
import { POLYGON_CHAIN_ID, ARBITRUM_CHAIN_ID } from "src/config";

const BidModal = () => {
  useGate(BidModalGate);
  const { t } = useTranslation();
  const yourBid = useStore($yourBid);
  const selectedGame = useStore($selectedGame);
  const selectedPuzzle = useStore($selectedPuzzle);
  const bidUnavailable = useStore($bidUnavailable);
  const bidError = useStore($bidError);
  const bidding = useStore(bid.pending);
  const [hideBidModal, setHideBidModal] = useState(false);

  const highestBidValue = selectedPuzzle?.currentBid || 0;

  useEffect(() => {
    if (selectedPuzzle && selectedGame && yourBid === "") {
      const minBidNow = new BigNumberJs(highestBidValue)
        .plus(selectedGame.minBidStep)
        .toString();
      highestBidValue && setYourBid(minBidNow);
    }
  }, [selectedPuzzle, selectedGame, highestBidValue, yourBid]);

  if (!selectedGame || !selectedPuzzle) {
    return null;
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const validValue = getOnlyNumberValue(e.target.value);
    setYourBid(validValue);
  };

  const bidHandler = () => {
    if (hideBidModal) {
      localStorage.setItem(
        `game:${selectedGame.id}`,
        JSON.stringify({ hideBidPopup: true })
      );
    } else {
      localStorage.removeItem(`game:${selectedGame.id}`);
    }
    bid({
      gameId: selectedPuzzle.gameId,
      lotId: selectedPuzzle.lotId,
      amount: yourBid,
    });
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) =>
    event.target.select();

  return (
    <div className="bid-modal">
      <MarketPuzzle
        item={selectedPuzzle}
        onlyImage
        className="bid-modal__puzzle-img"
        numberOfPieces={selectedGame.numberOfPieces}
        image={selectedGame.nFTInCustody.image280}
      />
      <div className="bid-modal__bid">
        <div className="bid-modal__bid-last">
          {selectedPuzzle?.bidder ? "Current bid" : "Current price"}:&nbsp;
          {POLYGON_CHAIN_ID && <PolygonIcon className="bid-modal__puzzle-icon" />}
          {ARBITRUM_CHAIN_ID && <ArbitrumIcon className="bid-modal__puzzle-icon _not-fill" />}
          <b>
            {selectedPuzzle?.bidder
              ? highestBidValue
              : new BigNumberJs(highestBidValue)
                  .plus(selectedGame.minBidStep)
                  .toString()}
          </b>
        </div>
        <div className="bid-modal__bid-your">
          My bid:{" "}
          <input
            type="text"
            className={cs("bid-modal__bid-your-value", {
              "bid-modal__bid-your-value_err": bidUnavailable,
              cDisabled: bidUnavailable,
            })}
            onChange={handleInputChange}
            value={yourBid}
            autoFocus={!bidUnavailable}
            onFocus={handleFocus}
          />
          {POLYGON_CHAIN_ID && <PolygonIcon className="bid-modal__polygon-icon-unit" />}
          {ARBITRUM_CHAIN_ID && <ArbitrumIcon className="bid-modal__polygon-icon-unit _not-fill" />}
        </div>
        {bidUnavailable && (
          <div className="bid-modal__bid-unavailable">
            Your bid must be greater than the current bid.
          </div>
        )}
        {bidError && (
          <div className="bid-modal__bid-unavailable">{bidError}</div>
        )}
        <Button
          onClick={bidHandler}
          className="bid-modal__bid-btn"
          loading={bidding}
          disabled={bidding || bidUnavailable}
          // walletRequired
        >
          {t("buttons.confirm")}
        </Button>
        <div className="bid-modal__hide-modal-option">
          <Switch
            height={18}
            width={36}
            uncheckedIcon={false}
            checkedIcon={false}
            offColor="#C4C4C4"
            onColor="#8FAD91"
            // borderRadius={20}
            onChange={value => setHideBidModal(ps => !ps)}
            checked={hideBidModal}
          />
          Hide Bid Popup Window{" "}
          <HelpInfoIcon bold data-multiline data-tip={t("tooltips.bidModal")} />
        </div>
      </div>
    </div>
  );
};

export default BidModal;
