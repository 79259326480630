import React, { useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import "./index.scss";
import Layout from "src/components/common/Layout";
import { ReactComponent as LogoSvg } from "src/assets/Logo.svg";
import { discordConnect } from "src/store/games";
import { GAMES_DISCORD_INVITE_URL } from "src/graphQl/auctionGames";

const DiscordConnect = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const signature = searchParams.get("signature");
  const id = searchParams.get("id");
  const lastOpenedGameId = localStorage.getItem("lastOpenedGameId");

  const [getGames, { data }] = useLazyQuery(GAMES_DISCORD_INVITE_URL);

  useEffect(() => {
    lastOpenedGameId &&
      getGames({
        variables: {
          where: {
            id: { eq: lastOpenedGameId },
          },
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const discordInviteUrl = data?.auctionGames?.nodes?.[0]?.discordInviteUrl;

  useEffect(() => {
    const connect = async () => {
      try {
        signature && id && (await discordConnect({ signature, id }));
        window.open(discordInviteUrl, "_self");
      } catch (err) {
        console.log({ err });
      }
    };

    discordInviteUrl && connect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discordInviteUrl]);

  if (!signature || !id) {
    toast.warn(t("notifications.systemError"));
    return <Redirect to="/" />;
  }

  return (
    <Layout className="gradient-layout">
      <div className="discord-connect">
        <LogoSvg fill="red" className="discord-connect__logo zoomInOut" />
        <div className="discord-connect__text">
          {t("pages.discordConnecting.text")}
        </div>
      </div>
    </Layout>
  );
};

export default DiscordConnect;
