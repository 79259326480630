import { gql } from "@apollo/client";

import { IUser } from "./users";
import { IAuctionGame } from "src/graphQl/auctionGames";
import { apolloClient } from ".";

export interface IAuctionGameLot {
  active: boolean;
  bidder: string | null;
  bidderUser?: IUser;
  createdTime: string;
  creator?: string;
  creatorUser?: IUser;
  currentBid: number;
  game?: IAuctionGame;
  gameId: string;
  id: number;
  last: boolean;
  lotId: string;
  piece: number;
  winTime: string | null;
}

export const GAME_LOTS = gql`
  query GameLots($where: AuctionGameLotFilterInput, $first: Int) {
    auctionGameLots(where: $where, first: $first) {
      nodes {
        id
        lotId
        gameId
        piece
        currentBid
        bidder
        winTime
        active
      }
    }
  }
`;

export const MY_GAME_LOTS = gql`
  query GameLots($where: AuctionGameLotFilterInput, $first: Int) {
    auctionGameLots(where: $where, first: $first) {
      nodes {
        id
        lotId
        gameId
        piece
        currentBid
        bidder
        winTime
        active
      }
    }
  }
`;

// export const useActiveGameLot = ({
//   gameId,
//   piece,
// }: {
//   gameId: string;
//   piece: number;
// }) => {
//   const data = apolloClient.readQuery({
//     query: GAME_LOTS,
//     variables: {
//       where: {
//         active: { eq: true },
//         gameId: { eq: gameId },
//         // piece: { eq: piece - 1 },
//       },
//     },
//   });
//   // const { data, error, loading } = useQuery<{
//   //   auctionGameLots: {
//   //     nodes: AuctionGameLot[];
//   //   };
//   // }>(GAME_LOTS, {
//   //   variables: {
//   //     where: {
//   //       active: { eq: true },
//   //       gameId: { eq: gameId },
//   //       piece: { eq: piece - 1 },
//   //     },
//   //   },
//   //   fetchPolicy: "cache-only",
//   //   nextFetchPolicy: "cache-only",
//   // });
//   console.log({ data });
//   return data?.auctionGameLots?.nodes?.[0];
// };

interface ModifyLotsParams {
  Bidder: string;
  CurrentBid: number;
  GameId: string;
  LotId: string;
  Piece: number;
  WinTime: string;
  Id: number;
}

// interface ModifyMyLotsParams {
//   GameId: string;
//   PieceId: number;
//   Winner: string;
//   WinnerName: string;
// }

export const updateAuctionGameLot = (params: ModifyLotsParams) => {
  const piece = params.Piece || 0;
  const bidder = params.Bidder || null;
  const winTime = params.WinTime || null;

  const lots = apolloClient.readQuery({
    query: GAME_LOTS,
    variables: {
      where: {
        active: { eq: true },
        gameId: { eq: params.GameId },
      },
      first: 20,
    },
  });

  if (!lots) return;

  const { auctionGameLots } = lots;

  let gameLots = [...auctionGameLots?.nodes] || [];
  const itemIndex = gameLots.findIndex(
    (i: IAuctionGameLot) => i.piece === piece
  );
  // console.log(3, { itemIndex });
  const newItem = {
    __typename: "AuctionGameLot",
    piece,
    gameId: params.GameId,
    lotId: params.LotId,
    currentBid: params.CurrentBid,
    bidder,
    winTime,
    active: true,
    id: params.Id,
  };
  if (itemIndex !== -1) {
    gameLots.splice(itemIndex, 1, newItem);
  } else {
    gameLots = [...gameLots, newItem];
  }
  apolloClient.writeQuery({
    query: GAME_LOTS,
    data: {
      auctionGameLots: { nodes: [...gameLots] },
    },
    variables: {
      where: {
        active: { eq: true },
        gameId: { eq: params.GameId },
      },
      first: 20,
    },
  });
};

// export const updateMyLot = (params: ModifyMyLotsParams, account: string) => {
//   const piece = params.PieceId || 0;

//   const myLots = apolloClient.readQuery({
//     query: MY_GAME_LOTS,
//     variables: {
//       where: {
//         creator: { eq: account },
//         gameId: { eq: params.GameId },
//       },
//       first: 50,
//     },
//   });

//   if (!myLots) return;

//   let gameLots = [...myLots?.auctionGameLots?.nodes] || [];
//   const itemIndex = gameLots.findIndex(
//     (i: IAuctionGameLot) => i.piece === piece
//   );
//   if (itemIndex !== -1) {
//     gameLots.splice(itemIndex, 1, { ...gameLots[itemIndex], active: true });
//   }
//   apolloClient.writeQuery({
//     query: MY_GAME_LOTS,
//     data: {
//       auctionGameLots: { nodes: [...gameLots] },
//     },
//     variables: {
//       where: {
//         creator: { eq: account },
//         gameId: { eq: params.GameId },
//       },
//       first: 50,
//     },
//   });
// };
