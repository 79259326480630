import Loader from "src/components/common/Loader";
import { FC, ReactNode } from "react";
import { Redirect, Route } from "react-router-dom";

interface IProps {
  isAllowed: boolean;
  loading?: boolean;
  children?: ReactNode | ReactNode[];
  loader?: ReactNode;
}

const PrivateRoute: FC<IProps> = ({ isAllowed, loading, children, loader }) => {
  if (loading) {
    return (
      <>{loader || <Loader className="page-loader page-loader_with-bg" />}</>
    );
  }

  return <Route render={() => (isAllowed ? children : <Redirect to="/" />)} />;
};

export default PrivateRoute;
