import React, { ChangeEvent, FC, useState } from "react";
import { useGate, useStore } from "effector-react";
import { useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
// import web3 from "web3";
import { useTranslation } from "react-i18next";

import "./index.scss";
import Button from "src/components/common/Button";
import { getOnlyNumberValue, isMobile, round } from "src/utils/helpers";
import {
  $account,
  $walletBalance,
  GetBalanceGate,
  withdraw,
} from "src/store/wallet";
import { GET_CONTRACTS, ContractTypes } from "src/graphQl/contracts";
import { useAccountBalance } from "src/graphQl/accounts";
import {
  WalletOperationStatus,
  WITHDRAW_OPERATIONS,
} from "src/graphQl/withdrawOperations";
import { setModal } from "src/store/app";
// import { signer } from "src/utils/ethers";
import HelpInfoIcon from "src/components/common/HelpInfoIcon";
import { ARBITRUM_CHAIN_ID, POLYGON_CHAIN_ID } from "src/config";
import ChainIcon from "src/components/common/ChainIcon";

interface IProps { }

const Withdraw: FC<IProps> = () => {
  useGate(GetBalanceGate);
  const { t } = useTranslation();
  const [amount, setAmount] = useState("");
  const loading = useStore(withdraw.pending);
  const account = useStore($account);
  // const [walletBalance, setWalletBalance] = useState("0");
  // const chainId = useStore($chainId);
  const walletBalance = useStore($walletBalance);

  const { data: contracts } = useQuery(GET_CONTRACTS, {
    variables: {
      where: {
        active: { eq: true },
        type: { eq: ContractTypes.WALLET },
      },
    },
  });

  const { data: withdrawOperation } = useQuery(WITHDRAW_OPERATIONS, {
    variables: {
      where: {
        account: {
          address: { eq: account },
        },
        status: { eq: WalletOperationStatus.CREATED },
      },
    },
  });

  // useEffect(() => {
  //   const getBalance = async () => {
  //     if (!signer) return;
  //     const balance = await signer.getBalance();
  //     setWalletBalance(web3.utils.fromWei(balance.toString()));
  //   };

  //   signer && getBalance();
  // }, [chainId]);

  const nonExecutedWalletOperation =
    withdrawOperation?.withdrawOperation?.nodes?.[0];

  const { balance } = useAccountBalance(account);
  const contract = contracts?.contracts?.nodes?.[0];

  const changeAmount = (e: ChangeEvent<HTMLInputElement>) => {
    const validValue = getOnlyNumberValue(e.target.value);
    setAmount(validValue);
  };

  return (
    <div className="withdraw">
      <div className="withdraw__title">
        {t("modals.withdraw.title")}{" "}
        <HelpInfoIcon bold data-multiline data-tip={t("tooltips.withdraw")} />
      </div>
      <Link
        to="#!"
        className="withdraw__link"
        onClick={() => setModal("deposit")}
      >
        {t("modals.withdraw.depositLink")}
      </Link>
      <div className="withdraw__balance">
        <div> {t("modals.withdraw.puzzledBalance")}:</div>{" "}
        {POLYGON_CHAIN_ID && <ChainIcon
          chainId={POLYGON_CHAIN_ID}
          tooltip={t("tooltips.topUpWithdrawPolygonSign")}
          className="top-up__polygon-icon"
        />}
        {ARBITRUM_CHAIN_ID && <ChainIcon
          chainId={ARBITRUM_CHAIN_ID}
          tooltip={t("tooltips.topUpWithdrawArbitrumSign")}
          className="top-up__arbitrum-icon"
        />}
        {balance}
      </div>
      <div className="withdraw__balance">
        <div> {t("modals.withdraw.walletBalance")}:</div>
        {POLYGON_CHAIN_ID && <ChainIcon
          chainId={POLYGON_CHAIN_ID}
          tooltip={t("tooltips.topUpWithdrawPolygonSign")}
          className="top-up__polygon-icon"
        />}
        {ARBITRUM_CHAIN_ID && <ChainIcon
          chainId={ARBITRUM_CHAIN_ID}
          tooltip={t("tooltips.topUpWithdrawArbitrumSign")}
          className="top-up__arbitrum-icon"
        />}
        {" "}
        {round(+walletBalance, 0.000001)}
      </div>
      <div className="withdraw__field">
        <div className="withdraw__field-label">
          {" "}
          {t("modals.withdraw.amountLabel")}
        </div>
        <input
          value={amount}
          onChange={changeAmount}
          className="withdraw__field-value"
          placeholder={t("modals.withdraw.amountPlaceholder")}
          autoFocus={!isMobile()}
        />
        {POLYGON_CHAIN_ID && <ChainIcon
          chainId={POLYGON_CHAIN_ID}
          tooltip={t("tooltips.topUpWithdrawPolygonSign")}
          className="top-up__polygon-icon-unit"
        />}
        {ARBITRUM_CHAIN_ID && <ChainIcon
          chainId={ARBITRUM_CHAIN_ID}
          tooltip={t("tooltips.topUpWithdrawArbitrumSign")}
          className="top-up__arbitrum-icon-unit"
        />}
        <div
          className="withdraw__max-btn"
          onClick={() => setAmount(round(balance, 0.00000001).toString())}
        >
          Max
        </div>
      </div>
      <Button
        className="withdraw__btn"
        onClick={() =>
          contract
            ? withdraw({
              contractId: contract.id,
              amount: +amount,
              contractAddress: contract.address,
              nonExecutedWalletOperation,
            })
            : toast.warn(t("notifications.withdraw.contractError"))
        }
        loading={loading}
        disabled={loading}
        walletRequired
      >
        {t("modals.withdraw.confirmBtn")}
      </Button>
    </div>
  );
};

export default Withdraw;
