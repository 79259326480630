import React, { FC, Fragment, useEffect, useMemo, useState } from "react";
import { useStore } from "effector-react";
import { useLazyQuery } from "@apollo/client";
import cs from "classnames";
import BigNumber from "bignumber.js";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import "./index.scss";
import Loader from "src/components/common/Loader";
import { $account, $txsFilterDate } from "src/store/wallet";
import { DEFAULT_DATE_TIME_FORMAT } from "src/config/constants";
import { GameStatus } from "src/types/games";
import EmptyPlaceholder from "src/components/common/EmptyPlaceholder";
import {
  GAMES_TRANSACTIONS_DATA,
  IAuctionGame,
} from "src/graphQl/auctionGames";
import { TransactionOperationType } from "src/graphQl/accountOperations";
import {
  AccountTransactionState,
  TransactionType,
} from "src/graphQl/transactions";
import Pagination from "src/components/common/Pagination";
import { addressesAreEquals, objFromArr } from "src/utils/helpers";

interface IProps {}

// interface TxsObject {
//   [key: string]: IAccountTransaction[];
// }

const GamesTxs: FC<IProps> = () => {
  const { t } = useTranslation();
  const account = useStore($account);
  const txsFilterDate = useStore($txsFilterDate);
  const [opened, setOpened] = useState<string[]>([]);
  // const [lastOpened, setLastOpened] = useState("");
  const [pageSize, setPageSize] = useState(10);
  // const [txs, setTxs] = useState<TxsObject>({});

  const [getHistory, { loading, data: auctionGames, fetchMore }] = useLazyQuery(
    GAMES_TRANSACTIONS_DATA
  );
  // const [getTxs, { loading: loadingTxs, data: transactions }] =
  //   useLazyQuery(TRANSACTIONS);

  // useEffect(() => {
  //   if (lastOpened && !Object.keys(txs).includes(lastOpened)) {
  //     getTxs({
  //       variables: {
  //         first: 1000,
  //         where: {
  //           operation: {
  //             gameId: {
  //               eq: lastOpened,
  //             },
  //             type: {
  //               nin: [
  //                 TransactionOperationType.BID,
  //                 TransactionOperationType.CANCEL_GAME,
  //                 TransactionOperationType.JOIN_TO_GAME,
  //                 TransactionOperationType.OVER_BID,
  //                 TransactionOperationType.WITHDRAWAL,
  //               ],
  //             },
  //           },
  //         },
  //       },
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [txs, lastOpened]);

  // const lastLoadedTxs = transactions?.transactions?.nodes;

  // useEffect(() => {
  //   if (lastLoadedTxs && !loadingTxs && lastOpened) {
  //     setTxs(ps => ({
  //       ...ps,
  //       [lastOpened]: lastLoadedTxs,
  //     }));
  //   }
  // }, [lastLoadedTxs, loadingTxs, lastOpened]);

  // console.log({ txs });

  const variables: any = useMemo(() => {
    let where: any = {
      status: { nin: [GameStatus.CANCELED] },
      accountOperations: {
        any: true,
      },
      createdTime: undefined,
    };
    if (txsFilterDate) {
      const gte = new Date(txsFilterDate);
      const lte = new Date(txsFilterDate);
      gte.setHours(0, 0, 0, 0);
      lte.setHours(24, 0, 0, 0);
      where = {
        ...where,
        createdTime: {
          gte,
          lte,
        },
      };
    }
    return {
      address: account,
      where,
      order: [{ createdTime: "DESC" }],
    };
  }, [account, txsFilterDate]);

  useEffect(() => {
    getHistory({
      variables: { ...variables, first: pageSize },
      notifyOnNetworkStatusChange: true,
    });
  }, [pageSize, txsFilterDate, getHistory, variables]);

  const games = useMemo(
    () => auctionGames?.auctionGames?.nodes || [],
    [auctionGames?.auctionGames?.nodes]
  );

  const totalCount = auctionGames?.auctionGames?.totalCount || 0;
  const pageInfo = auctionGames?.auctionGames?.pageInfo;

  const filteredGames = useMemo(
    () =>
      games.filter(
        (game: IAuctionGame) =>
          !!game.accountOperations.length &&
          !game.accountOperations.every(i =>
            [
              TransactionOperationType.BID,
              TransactionOperationType.CANCEL_GAME,
              TransactionOperationType.JOIN_TO_GAME,
              TransactionOperationType.OVER_BID,
              TransactionOperationType.WITHDRAWAL,
            ].includes(i.type)
          )
      ),
    [games]
  );

  const formattedGames = useMemo(
    () =>
      filteredGames.map((game: IAuctionGame) => {
        const {
          nFTInCustody,
          startTime,
          royaltyPercent,
          featured,
          royaltyAddress,
        } = game;
        const titleDate = dayjs(startTime).format(DEFAULT_DATE_TIME_FORMAT);
        const gameType = featured
          ? t("gamesTxs.featured")
          : t("gamesTxs.regular");
        const royalty = royaltyPercent
          ? new BigNumber(royaltyPercent).dividedBy(100).toNumber()
          : 0;
        const isOwner = addressesAreEquals(royaltyAddress, account);
        // const isOwner = addressesAreEquals(
        //   nFTInCustody?.collection?.owner,
        //   account
        // );
        const isCreator = addressesAreEquals(game.creator, account);
        // console.log({ isCreator, isOwner, titleDate });
        return {
          id: game.id,
          title: `${titleDate}. ${gameType} ${t("gamesTxs.game")}: ${
            nFTInCustody?.collection?.contractName
          }, ${nFTInCustody?.name} ${royalty}% ${t(
            "gamesTxs.gameNameRoyalty"
          )}:`,
          titleFormatted: (
            <>
              {titleDate}.{" "}
              <b>
                {gameType} {t("gamesTxs.game")}:{" "}
              </b>
              {nFTInCustody?.collection?.contractName}, {nFTInCustody?.name} (
              {royalty}% {t("gamesTxs.gameNameRoyalty")}):
            </>
          ),
          isCreator,
          isOwner,
          operations: game.accountOperations
            .map(i => {
              const {
                relatedPiece,
                time,
                totalAmount,
                userAddress,
                // userAddress2,
                transactions,
              } = i;
              const isBuy = addressesAreEquals(userAddress, account);
              // const isSell = addressesAreEquals(userAddress2, account);
              // const filteredTxs = transactions.filter(tx => {
              //   console.log(tx.accountTransactionState);
              //   if (isCreator && isOwner) {
              //     return tx.type !== TransactionType.SYSTEM_FEE;
              //   }
              //   if (isOwner) {
              //     return tx.type === TransactionType.ROYALTY;
              //   }
              //   return (
              //     tx.type !== TransactionType.SYSTEM_FEE &&
              //     tx.type !== TransactionType.ROYALTY
              //   );
              // });
              const executedTxs = transactions.filter(
                tx =>
                  tx.accountTransactionState !==
                  AccountTransactionState.CANCELED
              );
              const objTxs = objFromArr("type", executedTxs, "amount");
              const entryFeeOption =
                game.entryPrices[2] === totalAmount
                  ? t("gamesTxs.gold")
                  : game.entryPrices[1] === totalAmount
                  ? t("gamesTxs.silver")
                  : t("gamesTxs.bronze");
              return {
                ...i,
                // transactions: filteredTxs,
                // royaltyTx: royaltyTxs[0],
                objTxs,
                timestamp: new Date(time).getTime(),
                timeCreated: dayjs(time).format(DEFAULT_DATE_TIME_FORMAT),
                // isSell: isSell && i.type === TransactionOperationType.WIN_LOT,
                // isOwner,
                sum:
                  !isCreator && isOwner
                    ? objTxs[TransactionType.ROYALTY]
                    : isBuy
                    ? -totalAmount
                    : isCreator
                    ? objTxs[TransactionType.GAME_CREATOR]
                    : objTxs[TransactionType.BUY] ??
                      objTxs[TransactionType.GAME_CREATOR] ??
                      totalAmount,
                // sum: isBuy
                //   ? -totalAmount
                //   : isSell
                //   ? filteredTxs.reduce((acc: any, cur: any) => {
                //       acc = new BigNumber(acc).plus(cur.amount).toNumber();
                //       return acc;
                //     }, 0)
                //   : totalAmount,

                reason:
                  isCreator && isOwner
                    ? `${
                        i.type ===
                        TransactionOperationType.ENTRY_PRICE_AFTER_WIN_GAME
                          ? t("gamesTxs.entryFeeOption", {
                              option: entryFeeOption,
                            })
                          : relatedPiece !== null &&
                            i.type === TransactionOperationType.WIN_LOT
                          ? t("gamesTxs.pieceNumber", {
                              number: relatedPiece + 1,
                              action: isBuy
                                ? t("gamesTxs.won")
                                : objTxs[TransactionType.BUY]
                                ? t("gamesTxs.forfeitedSold")
                                : t("gamesTxs.sold"),
                            })
                          : i.type
                      }`
                    : isOwner
                    ? `${
                        objTxs[TransactionType.BUY]
                          ? t("gamesTxs.forfeitedPieceNumber", {
                              number: relatedPiece + 1,
                            })
                          : relatedPiece !== null
                          ? t("gamesTxs.pieceNumber", {
                              number: relatedPiece + 1,
                              action: "",
                            })
                          : t("gamesTxs.entryFeeOption", {
                              option: entryFeeOption,
                            })
                      } ${t("gamesTxs.royalty")}`
                    : relatedPiece !== null &&
                      i.type === TransactionOperationType.WIN_LOT
                    ? t("gamesTxs.pieceNumber", {
                        number: relatedPiece + 1,
                        action: isBuy
                          ? t("gamesTxs.won")
                          : objTxs[TransactionType.BUY]
                          ? t("gamesTxs.forfeitedSold")
                          : t("gamesTxs.sold"),
                      })
                    : i.type ===
                      TransactionOperationType.ENTRY_PRICE_AFTER_WIN_GAME
                    ? t("gamesTxs.entryFeeOption", {
                        option: entryFeeOption,
                      })
                    : i.type,
              };
            })
            .sort((a: any, b: any) => b.timestamp - a.timestamp),
        };
      }),
    [filteredGames, account, t]
  );

  if (loading) {
    return <Loader className="transactions__loader" />;
  }

  if (formattedGames.length === 0) {
    return (
      <EmptyPlaceholder
        content={
          <>
            {txsFilterDate
              ? t("gamesTxs.noFilteredTxs")
              : t("gamesTxs.noTxsP1")}
            <br />
            {t("gamesTxs.noTxsP2")}{" "}
            <a href="/" className="empty-placeholder__link">
              {t("gamesTxs.noTxsP3")}
            </a>{" "}
            {t("gamesTxs.noTxsP4")}
          </>
        }
      />
    );
  }

  return (
    <>
      <div className="transactions__list">
        {formattedGames.map((i: any) => {
          const totalSum = i.operations.reduce((acc: any, cur: any) => {
            acc = new BigNumber(acc).plus(cur.sum).toNumber();
            if (i.isOwner && i.isCreator) {
              acc = new BigNumber(acc)
                .plus(cur.objTxs[TransactionType.ROYALTY])
                .toNumber();
            }
            return acc;
          }, 0);
          return (
            <div key={i.id} className="transactions__item">
              <div
                className={cs("transactions__item-title", {
                  "transactions__item-title_opened": opened.includes(i.id),
                })}
                onClick={() => {
                  setOpened(ps => {
                    if (ps.includes(i.id)) {
                      return ps.filter(j => j !== i.id);
                    }
                    return [...ps, i.id];
                  });
                  // setLastOpened(ps => (ps === i.id ? "" : i.id));
                }}
              >
                <div>{i.titleFormatted} </div>
                <div
                  style={{
                    padding: "1px 5px",
                    borderRadius: 4,
                    color: totalSum >= 0 ? "#00f" : "#f00",
                  }}
                >
                  {totalSum > 0 && "+"}
                  {totalSum} Matic
                </div>
              </div>
              <div
                className={cs("transactions__item-info-wrapper", {
                  "transactions__item-info-wrapper_opened": opened.includes(
                    i.id
                  ),
                })}
              >
                {i.operations?.map((j: any, ind: number) => (
                  <Fragment key={ind}>
                    <div className="transactions__item-info">
                      <div>{j.timeCreated}</div> <div>{j.reason}</div>{" "}
                      <div
                        style={{
                          color: j.sum >= 0 ? "#00f" : "#f00",
                        }}
                      >
                        {j.sum > 0 && "+"}
                        {j.sum} MATIC
                      </div>
                    </div>
                    {i.isOwner && i.isCreator && (
                      <div className="transactions__item-info">
                        <div>{j.timeCreated}</div>{" "}
                        <div>
                          {`${
                            j.objTxs[TransactionType.BUY]
                              ? t("gamesTxs.forfeitedPieceNumber", {
                                  number: j.relatedPiece + 1,
                                })
                              : j.relatedPiece !== null
                              ? t("gamesTxs.pieceNumber", {
                                  number: j.relatedPiece + 1,
                                  action: "",
                                })
                              : t("gamesTxs.entryFee")
                          } ${t("gamesTxs.royalty")}`}
                        </div>{" "}
                        <div
                          style={{
                            color: "#00f",
                          }}
                        >
                          +{j.objTxs[TransactionType.ROYALTY]} MATIC
                        </div>
                      </div>
                    )}
                  </Fragment>
                ))}
                {/* {i.operations?.map((j: any, ind: number) =>
                  j.isSell ? (
                    j.transactions.map((k: any) => (
                      <div key={k.id} className="transactions__item-info">
                        <div>{j.timeCreated}</div>{" "}
                        <div>{k.type === "BUY" ? "SELL" : k.type}</div>{" "}
                        <div style={{ color: "#00f" }}>+{k.amount} MATIC</div>
                      </div>
                    ))
                  ) : (
                    <div key={ind} className="transactions__item-info">
                      <div>{j.timeCreated}</div> <div>{j.reason}</div>{" "}
                      <div
                        style={{
                          color: j.sum >= 0 ? "#00f" : "#f00",
                        }}
                      >
                        {j.sum > 0 && "+"}
                        {j.sum} MATIC
                      </div>
                    </div>
                  )
                )} */}
              </div>
            </div>
          );
        })}
      </div>
      <Pagination
        pageSize={pageSize}
        totalCount={totalCount}
        pageInfo={pageInfo}
        fetchMore={fetchMore}
        variables={variables}
        setPageSize={setPageSize}
      />
    </>
  );
};

export default GamesTxs;
