import { createEvent, restore } from "effector";
import { TModal } from "src/types/common";

export const checkWalletConnectOnInit = createEvent();

export const setModal = createEvent<TModal>();
export const $modal = restore(setModal, null);

export const setMobileMenuIsOpen = createEvent<boolean>();
export const $mobileMenuIsOpen = restore(setMobileMenuIsOpen, false);
