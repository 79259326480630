import React, { FC } from "react";
import { useStore } from "effector-react";
import { useQuery } from "@apollo/client";
import cs from "classnames";
import { useTranslation } from "react-i18next";

import "./index.scss";
import { $account, $shortAccount } from "src/store/wallet";
import ConnectWallet from "../ConnectWallet";
import UserImage from "../UserImage";
import AccountMenu from "../AccountMenu";
import { USERS } from "src/graphQl/users";
import { $loggedIn } from "src/store/profile";
import Dropdown from "../Dropdown";

interface IProps {
  isMobile?: boolean;
}

const Account: FC<IProps> = ({ isMobile }) => {
  const { t } = useTranslation();
  const account = useStore($account);
  const shortAccount = useStore($shortAccount);
  const loggedIn = useStore($loggedIn);

  const { data } = useQuery(USERS, {
    variables: {
      where: {
        address: { eq: account },
      },
    },
    // skip: !account,
  });

  if (!account || !loggedIn) {
    return <ConnectWallet />;
  }

  const accountInfo = data?.users?.nodes?.[0];

  const userInfo = (
    <>
      <UserImage
        imageUrl={accountInfo?.avatarUrl}
        className={cs("account__user-img", {
          "account__user-img_mobile": isMobile,
        })}
      />
      <div className="account__user-name">
        {accountInfo?.username || shortAccount}
      </div>
      <div className="account__vertical-divider">|</div>
      {/* <span className="account__address">{shortAccount}</span> */}
      <span className="account__title">{t("dropdown.accountMenu.title")}</span>
    </>
  );

  return (
    <div
      className={cs("account", {
        account_show: isMobile,
      })}
    >
      {/* <div className="account__wins">
        <AiFillStar className="account__wins-icon" />
        <span className="account__wins-count">5</span>{" "}
      </div> */}
      {/* <UserImage
        imageUrl={accountInfo?.avatarUrl}
        className={cs("account__user-img", {
          "account__user-img_mobile": isMobile,
        })}
      /> */}
      {isMobile ? (
        userInfo
      ) : (
        <Dropdown title={userInfo} className="account__dropdown">
          <AccountMenu isAdmin={accountInfo?.roles?.includes("admin")} />
        </Dropdown>
      )}
    </div>
  );
};

export default Account;
