import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./index.scss";
import useWindowSize from "src/hooks/useWindowSize";
import Button from "../Button";

interface IProps {
  className?: string;
}

const CreateGameBtn: FC<IProps> = ({ className }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [width] = useWindowSize();
  const isMobile = width <= 500;

  return (
    <Button
      className="create-game-btn"
      onClick={() => history.push("/select-nft")}
    >
      {isMobile ? "+" : t("buttons.createGame")}
    </Button>
  );
};

export default CreateGameBtn;
