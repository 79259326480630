import React, { FC, useEffect, useState } from "react";
import { Document, Page } from "react-pdf";

import "./index.scss";
import Layout from "src/components/common/Layout";
import { setModal } from "src/store/app";

interface IProps {
  file: string;
}

const PdfReader: FC<IProps> = ({ file }) => {
  const [numPages, setNumPages] = useState(0);
  // const [pageNumber, setPageNumber] = useState(1);
  useEffect(() => {
    setModal(null);
  }, []);

  return (
    <Layout className="gradient-layout">
      <div className="pdf-reader">
        <Document
          file={file}
          onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        >
          {numPages > 0 &&
            Array.from(Array(numPages).keys()).map(i => (
              <Page key={i} pageNumber={i + 1} scale={1.5} />
            ))}
        </Document>
        {/* <div className="page-controls">
          <button
            disabled={pageNumber <= 1}
            onClick={() => setPageNumber(pageNumber - 1)}
            type="button"
            aria-label="Previous page"
          >
            ‹
          </button>
          <span>
            Page {pageNumber} of {numPages}
          </span>
          <button
            disabled={pageNumber >= numPages}
            onClick={() => setPageNumber(pageNumber + 1)}
            type="button"
            aria-label="Next page"
          >
            ›
          </button>
        </div> */}
      </div>
    </Layout>
  );
};

export default PdfReader;
