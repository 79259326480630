import React, { FC, ReactNode, useState } from "react";
import cs from "classnames";
import { useTranslation } from "react-i18next";

import Button from "../Button";

import "./index.scss";

interface IProps {
  onConfirm: () => void;
  onCancel?: () => void;
  title: string;
  okText?: string;
  cancelText?: string;
  className?: string;
  children: ReactNode;
}

const PopConfirm: FC<IProps> = props => {
  const { t } = useTranslation();
  const {
    onConfirm,
    title,
    okText = t("popConfirm.defaultOk"),
    cancelText = t("popConfirm.defaultCancel"),
    className,
    children,
  } = props;

  const [visible, setVisible] = useState(false);

  const confirmHandler = async () => {
    try {
      setVisible(false);
      onConfirm();
    } catch (err) {
      setVisible(false);
    }
  };

  const classContent = cs("pop-confirm__content", className, {
    "pop-confirm__content_hidden": !visible,
  });

  return (
    <div className="pop-confirm">
      <span onClick={() => setVisible(ps => !ps)}>{children}</span>
      <div className={classContent}>
        <div className="pop-confirm__text">{title}</div>
        <div className="pop-confirm__buttons">
          <Button className="pop-confirm__btn" onClick={confirmHandler}>
            {okText}
          </Button>
          <Button
            className="pop-confirm__cancel"
            onClick={() => setVisible(false)}
          >
            {cancelText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PopConfirm;
