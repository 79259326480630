import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import "./index.scss";
import Layout from "src/components/common/Layout";
import { ReactComponent as LogoSvg } from "src/assets/Logo.svg";

const ComingSoon = () => {
  const { t } = useTranslation();

  return (
    <Layout className="gradient-layout coming-soon__layout">
      <div className="coming-soon">
        <LogoSvg fill="red" className="coming-soon__logo" />
        <div className="coming-soon__title">{t("pages.comingSoon.title")}</div>
        <div className="coming-soon__text">
          {t("pages.comingSoon.launching")}
        </div>
        <div className="coming-soon__text">
          {t("pages.comingSoon.contact")}{" "}
          <CopyToClipboard
            text="support@puzzled.space"
            onCopy={() =>
              toast.success(
                t("notifications.copiedToClipboard", {
                  item: t("email"),
                })
              )
            }
          >
            <a href="mailto:support@puzzled.space">support@puzzled.space</a>
          </CopyToClipboard>
        </div>
      </div>
    </Layout>
  );
};

export default ComingSoon;
