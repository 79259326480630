import { IAccount } from "src/graphQl/accounts";
import { gql } from "@apollo/client";

export enum WalletOperationStatus {
  CREATED = "CREATED",
  DONE = "DONE",
}

export interface IAccountOperation {
  account?: IAccount;
  accountId: string;
  amount: string;
  expirationTime: number;
  from?: string;
  id: number;
  originalAmount: number;
  signature: string;
  status: WalletOperationStatus;
  to: string;
  tx?: string;
}

export const WITHDRAW_OPERATIONS = gql`
  query WithdrawOperations($where: WithdrawOperationFilterInput, $first: Int) {
    withdrawOperation(where: $where, first: $first) {
      nodes {
        id
        to
        amount
        expirationTime
        signature
      }
    }
  }
`;
