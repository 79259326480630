import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";

import "normalize.css";
import { createBrowserHistory } from "history";
import "./styles/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./store/init";
import { apolloClient } from "./graphQl";
import { IS_DEMO } from "./config";
import { isMobile } from "./utils/helpers";
import MobileDemoApp from "./components/MobileDemoApp";
import "./i18n";

const customHistory = createBrowserHistory();

const app =
  IS_DEMO && isMobile() ? (
    <MobileDemoApp />
  ) : (
    <ApolloProvider client={apolloClient}>
      <Router history={customHistory}>
        <App />
      </Router>
    </ApolloProvider>
  );

ReactDOM.render(app, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
