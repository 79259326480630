import React, { FC } from "react";
import cs from "classnames";
import "./index.scss";
import { FaUserCircle } from "react-icons/fa";

interface ImageProps {
  imageUrl?: string | undefined;
  className?: string;
}

const UserImage: FC<ImageProps> = ({ imageUrl, className }) => {
  return (
    <div className={cs("user-image", className)}>
      {imageUrl ? (
        <img src={imageUrl} alt="Avatar" />
      ) : (
        <FaUserCircle className="user-image__default" />
      )}
    </div>
  );
};

export default UserImage;
