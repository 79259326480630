import BigNumber from "bignumber.js";
import { effectErrorHandler, isValidAddress } from "src/utils/helpers";
import { $createGameForm, createGame, setField } from "./index";
import AuctionGameService from "src/services/AuctionGameService";
import { GAME_CONFIG, MAX_ARTIST_NAME_LENGTH } from "src/config/constants";
import i18n from "src/i18n";

$createGameForm.on(setField, (state, { key, value }) => ({
    ...state,
    [key]: value,
}));

createGame.use(async ({ nftId, history, collectionAddress, account }) => {
    try {
        const formParams = $createGameForm.getState();

        const {
            featured,
            maxPlayersCount = 0,
            minPlayersCount,
            startTime,
            maxGameDuration,
            description,
            artist,
            mapId,
            entryPrices,
        } = formParams;
        console.log(formParams);

        const maxGameDurationSeconds = new BigNumber(maxGameDuration)
            .multipliedBy(60)
            .toNumber();

        if (new Date(startTime).getTime() <= Date.now()) {
            throw new Error(i18n.t("notifications.createGame.startTimeErr"));
        }

        if (!maxGameDurationSeconds) {
            throw new Error(i18n.t("notifications.createGame.descriptionErr"));
        }
        if (+minPlayersCount < GAME_CONFIG.minPlayersCount) {
            throw new Error(
                i18n.t("notifications.createGame.minPlayersCountErr", {
                    minPlayersCount: GAME_CONFIG.minPlayersCount,
                })
            );
        }
        if (
            maxPlayersCount &&
            +maxPlayersCount < +GAME_CONFIG.minPlayersCount
        ) {
            throw new Error(
                i18n.t("notifications.createGame.maxPlayersCountErr", {
                    maxPlayersCount: GAME_CONFIG.minPlayersCount,
                })
            );
        }

        if (maxPlayersCount && +maxPlayersCount < +minPlayersCount) {
            throw new Error(
                i18n.t("notifications.createGame.maxLessMinPlayersErr")
            );
        }

        if (maxGameDurationSeconds < GAME_CONFIG.minGameDurationSec) {
            throw new Error(
                i18n.t("notifications.createGame.minDurationErr", {
                    minDuration: `${GAME_CONFIG.minGameDurationSec / 60}m`,
                })
            );
        }
        if (maxGameDurationSeconds > GAME_CONFIG.maxGameDurationSec) {
            throw new Error(
                i18n.t("notifications.createGame.maxDurationErr", {
                    maxDuration: `${
                        GAME_CONFIG.maxGameDurationSec / 7 / 24 / 60 / 60
                    } week`,
                })
            );
        }

        const { data } = await AuctionGameService.create({
            nftId,
            minPlayersCount: +minPlayersCount,
            maxPlayersCount: +maxPlayersCount,
            startTime: new Date(startTime).toISOString(),
            description: description,
            artist: artist,
            featured,
            maxGameDurationSeconds,
            mapId,
            entryPrice: entryPrices,
        });
        history.push(`/game/${data.id}`);
    } catch (err: any) {
        const errMsg = effectErrorHandler(err);
        throw new Error(errMsg);
    }
});
