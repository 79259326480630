import {
  createEffect,
  createEvent,
  createStore,
  forward,
  restore,
} from "effector";
import web3 from "web3";

import { POLYGON_CHAIN_ID } from "src/config";
import { IUnconfirmedTx } from "src/types/wallet";
import { IDeposit } from "src/services/PuzzledSpaceWalletService";
import { IAccountOperation } from "src/graphQl/withdrawOperations";
import { signer } from "src/utils/ethers";
import { effectErrorHandler } from "src/utils/helpers";
import { createGate } from "effector-react";

export const GetBalanceGate = createGate();
export const $account = createStore<string | null>(null);
// export const $tokenBalance = createStore<string>("0");
export const $shortAccount = $account.map(
  // account => `${account?.slice(0, 2)}...${account?.slice(account.length - 20)}`
  account => `${account?.slice(0, 5)}...${account?.slice(-5)}`
);
export const changeAccount = createEvent<string | null>();
export const checkConnectWallet = createEvent();
export const connectWallet = createEffect<void, string>();
// export const getBalance = createEffect<
//   { tokenContractAddress: string },
//   string
// >();

export const $unconfirmedTxs = createStore<IUnconfirmedTx[]>([
  // {
  //   chainId: "4",
  //   hash: "0x13e296d2d98aba755147a9baa1493d72919cfc3b382f5f02e208defba2e86608",
  // },
]);
export const addUnconfirmedTx = createEvent<{
  tx: IUnconfirmedTx;
  callback?: () => void;
}>();
export const deleteUnconfirmedTx = createEvent<IUnconfirmedTx["hash"]>();

export const changeNetwork = createEffect<string, string>();
export const setChainId = createEvent<string | null>();
export const $chainId = restore(setChainId, null);
export const $isWrongChain = $chainId.map(
  chainId => chainId !== POLYGON_CHAIN_ID
);

export const deposit = createEffect<IDeposit, void>();
export const withdraw = createEffect<
  {
    contractId: string;
    amount: number;
    contractAddress: string;
    nonExecutedWalletOperation?: IAccountOperation;
  },
  void
>();
export const reloadBalance = createEffect<void, void>();

export const setTxsFilterDate = createEvent<Date | null | undefined>();
export const $txsFilterDate = restore(setTxsFilterDate, null);

export const getWalletBalance = createEffect<void, string>(async () => {
  try {
    if (!signer) return "0";
    const balance = await signer.getBalance();
    return web3.utils.fromWei(balance.toString());
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

export const setWalletBalance = createEvent<string>();
export const $walletBalance = restore(setWalletBalance, "0").on(
  getWalletBalance.doneData,
  (_, balance) => balance
);

forward({
  from: [setChainId, GetBalanceGate.open, deposit.done, withdraw.done],
  to: [getWalletBalance, reloadBalance],
});
