import React, { FC } from "react";
import cs from "classnames";
import "./index.scss";

interface LayoutProps {
  className?: string;
  mainPage?: boolean;
  showFooter?: boolean;
}

const Layout: FC<LayoutProps> = (props) => {
  const { children, className } = props;

  return <div className={cs("layout", className)}>{children}</div>;
};

export default Layout;
