import React, { FC, MouseEvent } from "react";
import { useStore } from "effector-react";
import cs from "classnames";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./index.scss";
import { $account, changeAccount } from "src/store/wallet";
import ConnectWallet from "../ConnectWallet";
import Button from "../Button";
import { $loggedIn, checkAuth, login, logout } from "src/store/profile";
import { setMobileMenuIsOpen, setModal } from "src/store/app";
import AccountBalance from "src/components/common/AccountBalance";
import { isMobileNotMetamask } from "src/utils/helpers";
import { METAMASK_DEEP_LINK } from "src/config";

interface IProps {
  isMobile?: boolean;
  isAdmin?: boolean;
}

const AccountMenu: FC<IProps> = ({ isMobile, isAdmin }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const account = useStore($account);
  const loggedIn = useStore($loggedIn);
  const loggingIn = useStore(login.pending);
  const loggingOut = useStore(logout.pending);
  const checkingAuth = useStore(checkAuth.pending);

  if (!account) {
    return <ConnectWallet />;
  }

  const onClick = () => {
    if (loggedIn) {
      changeAccount(null);
      logout();
    } else {
      if (isMobileNotMetamask()) {
        window.open(METAMASK_DEEP_LINK);
      } else {
        login();
      }
    }
  };

  const closeMenu = (e: MouseEvent<HTMLElement>) => {
    const el = e.target as HTMLElement;
    if (el.classList.contains("account-menu__item")) {
      setMobileMenuIsOpen(false);
    }
  };

  return (
    <div
      className={cs("account-menu", {
        "account-menu_mobile": isMobile,
      })}
      onClick={closeMenu}
    >
      {/* <div className="account-menu__title">My Puzzled Space</div> */}
      <AccountBalance />
      {/* <div
        className="account-menu__item"
        onClick={() => history.push("/account")}
      >
        My Account
      </div> */}
      {/* <div className="account-menu__item" onClick={() => setModal("deposit")}>
        My Balance Manager
      </div> */}
      <div
        className="account-menu__item"
        onClick={() => history.push("/my-nfts")}
      >
        {t("dropdown.accountMenu.nfts")}
      </div>
      <div
        className="account-menu__item"
        onClick={() => history.push("/my-games")}
      >
        {t("dropdown.accountMenu.games")}
      </div>
      <div
        className="account-menu__item"
        onClick={() => history.push("/my-collections")}
      >
        {t("dropdown.accountMenu.collections")}
      </div>
      <div
        className="account-menu__item"
        onClick={() => history.push("/games-transactions")}
      >
        {t("dropdown.accountMenu.transactions")}
      </div>
      <div className="account-menu__item" onClick={() => setModal("profile")}>
        {t("dropdown.accountMenu.profile")}
      </div>
      <div
        className="account-menu__item"
        onClick={() => history.push("/select-nft")}
      >
        {t("dropdown.accountMenu.createGame")}
      </div>
      <div
        className="account-menu__item"
        onClick={() => history.push("/approved-collections")}
      >
        {t("dropdown.accountMenu.approvedCollections")}
      </div>
      {isAdmin && (
        <div
          className="account-menu__item"
          onClick={() => history.push("/admin")}
        >
          {t("dropdown.accountMenu.adminPanel")}
        </div>
      )}
      {isMobile ? (
        loggedIn && (
          <div
            className="account-menu__item account-menu__sign-out"
            onClick={onClick}
          >
            {t("dropdown.accountMenu.signOut")}
          </div>
        )
      ) : (
        <Button
          className="account-menu__logout-btn"
          onClick={onClick}
          loading={loggingIn || loggingOut || checkingAuth}
        >
          {loggedIn ? t("buttons.logout") : t("buttons.login")}
        </Button>
      )}
    </div>
  );
};

export default AccountMenu;
