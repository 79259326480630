import axios from "axios";
import { API_URL } from "src/config";
import { logout } from "src/store/profile";
import { IAuthResponse } from "../types/auth";
// import { AuthResponse } from "../models/response/AuthResponse";

const $api = axios.create({
  withCredentials: false,
  baseURL: API_URL,
});

$api.interceptors.request.use((config: any) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  return config;
});

$api.interceptors.response.use(
  config => {
    return config;
  },
  async error => {
    const originalRequest = error.config;
    const refreshToken = localStorage.getItem("refreshToken");
    if (
      error?.response?.status === 401 &&
      error.config &&
      !error.config._isRetry &&
      refreshToken
    ) {
      originalRequest._isRetry = true;
      try {
        const response = await axios.post<IAuthResponse>(
          `${API_URL}/Auth/refreshToken/${refreshToken}`
        );
        localStorage.setItem("accessToken", response.data.accessToken);
        return $api.request(originalRequest);
      } catch (e) {
        logout();
      }
    }
    throw error;
  }
);

export default $api;
