import $api from "../http";
import { AxiosResponse } from "axios";

export interface IWhitelistCollection {
  // name: string;
  chainId: number;
  address: string;
  // mediaLink?: string;
  discordCommunity?: string;
  twitterCommunity?: string;
}

export interface IUpdateMediaLinkParams {
  nftCollectionId: string;
  mediaLink?: string;
  field: "discordCommunity" | "twitterCommunity";
}

export interface ChangeCollectionImageParams {
  nftCollectionId: string;
  image: File;
}

export default class AdminService {
  static setPlatformFee(
    gameId: string,
    fee: number
  ): Promise<AxiosResponse<string>> {
    return $api.post<string>(
      `/Admin/game/platformFee?gameId=${gameId}&fee=${fee}`
    );
  }
  static cancelGame(gameId: string): Promise<AxiosResponse<string>> {
    return $api.post<string>(`/Admin/auction_game/cancel?gameId=${gameId}`);
  }
  static addCollectionToWhitelist(
    params: IWhitelistCollection
  ): Promise<AxiosResponse<string>> {
    return $api.post<string>("/Admin/contract/whitelist", params);
  }
  static deleteCollectionFromWhitelist(
    nftCollectionId: string
  ): Promise<AxiosResponse<string>> {
    return $api.delete<string>("/Admin/contract/whitelist", {
      params: { nftCollectionId },
    });
  }
  static updateCollectionMediaLink({
    nftCollectionId,
    mediaLink,
  }: IUpdateMediaLinkParams): Promise<AxiosResponse<string>> {
    return $api.patch<string>(
      `/Admin/contract/whitelist/${nftCollectionId}/mediaLink?mediaLink=${mediaLink}`
    );
  }
  static updateCollectionDiscordLink({
    nftCollectionId,
    mediaLink,
  }: IUpdateMediaLinkParams): Promise<AxiosResponse<string>> {
    return $api.patch<string>(
      `/Admin/contract/whitelist/${nftCollectionId}/discordCommunity?discordCommunity=${mediaLink}`
    );
  }
  static updateCollectionTwitterLink({
    nftCollectionId,
    mediaLink,
  }: IUpdateMediaLinkParams): Promise<AxiosResponse<string>> {
    return $api.patch<string>(
      `/Admin/contract/whitelist/${nftCollectionId}/twitterCommunity?twitterCommunity=${mediaLink}`
    );
  }
  static changeCollectionImage({
    nftCollectionId,
    image,
  }: ChangeCollectionImageParams): Promise<AxiosResponse<string>> {
    const data = new FormData();
    data.set("image", image);
    return $api.post<string>(`/Admin/nft_collection/${nftCollectionId}`, data);
  }
}
