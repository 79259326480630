import { Field, Message, Type } from "protobufjs";

@Type.d("BidSocketMessage")
export class BidSocketMessage extends Message<BidSocketMessage> {
    @Field.d(1, "string")
    GameId!: string;

    @Field.d(2, "int32")
    PieceId!: number;

    @Field.d(3, "string")
    HighestBid!: string;

    @Field.d(4, "string")
    HighestBidder!: string;

    @Field.d(5, "string")
    HighestBidWinTime!: string;
}

@Type.d("DrawSocketMessage")
export class DrawSocketMessage extends Message<DrawSocketMessage> {
    @Field.d(1, "int32")
    PieceId!: number;

    @Field.d(2, "string")
    User!: string;

    @Field.d(3, "string")
    GameId!: string;

    @Field.d(4, "string")
    TxId!: string;
}

@Type.d("GameCreatedSocketMessage")
export class GameCreatedSocketMessage extends Message<GameCreatedSocketMessage> {
    @Field.d(1, "string")
    Id!: string;

    @Field.d(2, "string")
    Creator!: string;

    @Field.d(3, "string")
    Tx!: string;

    @Field.d(4, "string")
    BlockchainGameId!: string;
}

@Type.d("GameJoinSocketMessage")
export class GameJoinSocketMessage extends Message<GameJoinSocketMessage> {
    @Field.d(1, "string")
    GameId!: string;

    @Field.d(2, "string")
    Address!: string;
}

// export enum GameStatus {
//   Created = 0,
//   Canceled = 1,
//   Started = 2,
//   Complete = 3,
//   CompleteNoWinner = 4,
// }

export enum AuctionGameStatus {
    Created = 0,
    Canceled = 1,
    Started = 2,
    Complete = 3,
}

@Type.d("GameStatusChangedSocketMessage")
export class GameStatusChangedSocketMessage extends Message<GameStatusChangedSocketMessage> {
    @Field.d(1, "string")
    GameId!: string;

    @Field.d(2, AuctionGameStatus)
    NewStatus!: AuctionGameStatus;
}

@Type.d("GameWinSocketMessage")
export class GameWinSocketMessage extends Message<GameWinSocketMessage> {
    @Field.d(1, "string")
    GameId!: string;

    @Field.d(2, "string")
    Winner!: string;
}

@Type.d("GameLotClaimedSocketMessage")
export class GameLotClaimedSocketMessage extends Message<GameLotClaimedSocketMessage> {
    @Field.d(1, "string")
    GameId!: string;

    @Field.d(2, "int32")
    PieceId!: number;

    @Field.d(3, "string")
    Winner!: string;

    @Field.d(4, "string")
    WinnerName!: string;
}

@Type.d("NewLotSocketMessage")
export class NewLotSocketMessage extends Message<NewLotSocketMessage> {
    @Field.d(1, "string")
    GameId!: string;

    @Field.d(2, "int32")
    PieceId!: number;

    @Field.d(3, "string")
    StartPrice!: string;

    @Field.d(4, "string")
    Id!: string;

    @Field.d(5, "string")
    LotId!: string;
}

@Type.d("NftDepositSocketMessage")
export class NftDepositSocketMessage extends Message<NftDepositSocketMessage> {
    @Field.d(1, "string")
    Id!: string;

    @Field.d(2, "string")
    Owner!: string;

    @Field.d(3, "string")
    TokenId!: string;

    @Field.d(4, "string")
    Tx!: string;
}

@Type.d("NftDepositedSocketMessage")
export class NftDepositedSocketMessage extends Message<NftDepositedSocketMessage> {
    @Field.d(1, "string")
    NFTId!: string;
}

// message SocketMessageBase {
//   oneof subtype {
//      SocketBid SocketBid = 2;
//      SocketDraw SocketDraw = 3;
//      SocketGameCreated SocketGameCreated = 4;
//      SocketGameJoin SocketGameJoin = 5;
//      SocketGameStatusChanged SocketGameStatusChanged = 6;
//      SocketGameWin SocketGameWin = 7;
//      SocketLotClaimed SocketLotClaimed = 8;
//      SocketNewLot SocketNewLot = 9;
//      SocketNFTDeposit SocketNFTDeposit = 10;
//      SubscriptionWebSocketSocketMessage SubscriptionWebSocketSocketMessage = 11;
//      WebSocketSocketMessage WebSocketSocketMessage = 12;
//   }
// }

// @Type.d("MessageBaseModel")
// export class MessageBaseModel extends Message<MessageBaseModel> {
//   @Field.d(1, "string")
//   GameId!: string;
// }

export enum WebsocketSubscriptionMessageType {
    SubscribeByAddress,
    SubscribeByGame,
    Global,
}

@Type.d("SubscriptionWebSocketSocketMessage")
export class SubscriptionWebSocketSocketMessage extends Message<SubscriptionWebSocketSocketMessage> {
    @Field.d(1, "int32")
    Type!: number;

    @Field.d(2, "string")
    Key!: string;
}

enum WebsocketMessageType {
    GameStatusChanged = 0,
    Draw = 1,
    Join = 2,
    NewGame = 3,
    Won = 4,
    NFTDeposit = 5,
    Bid = 6,
    NewLot = 7,
    LotClaim = 8,
    NFTDeposited = 9,
    AuctionGameLot = 10,
    WalletDeposit = 11,
    WalletBalanceChange = 12,
    PenguinLeaderBoardChange = 13,
}

@Type.d("WebSocketMessageModel")
export class WebSocketMessageModel extends Message<WebSocketMessageModel> {
    @Field.d(1, WebsocketMessageType)
    Type!: WebsocketMessageType;

    @Field.d(2, "bytes")
    Data!: Uint8Array;
}

@Type.d("SocketAuctionGameLot")
export class SocketAuctionGameLot extends Message<SocketAuctionGameLot> {
    @Field.d(1, "string")
    LotId!: string;

    @Field.d(2, "string")
    GameId!: string;

    @Field.d(3, "int32")
    Piece!: number;

    @Field.d(4, "double")
    CurrentBid!: number;

    @Field.d(5, "string")
    Bidder!: string;

    @Field.d(6, "string")
    WinTime!: string;

    @Field.d(7, "int32")
    Id!: number;
}

// @Type.d("Test")
// export class Test extends Message<Test> {
//   @Field.d(1, "int32")
//   Type!: number;

//   @Field.d(2, "string")
//   Key!: string;
// }

@Type.d("WebSocketDeposit")
export class WebSocketDeposit extends Message<WebSocketDeposit> {
    @Field.d(1, "string")
    To!: string;

    @Field.d(2, "double")
    Amount!: number;

    @Field.d(3, "uint32")
    ChainId!: number;
}

@Type.d("WebSocketBalanceChange")
export class WebSocketBalanceChange extends Message<WebSocketBalanceChange> {
    @Field.d(1, "string")
    Address!: string;

    @Field.d(2, "double")
    Amount!: number;

    @Field.d(3, "double")
    Escrow!: number;
}
