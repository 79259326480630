import $api from "../http";
import { AxiosResponse } from "axios";

export interface ISendReportParams {
  gameId: string;
  message: string;
}

export default class ReportService {
  static sendReport(params: ISendReportParams): Promise<AxiosResponse<void>> {
    return $api.post<void>("/report", params);
  }
}
