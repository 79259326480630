import React, { FC, ReactNode } from "react";
import Countdown from "react-countdown";
import "./index.scss";

export interface ICountdownRendererProps {
  total: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
  completed: boolean;
  // api: { ... },
  // props: { ... },
  // formatted: { ... }
}

interface IProps {
  date: Date | number;
  onComplete?: () => void;
  onTick?: (p: any) => void;
  overtime?: boolean;
  renderer: (props: ICountdownRendererProps) => ReactNode;
}

const CountdownComponent: FC<IProps> = (props) => {
  const { date, onComplete, overtime, renderer, onTick } = props;
  return (
    <Countdown
      className="countdown"
      date={date}
      // intervalDelay={0}
      precision={3}
      zeroPadTime={2}
      renderer={renderer}
      overtime={overtime}
      onComplete={onComplete}
      onTick={onTick}
    />
  );
};

export default CountdownComponent;
