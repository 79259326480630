import $api from "../http";
import { AxiosResponse } from "axios";
import { IAccountOperation } from "src/graphQl/withdrawOperations";

export interface IWithdrawApiParams {
  contractId: string;
  amount: number;
}

export default class WalletService {
  static withdraw(
    params: IWithdrawApiParams
  ): Promise<AxiosResponse<IAccountOperation>> {
    return $api.post<IAccountOperation>("/Wallet/withdrawal", params);
  }
}
