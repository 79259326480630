import { useMemo } from "react";

export interface IPageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
  endCursor: string;
}

const useCursorPagination = (
  pageInfo: IPageInfo,
  totalCount: number,
  pageSize: number
) => {
  const firstIndex =
    pageInfo?.startCursor &&
    Buffer.from(pageInfo?.startCursor, "base64").toString();

  const totalPages = useMemo(
    () => Math.ceil(totalCount / pageSize),
    [totalCount, pageSize]
  );

  const currentPage = useMemo(() => {
    const page = +firstIndex / pageSize;
    return isNaN(page) ? 0 : page + 1;
  }, [firstIndex, pageSize]);

  return { currentPage, totalPages };
};

export default useCursorPagination;
