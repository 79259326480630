import { gql, useQuery } from "@apollo/client";
import BigNumber from "bignumber.js";

import { IAuctionGame } from "src/graphQl/auctionGames";
import { IAccountTransaction } from "./transactions";
import { IUser } from "./users";

export enum TransactionOperationType {
  DEPOSIT = "DEPOSIT",
  OVER_BID = "OVER_BID",
  CANCEL_GAME = "CANCEL_GAME",
  GAME_COMPLETE_WIN = "GAME_COMPLETE_WIN",
  WITHDRAWAL = "WITHDRAWAL",
  JOIN_TO_GAME = "JOIN_TO_GAME",
  BID = "BID",
  WITHDRAWAL_COMPLETE = "WITHDRAWAL_COMPLETE",
  ENTRY_PRICE_AFTER_WIN_GAME = "ENTRY_PRICE_AFTER_WIN_GAME",
  WIN_LOT = "WIN_LOT",
}

export interface IAccountOperation {
  game: IAuctionGame;
  gameId: string;
  id: string;
  relatedPiece: number;
  totalAmount: number;
  transactions: IAccountTransaction[];
  type: TransactionOperationType;
  user: IUser;
  user2: IUser;
  userAddress: string;
  userAddress2: string;
  time: string;
}

export const ACCOUNT_OPERATIONS = gql`
  query AccountOperation(
    $where: AccountOperationFilterInput
    $first: Int
    $order: [AccountOperationSortInput!]
  ) {
    accountOperations(where: $where, first: $first, order: $order) {
      nodes {
        game
        gameId
        id
        relatedPiece
        totalAmount
        transactions
        type
        user
        user2
        userAddress
        userAddress2
        time
      }
    }
  }
`;

export const OPERATIONS_AMOUNT_SPENT = gql`
  query AccountOperation(
    $where: AccountOperationFilterInput
    $first: Int
    $order: [AccountOperationSortInput!]
  ) {
    accountOperations(where: $where, first: $first, order: $order) {
      nodes {
        totalAmount
      }
    }
  }
`;

export const useAmountSpent = ({
  address,
  gameId,
}: {
  address?: string | null;
  gameId?: string;
}) => {
  const { data, error, loading } = useQuery<{
    accountOperations: {
      nodes: IAccountOperation[];
    };
  }>(OPERATIONS_AMOUNT_SPENT, {
    variables: {
      where: {
        userAddress: { eq: address },
        gameId: { eq: gameId },
        relatedPiece: { neq: null },
        type: {
          eq: TransactionOperationType.WIN_LOT,
        },
      },
      first: 1000,
    },
  });

  return {
    amountSpent: data?.accountOperations?.nodes?.reduce(
      (acc: number, cur: IAccountOperation) => {
        acc = new BigNumber(acc).plus(cur.totalAmount).toNumber();
        return acc;
      },
      0
    ),
    error,
    loading,
  };
};
