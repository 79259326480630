import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Account from "../Account";
import Layout from "../Layout";
import Logo from "../Logo";
import AccountBalance from "../AccountBalance";
import "./index.scss";
import Dropdown from "../Dropdown";
import Socials from "../Socials";
import useWindowSize from "src/hooks/useWindowSize";
import { ReactComponent as PolygonIcon } from "src/assets/PolygonIcon.svg";
import { ReactComponent as ArbitrumIcon } from "src/assets/ArbitrumIcon.svg";
import penguin from "../../../assets/pungvin.png";

const Gameplay = () => {
    const { t } = useTranslation();

    return (
        <Dropdown title={t("dropdown.gameplay.title")}>
            <Link to="/quick-guide" className="header__link">
                {t("dropdown.gameplay.quickGuide")}
            </Link>
            <Link to="/how-to-play" className="header__link">
                {t("dropdown.gameplay.playingGame")}
            </Link>
            <Link to="/how-to-create" className="header__link">
                {t("dropdown.gameplay.creatingGame")}
            </Link>
            <Link to="/games-history" className="header__link">
                {t("dropdown.gameplay.gamesHistory")}
            </Link>
            {/* <Link to="/faq" className="header__link">
      FAQs
    </Link> */}
        </Dropdown>
    );
};

const About = () => {
    const { t } = useTranslation();

    return (
        <Dropdown title={t("dropdown.about.title")}>
            <Link to="/for-collections" className="header__link">
                {t("dropdown.about.forCollections")}
            </Link>
            <Link to="/about-us" className="header__link">
                {t("dropdown.about.aboutUs")}
            </Link>
            <Link to="/contact-us" className="header__link">
                {t("dropdown.about.contactUs")}
            </Link>
            <Socials className="header__socials" />
        </Dropdown>
    );
};

const Chains = () => {
    const { t } = useTranslation();

    return (
        <div className="header__chain-links">
            <a
                href="https://polygon.puzzled.space/"
                title={t("chainLinks.polygon")}
            >
                <PolygonIcon />
            </a>
            <a
                href="https://arbitrum.puzzled.space/"
                title={t("chainLinks.arbitrum")}
            >
                <ArbitrumIcon />
            </a>
        </div>
    );
};

const Header = () => {
    const [width] = useWindowSize();
    const isMobile = width <= 680;

    return (
        <Layout className="header-layout">
            <header className="header">
                <div className="header-left">
                    <Logo className="header__logo" hideTitleOnMobile />
                </div>

                <div className="header__links">
                    <Gameplay />
                    <About />
                    <Chains />
                    {isMobile && <AccountBalance />}
                </div>
                {!isMobile && <Account />}
            </header>
        </Layout>
    );
};

export default Header;
