import React, { FC } from "react";
import LoaderComponent from "react-loader-spinner";
import cs from "classnames";
import "./index.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

interface Props {
  className?: string;
  width?: string;
  height?: string;
  color?: string;
  type?:
    | "ThreeDots"
    | "Audio"
    | "BallTriangle"
    | "Bars"
    | "Circles"
    | "Grid"
    | "Hearts"
    | "Oval"
    | "Puff"
    | "Rings"
    | "TailSpin"
    | "Watch"
    | "RevolvingDot"
    | "Triangle"
    | "Plane"
    | "MutatingDots"
    | "CradleLoader";
}

const Loader: FC<Props> = (props) => {
  const {
    className,
    width = "50px",
    height = "30px",
    color = "rgba(0, 0, 0, .3)",
    type = "ThreeDots",
  } = props;

  const onPage = className && className.split(" ").includes("page-loader");

  return (
    <div className={cs("loader-wrapper", className)}>
      <LoaderComponent
        type={onPage ? "Grid" : type}
        color={color}
        width={onPage ? 50 : width}
        height={onPage ? 50 : height}
      />
    </div>
  );
};

export default Loader;
