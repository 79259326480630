import { gql } from "@apollo/client";

export const MY_GAMES_COUNT = gql`
  query CountAuctionGames($address: String) {
    countAuctionGames(address: $address) {
      count
      status
    }
  }
`;
