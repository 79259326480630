import React, { MouseEvent } from "react";
import { useStore } from "effector-react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import "./index.scss";
import { $mobileMenuIsOpen, setMobileMenuIsOpen } from "src/store/app";
import Account from "../Account";
import Socials from "../Socials";
import AccountMenu from "../AccountMenu";
import { $loggedIn } from "src/store/profile";
import { USERS } from "src/graphQl/users";
import { $account } from "src/store/wallet";
import { ReactComponent as PolygonIcon } from "src/assets/PolygonIcon.svg";
import { ReactComponent as ArbitrumIcon } from "src/assets/ArbitrumIcon.svg";
import penguin from "../../../assets/pungvin.png";

const MobileMenu = () => {
    const { t } = useTranslation();
    const mobileMenuIsOpen = useStore($mobileMenuIsOpen);
    const loggedIn = useStore($loggedIn);
    const account = useStore($account);

    const closeMenu = (e: MouseEvent<HTMLElement>) => {
        const el = e.target as HTMLElement;
        if (el.tagName === "A") {
            setMobileMenuIsOpen(false);
        }
    };

    const { data } = useQuery(USERS, {
        variables: {
            where: {
                address: { eq: account },
            },
        },
    });

    const accountInfo = data?.users?.nodes?.[0];

    return (
        <Menu
            onStateChange={({ isOpen }) => setMobileMenuIsOpen(isOpen)}
            right
            isOpen={mobileMenuIsOpen}
            width={"100%"}
            className={"mobile-menu"}
            bodyClassName="ReactModal__Body--open"
            pageWrapId={"page-wrap"}
        >
            <div className="mobile-menu__block" onClick={closeMenu}>
                <div className="header-left mobile-menu__block-title">
                    <a
                        className="logo-title header-penguin"
                        href="https://minirace.puzzled.space/"
                    >
                        <span>NEW </span>
                        <img src={penguin} alt="" />
                        <span>DEMO</span>
                    </a>
                </div>

                <div className="mobile-menu__block-title">
                    {t("dropdown.gameplay.title")}
                </div>
                <Link to="/quick-guide" className="mobile-menu__link">
                    {t("dropdown.gameplay.quickGuide")}
                </Link>
                <Link to="/how-to-play" className="mobile-menu__link">
                    {t("dropdown.gameplay.playingGame")}
                </Link>
                <Link to="/how-to-create" className="mobile-menu__link">
                    {t("dropdown.gameplay.creatingGame")}
                </Link>
                <Link to="/games-history" className="mobile-menu__link">
                    {t("dropdown.gameplay.gamesHistory")}
                </Link>
                {/* <Link to="/faq" className="mobile-menu__link">
          FAQ
        </Link> */}
            </div>
            <div className="mobile-menu__block" onClick={closeMenu}>
                <div className="mobile-menu__block-title">
                    {t("dropdown.about.title")}
                </div>
                <Link to="/for-collections" className="mobile-menu__link">
                    {t("dropdown.about.forCollections")}
                </Link>
                <Link to="/about-us" className="mobile-menu__link">
                    {t("dropdown.about.aboutUs")}
                </Link>
                <Link to="/contact-us" className="mobile-menu__link">
                    {t("dropdown.about.contactUs")}
                </Link>
            </div>
            <div className="mobile-menu__block" onClick={closeMenu}>
                <div className="mobile-menu__block-title">
                    {t("chainLinks.title")}
                </div>
                <div className="mobile-menu__chain-links">
                    <a
                        href="https://polygon.puzzled.space/"
                        title={t("chainLinks.polygon")}
                    >
                        <PolygonIcon />
                    </a>
                    <a
                        href="https://arbitrum.puzzled.space/"
                        title={t("chainLinks.arbitrum")}
                    >
                        <ArbitrumIcon />
                    </a>
                </div>
            </div>
            <div className="mobile-menu__block" onClick={closeMenu}>
                <Account isMobile />
                {loggedIn && (
                    <AccountMenu
                        isMobile
                        isAdmin={accountInfo?.roles?.includes("admin")}
                    />
                )}
            </div>
            <div className="mobile-menu__block" onClick={closeMenu}>
                {/* <div className="mobile-menu__block-title">Socials</div> */}
                <Socials className="mobile-menu__socials" />
            </div>
        </Menu>
    );
};

export default MobileMenu;
