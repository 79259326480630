import $api from "../http";
import { AxiosResponse } from "axios";

export interface IDiscordConnectParams {
  signature: string;
  id: string;
}
export default class DiscordService {
  static connect({
    signature,
    id,
  }: IDiscordConnectParams): Promise<AxiosResponse<void>> {
    return $api.post<void>(`/Discord?signature=${signature}&id=${id}`);
  }
}
