import React, { MouseEvent, FC } from "react";
import cs from "classnames";
import "./index.scss";
import Loader from "../Loader";
import ConnectWallet from "../ConnectWallet";
import { $account, $chainId } from "../../../store/wallet";
import { $loggedIn } from "../../../store/profile";
import { useStore } from "effector-react";
import { ARBITRUM_CHAIN_ID, POLYGON_CHAIN_ID } from "../../../config";

interface Props {
  className?: string;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  loading?: boolean;
  walletRequired?: boolean;
  ignoreChain?: boolean;
}

const Button: FC<Props> = props => {
  const {
    onClick,
    disabled,
    className,
    loading,
    children,
    walletRequired,
    ignoreChain,
    // ...rest
  } = props;
  const account = useStore($account);
  const loggedIn = useStore($loggedIn);
  const chainId = useStore($chainId);

  if (
    walletRequired &&
    (!account || !loggedIn || (!ignoreChain && chainId !== POLYGON_CHAIN_ID && chainId !== ARBITRUM_CHAIN_ID))
  ) {
    return <ConnectWallet className="wallet-required-for-button" />;
  }

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={cs("button", className)}
    >
      {loading ? (
        <Loader
          type="ThreeDots"
          color="rgba(0, 0, 0, .3)"
          height="100%"
          // width="4rem"
        />
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
