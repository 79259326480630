import React, { FC, useEffect, useMemo, useState } from "react";
import { useGate, useStore } from "effector-react";
import { useLazyQuery } from "@apollo/client";
// import { Link } from "react-router-dom";
import { HiOutlineTrash } from "react-icons/hi";
// import { BsChevronLeft } from "react-icons/bs";
import dayjs from "dayjs";
import ReactTooltip from "react-tooltip";
import { FaDiscord, FaTwitter, FaEdit } from "react-icons/fa";
import BigNumber from "bignumber.js";
import { toast } from "react-toastify";
import cs from "classnames";
import { useTranslation } from "react-i18next";
import CopyToClipboard from "react-copy-to-clipboard";

import "./index.scss";
import { setModal } from "src/store/app";
import Button from "src/components/common/Button";
import Table from "src/components/common/Table";
import networksConfig from "src/utils/networksConfig";
import { TChainId } from "src/types/common";
import {
  getOpenSeaLinkFromCollection,
  getShortAddress,
  round,
} from "src/utils/helpers";
import {
  $filters,
  $item,
  $sortBy,
  AdminPageGate,
  deleteCollectionFromWhitelist,
  setFilters,
  setItem,
  setSortBy,
} from "src/store/admin";
import {
  INftCollection,
  NFT_COLLECTIONS,
  NFT_COLLECTIONS_ADMIN,
} from "src/graphQl/nftCollections";
import PopConfirm from "src/components/common/PopConfirm";
// import NftContractService from "src/services/NftContractService";
import { DEFAULT_DATE_TIME_FORMAT } from "src/config/constants";
import { ACCOUNTS_BALANCES } from "src/graphQl/accounts";
import { IoCopyOutline } from "react-icons/io5";
import Pagination from "src/components/common/Pagination";
import { SortEnumType } from "src/types/graphQlCommon";
import Dropdown from "src/components/common/Dropdown";
import SelectComponent from "src/components/common/Select";
import { ARBITRUM_CHAIN_ID, ETH_CHAIN_ID, POLYGON_CHAIN_ID } from "src/config";
import { setFields } from "src/store/collections";

interface IProps {
  isAdmin?: boolean;
}

const Collections: FC<IProps> = ({ isAdmin }) => {
  useGate(AdminPageGate);
  const { t } = useTranslation();
  const deletingItem = useStore($item);
  const [list, setList] = useState<any>([]);
  const [pageSize, setPageSize] = useState(10);
  const [whereFilters, setWhereFilters] = useState({
    whiteListed: { eq: true },
  });
  const [order, setOrder] = useState([]);

  const filters = useStore($filters);
  const sortBy = useStore($sortBy);

  const variables = useMemo(() => {
    if (!!Object.keys(order).length) {
      return {
        where: whereFilters,
        order,
      };
    }
    return {
      where: whereFilters,
    };
  }, [whereFilters, order]);

  useEffect(() => {
    let order: any = [];
    !!sortBy.length &&
      sortBy.forEach((sorter: { id: string; desc: boolean }) => {
        if (sorter.id === "contractName") {
          order = [
            ...order,
            {
              contractName: sorter.desc ? SortEnumType.DESC : SortEnumType.ASC,
            },
          ];
        }
        if (sorter.id === "chainId") {
          order = [
            ...order,
            {
              chainId: sorter.desc ? SortEnumType.DESC : SortEnumType.ASC,
            },
          ];
        }
        if (sorter.id === "blockchainRoyaltyPercent") {
          order = [
            ...order,
            {
              blockchainRoyaltyPercent: sorter.desc
                ? SortEnumType.DESC
                : SortEnumType.ASC,
            },
          ];
        }
        if (sorter.id === "totalRoyaltyCollect") {
          order = [
            ...order,
            {
              totalRoyaltyCollect: sorter.desc
                ? SortEnumType.DESC
                : SortEnumType.ASC,
            },
          ];
        }
      });
    if (!!Object.keys(order).length) {
      setOrder(order);
    }
    if (sortBy.length === 0) {
      setOrder([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  useEffect(() => {
    let where: any = variables?.where || {};
    !!filters.length &&
      filters.forEach((filter: { id: string; value: string }) => {
        if (filter.id === "contractName") {
          where = {
            ...where,
            contractName: { contains: filter.value },
          };
        }
        if (filter.id === "collectionAddress") {
          where = {
            ...where,
            collectionAddress: { contains: filter.value.toLowerCase() },
          };
        }
        if (filter.id === "chainId") {
          where = {
            ...where,
            chainId: { eq: +filter.value },
          };
        }
      });
    if (!!Object.keys(where).length) {
      setWhereFilters(where);
    }
    if (filters.length === 0) {
      setWhereFilters({ whiteListed: { eq: true } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const [getCollections, { data: whitelistedContracts, loading, fetchMore }] =
    useLazyQuery(isAdmin ? NFT_COLLECTIONS_ADMIN : NFT_COLLECTIONS);

  useEffect(() => {
    getCollections({
      variables: {
        order: !!Object.keys(order).length ? order : undefined,
        where: !!Object.keys(whereFilters).length ? whereFilters : undefined,
        first: pageSize,
      },
      notifyOnNetworkStatusChange: true,
    });
  }, [pageSize, getCollections, whereFilters, order]);

  const [getWalletsBalances, { data: accountsBalances }] =
    useLazyQuery(ACCOUNTS_BALANCES);

  const collections = useMemo(
    () => whitelistedContracts?.nFTCollection?.nodes || [],
    [whitelistedContracts?.nFTCollection?.nodes]
  );
  const totalCount = whitelistedContracts?.nFTCollection?.totalCount || 0;
  const pageInfo = whitelistedContracts?.nFTCollection?.pageInfo;

  useEffect(() => {
    const getOwnersBalances = async () => {
      const ownersAddresses = collections.map((i: INftCollection) => i?.owner);
      getWalletsBalances({
        variables: {
          where: {
            address: {
              in: ownersAddresses,
            },
          },
        },
      });
    };
    collections && getOwnersBalances();
  }, [collections, getWalletsBalances]);

  const addressesBalances = accountsBalances?.accounts?.nodes;

  useEffect(() => {
    const setOwnersBalances = async () => {
      const collectionsWithOwnersBalances = collections.map(
        (i: INftCollection) => {
          const ownerBalance =
            addressesBalances.find(
              (j: { address: string; balance: number }) =>
                j.address === i?.owner
            )?.balance || 0;
          return {
            ...i,
            ownerBalance: round(ownerBalance, 0.000001),
          };
        }
      );
      setList(collectionsWithOwnersBalances);
    };
    addressesBalances && setOwnersBalances();
  }, [addressesBalances, collections]);

  const chainFilterOptions = [
    { label: t("dropdown.chains.all"), value: "" }
  ];
  if(ETH_CHAIN_ID){
    chainFilterOptions.push({ label: t("dropdown.chains.ethereum"), value: ETH_CHAIN_ID });
  }
  if(POLYGON_CHAIN_ID){
    chainFilterOptions.push({ label: t("dropdown.chains.polygon"), value: POLYGON_CHAIN_ID });
  }
  if(ARBITRUM_CHAIN_ID){
    chainFilterOptions.push({ label: t("dropdown.chains.arbitrum"), value: ARBITRUM_CHAIN_ID });
  }

  const data = React.useMemo(() => list || [], [list]);
  const getColumns = (deletingItem: string, isAdmin?: boolean) => {
    let columns: any = [
      {
        Header: t("tables.collections.name"),
        accessor: "contractName",
        width: isAdmin ? 150 : 300,
        maxWidth: isAdmin ? 150 : 300,
        Cell: ({ value, row }: any) =>
          row?.original && value ? (
            <a
              href={getOpenSeaLinkFromCollection(row?.original)?.openSeaLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {value}
            </a>
          ) : null,
      },
      {
        Header: t("tables.collections.image"),
        accessor: "contractImage",
        Cell: ({ value, row }: any) => (
          <div
            className={cs("admin-collections__nft-image-container", {
              "admin-collections__nft-image-container_admin": isAdmin,
            })}
          >
            {isAdmin && (
              <div
                className="admin-collections__update-image"
                onClick={() => {
                  setItem(row.original);
                  setModal("changeCollectionImage");
                }}
              >
                {t("tables.collections.changeImage")}
              </div>
            )}
            {value ? (
              <img
                className="admin-collections__nft-image"
                src={value}
                alt="Nft img"
              />
            ) : null}
          </div>
        ),
        disableFilters: true,
        disableSortBy: true,
        width: 90,
        maxWidth: 90,
      },
      {
        Header: t("tables.collections.address"),
        accessor: "collectionAddress",
        disableSortBy: true,
        width: isAdmin ? 150 : 300,
        maxWidth: isAdmin ? 150 : 300,
        Cell: ({ value }: any) =>
          value ? (
            <CopyToClipboard
              text={value}
              onCopy={() =>
                toast.success(
                  t("notifications.copiedToClipboard", { item: "Address" })
                )
              }
            >
              <div className="admin-collections__address">
                <div className="admin-collections__address-value">
                  {isAdmin ? getShortAddress(value) : value}
                </div>
                <IoCopyOutline />
              </div>
            </CopyToClipboard>
          ) : null,
      },
      {
        Header: t("tables.collections.category"),
        accessor: "category",
        width: 150,
        maxWidth: 150,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value, row }: any) => (
          <div className="admin-collections__links">
            {isAdmin ? (
              <Dropdown
                hideArrow
                title={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      fontSize: "14px",
                      textDecoration: "underline",
                      fontWeight: 500,
                    }}
                  >
                    {value?.name || "Other"}
                    <FaEdit style={{ width: "14px" }} />
                  </div>
                }
              >
                <div
                  onClick={() => {
                    setFields({
                      collection: row.original,
                      categoryId: row.original?.category?.id,
                    });
                    setModal("setCategory");
                    // setItem({
                    //   item: row.original,
                    //   updateField: "discordCommunity",
                    //   title: t("modals.updateMediaLink.title", {
                    //     media: "Discord Community",
                    //   }),
                    //   label: t("modals.updateMediaLink.mediaLinkLabel", {
                    //     media: "Discord Community",
                    //   }),
                    // });
                    // setModal("updateMediaLink");
                  }}
                  className="admin-collections__link"
                >
                  Edit
                </div>
              </Dropdown>
            ) : value ? (
              value.name
            ) : null}

            <ReactTooltip className="tooltip" />
          </div>
        ),
      },
      {
        Header: t("tables.collections.chain"),
        accessor: "chainId",
        Cell: ({ value }: any) =>
          value ? (
            <div>
              {value}, {networksConfig[value as TChainId]?.name}
            </div>
          ) : null,
        width: isAdmin ? 100 : 200,
        maxWidth: isAdmin ? 100 : 200,
        Filter: () => (
          <SelectComponent
            options={chainFilterOptions}
            onChange={(option: any) =>
              setWhereFilters(where => ({
                ...where,
                chainId:
                  option?.value === "" ? { nin: [] } : { eq: +option?.value },
              }))
            }
            className="admin-filter-select"
            defaultValueIndex={0}
          />
        ),
        disableSortBy: true,
      },
      {
        Header: t("tables.collections.links"),
        accessor: "discordCommunity",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value, row }: any) => (
          <div className="admin-collections__links">
            {isAdmin ? (
              <Dropdown
                hideArrow
                title={<FaDiscord data-tip={value || "Not set"} />}
              >
                {value && (
                  <div
                    onClick={() => window.open(value, "_blank")}
                    className="admin-collections__link"
                  >
                    Open
                  </div>
                )}
                <div
                  onClick={() => {
                    setItem({
                      item: row.original,
                      updateField: "discordCommunity",
                      title: t("modals.updateMediaLink.title", {
                        media: "Discord Community",
                      }),
                      label: t("modals.updateMediaLink.mediaLinkLabel", {
                        media: "Discord Community",
                      }),
                    });
                    setModal("updateMediaLink");
                  }}
                  className="admin-collections__link"
                >
                  Edit
                </div>
              </Dropdown>
            ) : value ? (
              <FaDiscord
                data-tip={value || "Not set"}
                onClick={() => (value ? window.open(value, "_blank") : null)}
              />
            ) : null}

            {isAdmin ? (
              <Dropdown
                hideArrow
                title={
                  <FaTwitter
                    data-tip={row.original.twitterCommunity || "Not set"}
                  />
                }
              >
                {row.original.twitterCommunity && (
                  <div
                    onClick={() =>
                      window.open(row.original.twitterCommunity, "_blank")
                    }
                    className="admin-collections__link"
                  >
                    Open
                  </div>
                )}
                <div
                  onClick={() => {
                    setItem({
                      item: row.original,
                      updateField: "twitterCommunity",
                      title: t("modals.updateMediaLink.title", {
                        media: "Twitter Community",
                      }),
                      label: t("modals.updateMediaLink.mediaLinkLabel", {
                        media: "Twitter Community",
                      }),
                    });
                    setModal("updateMediaLink");
                  }}
                  className="admin-collections__link"
                >
                  Edit
                </div>
              </Dropdown>
            ) : value ? (
              <FaTwitter
                data-tip={row.original.twitterCommunity || "Not set"}
                onClick={() =>
                  row.original.twitterCommunity
                    ? window.open(row.original.twitterCommunity, "_blank")
                    : null
                }
              />
            ) : null}
            <ReactTooltip className="tooltip" />
          </div>
        ),
        width: 100,
        maxWidth: 100,
      },
    ];
    if (isAdmin) {
      columns = [
        ...columns,
        {
          Header: t("tables.collections.royaltyPercent"),
          accessor: "blockchainRoyaltyPercent",
          Cell: ({ value, row }: any) => {
            const customRoyalty = new BigNumber(
              row.original.royaltyPercent || 0
            )
              .div(100)
              .toNumber();
            const royalty =
              customRoyalty > 0
                ? customRoyalty
                : new BigNumber(value).div(100).toNumber();
            return <>{royalty}%</>;
          },
          width: 100,
          maxWidth: 100,
          disableFilters: true,
        },
        {
          Header: t("tables.collections.totalRoyalty"),
          accessor: "totalRoyaltyCollect",
          Cell: ({ value }: any) => <>{value || 0}</>,
          width: 90,
          maxWidth: 90,
          disableFilters: true,
        },
        {
          Header: t("tables.collections.countGames"),
          accessor: "countGames",
          width: 80,
          maxWidth: 80,
          disableFilters: true,
          disableSortBy: true,
        },
        {
          Header: t("tables.collections.countImportedNFT"),
          accessor: "countImportedNFT",
          width: 80,
          maxWidth: 80,
          disableFilters: true,
          disableSortBy: true,
        },
        {
          Header: t("tables.collections.countUniqNFTsInGames"),
          accessor: "countUniqNFTsInGames",
          width: 80,
          maxWidth: 80,
          disableFilters: true,
          disableSortBy: true,
        },
        {
          Header: t("tables.collections.ownerBalance"),
          accessor: "ownerBalance",
          width: 100,
          maxWidth: 100,
          disableFilters: true,
          disableSortBy: true,
        },
        {
          Header: t("tables.collections.lastOwnerLogin"),
          accessor: "ownerLoginTime",
          Cell: ({ value }: any) =>
            value ? dayjs(value).format(DEFAULT_DATE_TIME_FORMAT) : "Unknown",
          width: 100,
          maxWidth: 100,
          disableFilters: true,
          disableSortBy: true,
        },
        {
          Header: t("tables.collections.actions"),
          accessor: "collection",
          disableFilters: true,
          disableSortBy: true,
          Cell: ({ row }: any) =>
            row?.original?.id ? (
              <PopConfirm
                onConfirm={() =>
                  deleteCollectionFromWhitelist(row?.original?.id)
                }
                title={t("popConfirms.removeCollection")}
                className="admin-collections__delete-pop-confirm"
              >
                {deletingItem === row?.original?.id ? (
                  "Deleting..."
                ) : (
                  <HiOutlineTrash className="admin-collections__delete-icon" />
                )}
                {/* <Button
                className="admin-collections__action"
                loading={deletingItem === row?.original?.id}
                disabled={deletingItem === row?.original?.id}
              >
                Delete
              </Button> */}
              </PopConfirm>
            ) : null,
          width: 80,
          maxWidth: 80,
        },
      ];
    }
    return columns;
  };

  const columns: any = React.useMemo(
    () => getColumns(deletingItem, isAdmin),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deletingItem, isAdmin]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // if (loading || !data) {
  //   return <Loader className="page-loader" />;
  // }

  return (
    <div className="admin-collections">
      <div className="admin-collections__header">
        <div className="admin-collections__title">
          Puzzled Approved Collections
        </div>
        {isAdmin && (
          <div className="admin-collections__add-nft-wrapper">
            <Button
              onClick={() => setModal("adminAddCollection")}
              className="admin-collections__add-nft"
            >
              {t("buttons.addCollection")}
            </Button>
          </div>
        )}
      </div>
      {/* <div className="page-description admin-collections__description"></div> */}
      <div
        className={cs("admin-collections__table-wrap", {
          "admin-collections__table-wrap_no-admin": !isAdmin,
        })}
      >
        <Table
          columns={columns}
          data={data}
          setFilters={setFilters}
          setSortBy={setSortBy}
          loading={loading}
          pageSize={pageSize}
        />
        {totalCount > 0 && (
          <Pagination
            pageSize={pageSize}
            totalCount={totalCount}
            pageInfo={pageInfo}
            fetchMore={fetchMore}
            variables={variables}
            setPageSize={setPageSize}
          />
        )}
      </div>
      <ReactTooltip className="tooltip" />
    </div>
  );
};

export default Collections;
