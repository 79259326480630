import { connectWallet } from "./../wallet/index";
import {
  $loggedIn,
  $registered,
  checkAuth,
  deleteAvatar,
  login,
  logout,
  register,
} from "./index";
import { signer } from "src/utils/ethers";
import AuthService from "src/services/AuthService";
import { effectErrorHandler } from "src/utils/helpers";
import ProfileService from "src/services/ProfileService";
import { USERS } from "src/graphQl/users";
import { apolloClient } from "src/graphQl";
import i18n from "src/i18n";
import { MAX_USERNAME_LENGTH } from "src/config/constants";

checkAuth.use(async () => {
  try {
    // const address = await signer?.getAddress();
    // const address = window?.ethereum?.selectedAddress;
    // const account = $account.getState();

    // if (address && account && addressesAreEquals(address, account)) {
    await AuthService.checkAuth();
    // const response = await AuthService.checkAuth();
    // return addressesAreEquals(response.data, address);
    // }
    return true;
  } catch (err) {
    // effectErrorHandler(err);
    throw Error();
  }
});

login.use(async () => {
  try {
    if (!signer) {
      throw Error(i18n.t("notifications.noMetamask"));
    }
    await connectWallet();
    const address = await signer.getAddress();
    const { data } = await AuthService.getNonce(address);
    const signature = await signer.signMessage(data?.nonce);
    const response = await AuthService.auth({ address, signature });
    const { accessToken, refreshToken } = response.data;
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

logout.use(() => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("walletConnected");
  apolloClient.clearStore();
  window.location.href = "/";
});

$loggedIn.on(checkAuth.doneData, (_, loggedIn) => loggedIn);
$loggedIn.on(login.doneData, () => true);
$loggedIn.on(logout.doneData, () => false);

register.use(async ({ username = "", avatar, isCanadian }) => {
  try {
    if (username.includes(" ")) {
      throw Error(i18n.t("notifications.profile.usernameSpacesErr"));
    }
    if (username.length > MAX_USERNAME_LENGTH) {
      throw Error(
        i18n.t("notifications.profile.usernameLengthErr", {
          maxLength: MAX_USERNAME_LENGTH,
        })
      );
    }
    const queries = [ProfileService.setProfile({ username, isCanadian })];
    // if (username) {
    //   queries.push(ProfileService.setProfile({ username }));
    // }
    if (avatar) {
      queries.push(ProfileService.setAvatar({ avatar }));
    }
    await Promise.all(queries);
    await apolloClient.refetchQueries({
      include: [USERS],
    });
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

deleteAvatar.use(async () => {
  try {
    const res = await ProfileService.deleteAvatar();
    console.log(res);
    await apolloClient.refetchQueries({
      include: [USERS],
    });
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

$registered.on(register.doneData, () => true);
