const networksConfig = {
  "56": {
    name: "Binance Smart Chain",
    rpcUrl: "https://bsc-dataseed.binance.org/",
    chainId: 56,
    blockExplorer: "https://bscscan.com",
    ticker: "BNB",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    openSeaNetworkPart: "",
  },
  "97": {
    name: "Smart Chain - Testnet",
    rpcUrl: "https://data-seed-prebsc-2-s2.binance.org:8545/",
    chainId: 97,
    blockExplorer: "https://testnet.bscscan.com",
    ticker: "BNB",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    openSeaNetworkPart: "",
  },
  "137": {
    name: "Polygon",
    rpcUrl: "https://polygon-rpc.com",
    // https://rpc-mainnet.matic.network or
    // https://rpc-mainnet.maticvigil.com or
    // https://rpc-mainnet.matic.quiknode.pro
    chainId: 137,
    blockExplorer: "https://polygonscan.com",
    ticker: "MATIC",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    openSeaNetworkPart: "matic/",
  },
  "80001": {
    name: "Mumbai Testnet",
    // rpcUrl: "https://rpc-mumbai.matic.today/",
    rpcUrl: "https://rpc-mumbai.maticvigil.com/",
    // rpcUrl: "https://solitary-red-frost.matic-testnet.quiknode.pro/db9f414a5011968cb486548a479ae43d8c26a7db/",
    chainId: 80001,
    blockExplorer: "https://mumbai.polygonscan.com",
    ticker: "MATIC",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    openSeaNetworkPart: "mumbai/",
  },
  "1": {
    name: "Ethereum Mainnet",
    rpcUrl: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    chainId: 1,
    blockExplorer: "https://etherscan.io",
    ticker: "ETH",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    openSeaNetworkPart: "ethereum/",
  },
  "4": {
    name: "Rinkeby",
    rpcUrl: "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    chainId: 4,
    blockExplorer: "https://rinkeby.etherscan.io",
    ticker: "ETH",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    openSeaNetworkPart: "rinkeby/",
  },
  "5": {
    name: "Goerli",
    rpcUrl: "https://goerli.infura.io/v3/",
    chainId: 5,
    blockExplorer: "https://goerli.etherscan.io",
    ticker: "ETH",
    nativeCurrency: {
      name: "GoerliETH",
      symbol: "GoerliETH",
      decimals: 18,
    },
    openSeaNetworkPart: "goerli/",
  },
  "42161": {
    name: "Arbitrum",
    rpcUrl: "https://arbitrum.blockpi.network/v1/rpc/public",
    chainId: 42161,
    blockExplorer: "https://arbiscan.io/",
    ticker: "ETH",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    openSeaNetworkPart: "arbitrum/",
  },
};

export default networksConfig;
