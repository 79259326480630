import React, { FC, useEffect } from "react";
import { SiEthereum } from "react-icons/si";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import cs from "classnames";

import { ARBITRUM_CHAIN_ID, ETH_CHAIN_ID, POLYGON_CHAIN_ID } from "src/config";
import { ReactComponent as PolygonIcon } from "src/assets/PolygonIcon.svg";
import { ReactComponent as ArbitrumIcon } from "src/assets/ArbitrumIcon.svg";

import "./index.scss";

interface IProps {
  chainId: string | number;
  tooltip?: string;
  className?: string;
}

const ChainIcon: FC<IProps> = ({ chainId, tooltip, className }) => {
  const { t } = useTranslation();
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const getIcon = () => {
    if (chainId?.toString() === POLYGON_CHAIN_ID) {
      return (
        <PolygonIcon
          className={cs("chain-icon", className)}
          data-tip={tooltip || t("tooltips.polygonSign")}
        />
      );
    }
    if (chainId?.toString() === ETH_CHAIN_ID) {
      return (
        <SiEthereum
          className={cs("chain-icon", className)}
          data-tip={tooltip || t("tooltips.ethereumSign")}
        />
      );
    }
    if (chainId?.toString() === ARBITRUM_CHAIN_ID) {
      return (
        <ArbitrumIcon
          className={cs("chain-icon", "_not-fill", className)}
          data-tip={tooltip || t("tooltips.arbitrumSign")}
        />
      );
    }
    return null;
  };

  return <>{getIcon()}</>;
};

export default ChainIcon;
