import { gql } from "@apollo/client";

import { IAccount } from "./accounts";
import { IAccountOperation } from "./accountOperations";

export enum AccountTransactionState {
  CANCELED = "CANCELED",
  CREATED = "CREATED",
  EXECUTED = "EXECUTED",
}

export enum TransactionType {
  BUY = "BUY",
  DEPOSIT = "DEPOSIT",
  GAME_CREATOR = "GAME_CREATOR",
  ROYALTY = "ROYALTY",
  SELL = "SELL",
  SYSTEM_FEE = "SYSTEM_FEE",
  WITHDRAWAL = "WITHDRAWAL",
}

export interface IAccountTransaction {
  accountTransactionState: AccountTransactionState;
  amount: number;
  fromAccount: IAccount;
  id: string;
  operation: IAccountOperation;
  toAccount: IAccount;
  type: TransactionType;
  time: string;
}

export const TRANSACTIONS = gql`
  query Transactions(
    $where: AccountTransactionFilterInput
    $first: Int
    $last: Int
    $order: [AccountTransactionSortInput!]
    $after: String
    $before: String
  ) {
    transactions(
      where: $where
      first: $first
      order: $order
      after: $after
      before: $before
      last: $last
    ) {
      nodes {
        accountTransactionState
        amount
        fromAccount {
          address
        }
        id
        operation {
          userAddress
          userAddress2
          time
          relatedPiece
        }
        toAccount {
          address
        }
        type
      }
      totalCount
      pageInfo {
        startCursor
        hasPreviousPage
        endCursor
        hasNextPage
      }
    }
  }
`;

export const ADMIN_TRANSACTIONS = gql`
  query Transactions(
    $where: AccountTransactionFilterInput
    $first: Int
    $last: Int
    $order: [AccountTransactionSortInput!]
    $after: String
    $before: String
  ) {
    transactions(
      where: $where
      first: $first
      order: $order
      after: $after
      before: $before
      last: $last
    ) {
      nodes {
        accountTransactionState
        amount
        fromAccount {
          address
        }
        id
        operation {
          type
          userAddress
          userAddress2
          time
          relatedPiece
          game {
            nFTInCustody {
              collection {
                contractName
              }
              name
            }
            royaltyPercent
            featured
            entryPrices
          }
        }
        toAccount {
          address
        }
        type
      }
      totalCount
      pageInfo {
        startCursor
        hasPreviousPage
        endCursor
        hasNextPage
      }
    }
  }
`;
