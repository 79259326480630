import { createGate } from "effector-react";
import { ETH_CHAIN_ID, POLYGON_CHAIN_ID, ARBITRUM_CHAIN_ID } from "src/config";
import { createEffect, createEvent, createStore, restore } from "effector";
import { Contract } from "src/graphQl/contracts";
import { INftCollection } from "src/graphQl/nftCollections";
import { INftInCustodyTransferParams } from "src/services/NftInCustodyService";
import { INftInCustody } from "src/graphQl/nftInCustody";
import { createGame, CreateGameGate } from "../createGame";

export enum NftsPageModes {
  CreateMultipleGames,
  TransferMultipleNfts,
}

export const AddNftModalGate = createGate();

export const $addNftForm = createStore<{
  openSeaLink: string;
  nftAddress: string;
  tokenId: string;
}>({ openSeaLink: "", nftAddress: "", tokenId: "" }).reset(
  AddNftModalGate.close
);
export const addNftFormSetField = createEvent<{ key: string; value: any }>();

export const setSelectedChainId = createEvent<string>();
export const $selectedChainId = restore(setSelectedChainId, ETH_CHAIN_ID || POLYGON_CHAIN_ID || ARBITRUM_CHAIN_ID || ""); //TODO

export const transferNft = createEffect<
  {
    bridgeContracts: Contract[];
    whitelistedCollections: INftCollection[];
  },
  void
>();

export const transferNftsToOtherUser = createEffect<
  INftInCustodyTransferParams,
  void
>();

export const bulkNftUpload = createEffect<
  {
    bridgeContracts: Contract[];
    collectionAddress: string;
    chainId: string;
    nftIds: string;
    nftCounts: string;
  },
  void
>();

export const withdrawNft = createEffect<
  { contractAddress: string; nftId: string; to: string },
  void
>();

export const reloadNftsInCustody = createEffect<void, void>();

export const setTransferError = createEvent<boolean>();
export const $transferError = restore(setTransferError, false).reset([
  transferNft.doneData,
  AddNftModalGate.close,
]);

export const setNftsPageMode = createEvent<NftsPageModes | null>();
export const $nftsPageMode = restore(setNftsPageMode, null).reset([
  transferNftsToOtherUser.done,
  createGame.done,
]);

export const setNftList = createEvent<INftInCustody[]>();
export const toggleNft = createEvent<INftInCustody>();
export const $nftList = restore(setNftList, [])
  .on(toggleNft, (_, nftItem) => {
    const index = _.findIndex(i => i.id === nftItem.id);
    const newArray = [..._];
    if (index !== -1) {
      newArray.splice(index, 1);
    } else {
      newArray.push(nftItem);
    }
    return newArray;
  })
  .reset([CreateGameGate.close, setNftsPageMode, transferNftsToOtherUser.done]);

export const $nftIds = $nftList.map(list => list.map(i => i.id));
