import React, { FC } from "react";
import {
  BsChevronDoubleLeft,
  BsChevronDoubleRight,
  BsChevronLeft,
  BsChevronRight,
} from "react-icons/bs";
import { useTranslation } from "react-i18next";

import "./index.scss";
import useCursorPagination, { IPageInfo } from "src/hooks/useCursorPagination";

const pageSizes = [10, 20, 30, 40, 50];

interface IProps {
  pageSize: number;
  totalCount: number;
  pageInfo: IPageInfo;
  fetchMore: (params: any) => void;
  variables: any;
  setPageSize: any;
}

const Pagination: FC<IProps> = props => {
  const { t } = useTranslation();

  const { pageSize, pageInfo, totalCount, fetchMore, variables, setPageSize } =
    props;

  const { currentPage, totalPages } = useCursorPagination(
    pageInfo,
    totalCount,
    pageSize
  );

  const updateQuery = (prevResult: any, { fetchMoreResult }: any) =>
    fetchMoreResult;

  return (
    <div className="table-pagination">
      <button
        onClick={() => {
          const buff = Buffer.from("-1");
          const base64Cursor = buff.toString("base64");
          fetchMore({
            variables: {
              ...variables,
              after: base64Cursor,
              first: pageSize,
              last: undefined,
            },
            updateQuery,
          });
        }}
        disabled={!pageInfo?.hasPreviousPage}
      >
        <BsChevronDoubleLeft />
      </button>{" "}
      <button
        onClick={() =>
          fetchMore({
            variables: {
              ...variables,
              before: pageInfo?.startCursor,
              last: pageSize,
              first: undefined,
            },
            updateQuery,
          })
        }
        disabled={!pageInfo?.hasPreviousPage}
      >
        <BsChevronLeft />
      </button>{" "}
      <button
        onClick={() =>
          fetchMore({
            variables: {
              ...variables,
              after: pageInfo?.endCursor,
              first: pageSize,
              last: undefined,
            },
            updateQuery,
          })
        }
        disabled={!pageInfo?.hasNextPage}
      >
        <BsChevronRight />
      </button>{" "}
      <button
        onClick={e => {
          const cursor = (totalPages - 1) * pageSize - 1;
          const buff = Buffer.from(cursor.toString());
          const base64Cursor = buff.toString("base64");
          fetchMore({
            variables: {
              ...variables,
              after: base64Cursor,
              first: pageSize,
              last: undefined,
            },
            updateQuery,
          });
        }}
        disabled={!pageInfo?.hasNextPage}
      >
        <BsChevronDoubleRight />
      </button>{" "}
      <span>
        {t("pagination.page")}{" "}
        <strong>
          {currentPage} {t("pagination.of")} {totalPages}
        </strong>{" "}
      </span>
      <span>
        {t("pagination.goToPage")}{" "}
        <input
          type="number"
          defaultValue={currentPage || 1}
          onChange={e => {
            if (+e.target.value > 0) {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              const cursor = page * pageSize - 1;
              const buff = Buffer.from(cursor.toString());
              const base64Cursor = buff.toString("base64");
              fetchMore({
                variables: {
                  ...variables,
                  after: base64Cursor,
                  first: pageSize,
                  last: undefined,
                },
                updateQuery,
              });
            }
          }}
        />
      </span>{" "}
      {t("pagination.show")}
      <select value={pageSize} onChange={e => setPageSize(+e.target.value)}>
        {pageSizes.map(pageSize => (
          <option key={pageSize} value={pageSize}>
            {pageSize}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Pagination;
