import web3 from "web3";
import { ethers } from "ethers";
import PuzzledSpaceWalletAbi from "../contracts/PuzzledSpaceWallet.json";
import { IUnconfirmedTx } from "../types/wallet";

export interface IDeposit {
  contractAddress: string;
  address: string;
  amount: string;
}

export interface IWithdraw {
  contractAddress: string;
  to: string;
  amount: string;
  signatureExpirationTime: number;
  operationId: number;
  signature: string;
}

const getContract = (contractAddress: string) => {
  const provider: any = window.ethereum
    ? new ethers.providers.Web3Provider(window?.ethereum, "any")
    : null;
  const signer = provider?.getSigner();
  const contractInstance = new ethers.Contract(
    contractAddress,
    PuzzledSpaceWalletAbi,
    provider
  );
  const contractInstanceWithSigner = contractInstance?.connect(signer);
  return contractInstanceWithSigner;
};

export default class PuzzledSpaceWalletService {
  static deposit({
    contractAddress,
    address,
    amount,
  }: IDeposit): Promise<IUnconfirmedTx> {
    return getContract(contractAddress)?.deposit(address, {
      value: web3.utils.toWei(amount.toString()),
    });
  }

  static withdraw({
    contractAddress,
    to,
    amount,
    signatureExpirationTime,
    operationId,
    signature,
  }: IWithdraw): Promise<IUnconfirmedTx> {
    console.log(to, amount, signatureExpirationTime, operationId, signature);
    return getContract(contractAddress)?.withdrawal(
      to,
      amount,
      signatureExpirationTime,
      operationId,
      signature
    );
  }
}
