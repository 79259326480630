import { gql } from "@apollo/client";

export enum ContractTypes {
    BRIDGE = "BRIDGE",
    GAME = "GAME",
    TOKEN = "TOKEN",
    WALLET = "WALLET",
}

export interface Contract {
    id: string;
    name: string;
    env: string;
    chainId: number;
    lastBlock: number;
    address: string;
    type: ContractTypes;
    active: boolean;
}

export const GET_CONTRACTS = gql`
    query Contracts($where: ContractFilterInput) {
        contracts(where: $where) {
            nodes {
                id
                chainId
                address
            }
        }
    }
`;
