export const IS_DEMO = process.env.REACT_APP_IS_DEMO === "true";
export const API_URL =
  process.env.REACT_APP_API_URL || "https://api-dev.puzzled.space";
export const WS_URL =
  process.env.REACT_APP_WS_URL || "wss://api-dev.puzzled.space/ws";

export const POLYGON_CHAIN_ID = process.env.REACT_APP_POLYGON_CHAIN_ID || null;
export const ETH_CHAIN_ID = process.env.REACT_APP_ETH_CHAIN_ID || null;
export const ARBITRUM_CHAIN_ID = process.env.REACT_APP_ARBITRUM_CHAIN_ID || null;

export const COMING_SOON =
  process.env.REACT_APP_COMING_SOON === "true" &&
  localStorage.getItem("isAdmin") !== "true";
export const METAMASK_DEEP_LINK =
  process.env.REACT_APP_METAMASK_DEEP_LINK ||
  "https://metamask.app.link/dapp/beta.puzzled.space";

export const DISCORD_LINK = process.env.REACT_APP_DISCORD_LINK;

export const CAPTCHA_SITEKEY =
  process.env.REACT_APP_CAPTCHA_SITEKEY ||
  "6Ldaqt0hAAAAAN1gNF2DtZA517C6TKTy4wnvAxwN";

export const GA_ID = process.env.REACT_APP_GA_ID || "G-PD59QM9MGG";
