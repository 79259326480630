import React, { FC } from "react";
import cs from "classnames";
import { useGate, useStore } from "effector-react";
import { useTranslation } from "react-i18next";

import Button from "../../common/Button";
import "./index.scss";
import HelpInfoIcon from "../../common/HelpInfoIcon";
import {
  $fields,
  CollectionGate,
  setFields,
  setRoyalty,
} from "../../../store/collections";
import { MAX_COLLECTION_ROYALTY } from "src/config/constants";

interface IProps {}

const SetRoyalty: FC<IProps> = () => {
  useGate(CollectionGate);
  const { t } = useTranslation();
  const { collection, royaltyPercent } = useStore($fields);
  const loading = useStore(setRoyalty.pending);

  return (
    <div className="set-royalty">
      <div className="set-royalty__title">
        {collection?.contractName || collection?.collectionAddress}
      </div>
      <div className="set-royalty__field">
        <div className="set-royalty__field-label">
          {t("modals.setRoyalty.royaltyLabel")}{" "}
          <HelpInfoIcon
            bold
            data-tip={t("tooltips.setRoyaltyModal")}
            data-multiline
          />
        </div>
        <input
          value={royaltyPercent}
          onChange={e => setFields({ royaltyPercent: e.target.value })}
          className={cs("set-royalty__field-value")}
          placeholder={t("modals.setRoyalty.royaltyPlaceholder", {
            maxRoyalty: MAX_COLLECTION_ROYALTY,
          })}
          autoFocus
        />
        <div className="import-collection__field-unit">%</div>
      </div>
      <Button
        className="set-royalty__btn"
        onClick={() => setRoyalty()}
        loading={loading}
        disabled={loading}
        walletRequired
        ignoreChain
      >
        {t("modals.setRoyalty.confirmBtn")}
      </Button>
    </div>
  );
};

export default SetRoyalty;
