import React, { FC } from "react";
import cs from "classnames";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Layout from "../Layout";
import Logo from "../Logo";
import { ReactComponent as Puzzle2 } from "src/assets/Puzzle2.svg";
import "./index.scss";
import Socials from "../Socials";
import { COMING_SOON } from "src/config";

interface IProps {
  hidePuzzle?: boolean;
}

const Footer: FC<IProps> = ({ hidePuzzle }) => {
  const { t } = useTranslation();

  return (
    <Layout className="footer-layout">
      <footer
        className={cs("footer", {
          footer_center: COMING_SOON,
        })}
      >
        <Logo className="footer__logo" />
        <div className="footer__copyright">&#9400; {t("footer.copyright")}</div>
        {!COMING_SOON && (
          <div className="footer__terms">
            <Link to="/privacy" target="_blank" rel="noopener noreferrer">
              {t("footer.privacy")}
            </Link>{" "}
            |{" "}
            <Link to="/terms" target="_blank" rel="noopener noreferrer">
              {t("footer.termsConditions")}
            </Link>
          </div>
        )}
        {!COMING_SOON && <Socials className="footer__socials" />}
      </footer>
      {!hidePuzzle && <Puzzle2 className="footer__part-hidden-puzzle" />}
    </Layout>
  );
};

export default Footer;
