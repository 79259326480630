import React, { FC } from "react";
import DatePicker from "react-datepicker";
import cs from "classnames";
import "react-datepicker/dist/react-datepicker.css";

import "./index.scss";
import CustomInput from "../Datepicker/CustomInput";

interface IProps {
  onChange: (date: Date | [Date | null, Date | null] | null) => void;
  className?: string;
  wrapperClassName?: string;
  startDate: Date | undefined | null;
  endDate: Date | undefined | null;
  showTimeSelect?: boolean;
  dateFormat?: string;
  minDate?: Date;
  maxDate?: Date;
  placeholder?: string;
  isClearable?: boolean;
  isButton?: boolean;
}

const RangeDatePicker: FC<IProps> = props => {
  const {
    startDate,
    endDate,
    onChange,
    className,
    showTimeSelect,
    minDate,
    dateFormat,
    wrapperClassName,
    placeholder,
    isClearable,
    isButton,
    maxDate,
  } = props;

  return (
    <div className={cs("datepicker-wrapper", wrapperClassName)}>
      <DatePicker
        className={cs("datepicker", className)}
        onChange={date => onChange(date)}
        showTimeSelect={showTimeSelect}
        minDate={minDate}
        dateFormat={dateFormat || "dd MMM yyyy HH:mm"}
        timeFormat="HH:mm"
        placeholderText={placeholder}
        isClearable={isClearable}
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        customInput={isButton ? <CustomInput /> : undefined}
        maxDate={maxDate}
      />
    </div>
  );
};

export default RangeDatePicker;
