import React, { FC, useEffect } from "react";
import cs from "classnames";
import { IoHelp } from "react-icons/io5";
import ReactTooltip from "react-tooltip";

import "./index.scss";
import { AiOutlineInfo } from "react-icons/ai";

interface IProps {
  onClick?: () => any;
  className?: string;
  help?: boolean;
  big?: boolean;
  bold?: boolean;
}

const HelpInfoIcon: FC<IProps> = ({
  className,
  onClick,
  help,
  big,
  bold,
  ...props
}) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <div
      className={cs("help-info-icon", className, {
        "help-info-icon_help": help,
        "help-info-icon_big": big,
        "help-info-icon_bold": bold,
      })}
      onClick={() => (onClick ? onClick() : null)}
      {...props}
    >
      {help ? (
        <IoHelp className="help-info-icon__help" />
      ) : (
        <AiOutlineInfo className="help-info-icon__info" />
      )}
      {/* <ReactTooltip className="tooltip" /> */}
    </div>
  );
};

export default HelpInfoIcon;
