import React, { FC, ReactNode, useCallback } from "react";
import { useDropzone } from "react-dropzone";

import cs from "classnames";
import "./index.scss";
import { toast } from "react-toastify";

interface DropzoneSettings {
  accept?: string;
  maxFiles?: number;
  maxSize?: number;
}

interface DropzoneProps {
  className?: string;
  child: string | ReactNode;
  onDropFile: (files: File[]) => void;
  settings?: DropzoneSettings;
}

const FileDropzone: FC<DropzoneProps> = (props) => {
  const { className, child, onDropFile, settings } = props;

  const onDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      onDropFile(acceptedFiles);
    },
    [onDropFile]
  );

  const onDropRejected = useCallback(
    (fileRejections) => {
      if (fileRejections.length > 0 && fileRejections[0].errors.length > 0) {
        if (fileRejections[0].errors[0].code === "too-many-files") {
          toast.warn(
            `${fileRejections[0].errors[0].message}. Max files count - ${settings?.maxFiles}`
          );
        } else if (
          fileRejections[0].errors[0].code === "file-too-large" &&
          settings?.maxSize
        ) {
          toast.warn(`Max file size ${settings?.maxSize / 1024 / 1024}Mb`);
        } else {
          toast.warn(fileRejections[0].errors[0].message);
        }
      }
    },
    [settings?.maxSize, settings?.maxFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    onDropRejected,
    multiple: settings?.maxFiles === 1 ? false : true,
    ...settings,
  });

  return (
    <div {...getRootProps({ className: cs("dropzone", className) })}>
      <input {...getInputProps()} />
      {isDragActive ? <p>Drop the file here ...</p> : child}
    </div>
  );
};

export default FileDropzone;
