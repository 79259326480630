import { INftCollection } from "src/graphQl/nftCollections";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { toast } from "react-toastify";
import networksConfig from "./networksConfig";
import web3 from "web3";
import { TChainId } from "../types/common";
import { ARBITRUM_CHAIN_ID, ETH_CHAIN_ID, POLYGON_CHAIN_ID } from "../config";
import { ethers } from "ethers";
import { IUnconfirmedTx } from "../types/wallet";
import { deleteUnconfirmedTx } from "../store/wallet";
import { getValueFromHex } from "./ethers";
import { DEFAULT_DATE_FORMAT } from "src/config/constants";
import { GameCancelReason } from "src/graphQl/auctionGames";
import { INftInCustody } from "src/graphQl/nftInCustody";
import i18n from "src/i18n";
dayjs.extend(relativeTime);

export const toUserLocaleFormat = (
  value: string | number,
  maximumFractionDigits?: BigIntToLocaleStringOptions["maximumFractionDigits"]
) => {
  if (value === undefined || value === null || !isFinite(+value)) return "0";
  const [int, decimals = "00"] = value.toString().split(".");
  const intValue = BigInt(int).toLocaleString(undefined, {
    maximumFractionDigits,
  });
  const decimalsValue = (+`0.${decimals}`)
    .toLocaleString(undefined, {
      minimumFractionDigits: maximumFractionDigits !== 0 ? 2 : 0,
      maximumFractionDigits,
    })
    .slice(1);
  return `${intValue}${decimalsValue}`;
};

export const getFormattedDate = (date: string) => {
  const today = dayjs();
  const dayjsDate = dayjs(date);
  const daysPassed = today.diff(dayjsDate, "days");
  if (daysPassed < 6) {
    return dayjsDate.fromNow();
  } else {
    return dayjsDate.format(DEFAULT_DATE_FORMAT);
  }
};

export const isMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

export const isMobileNotMetamask = () =>
  isMobile() && !window?.ethereum?.isMetaMask;

export const delay = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));

export function getDecimalsCount(v: number) {
  const s = v.toString(),
    i = s.indexOf(".") + 1;
  return i && s.length - i;
}

export function round(value: number, step = 1) {
  const factor = 10 ** getDecimalsCount(step);
  var inv = (1 * factor) / (step * factor); // === 1 / step
  return Math.round(value * inv) / inv;
}

export const randomInteger = (min: number, max: number) => {
  let rand = min - 0.5 + Math.random() * (max - min + 1);
  return Math.round(rand);
};

export const getDuplicates = (arr: any[]) => {
  const duplicates = arr.filter((e, index, arr) => arr.indexOf(e) !== index);
  return duplicates;
};

export const getMockArray = (count: number) => {
  return Array.from(Array(count).fill(undefined)).map((i, index) => ({
    piece: index + 1,
    id: randomInteger(1, 10000).toString(),
  }));
};

export const getOnlyNumberValue = (value: string): string => {
  const reg = new RegExp(/(\d+([.]\d*)?|[.]\d+)?/);
  if (reg.test(value)) {
    return value?.match(reg)?.[0] || "";
  }
  return "";
};

export const isCommaSeparatedNumbers = (value: string) =>
  /^\d+(,\d+)*$/.test(value);

export const isValidUrl = (value: string) => {
  const re =
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
  return re.test(value);
};

export const addressesAreEquals = (
  addr1?: string | null,
  addr2?: string | null
) => {
  if (!addr1 || !addr2) {
    return false;
  }
  return addr1.toLowerCase() === addr2.toLowerCase();
};

export const parseOpenSeaLink = (link: string) => {
  if (
    /^https:\/\/(testnets\.)?opensea\.io\/assets\/(ethereum\/|matic\/|mumbai\/|rinkeby\/|arbitrum\/)?0x[a-z0-9]{40}\/\d+/g.test(
      link
    )
  ) {
    let isPolygon = false;
    if (link.includes("matic") || link.includes("mumbai")) {
      isPolygon = true;
    }
    let isArbitrum = false;
    if (link.includes("arbitrum")) {
      isArbitrum = true;
    }

    let chainId = "";
    if (isPolygon) {
      chainId = POLYGON_CHAIN_ID || "";
    }
    else if (isArbitrum) {
      chainId = ARBITRUM_CHAIN_ID || "";
    }
    else {
      chainId = ETH_CHAIN_ID || "";
    }

    const addressIndex = link.indexOf("0x");
    const [nftAddress, tokenId] = link.slice(addressIndex).split("/");

    return {
      chainId: chainId,
      nftAddress,
      tokenId,
    };
  }
  return null;
};

export const getLinksFromNftItem = (nft: INftInCustody) => {
  const { chainId, collection, tokenId } = nft;
  const isTestnet = [4, 80001].includes(chainId);
  const isPolygon = [137, 80001].includes(chainId);
  const isArbitrum = [42161, 421614].includes(chainId);
  const configData = networksConfig[chainId.toString() as TChainId];
  return {
    openSeaLink: `https://${isTestnet ? "testnets." : ""}opensea.io/assets/${configData.openSeaNetworkPart
      }${collection?.collectionAddress}/${getValueFromHex(tokenId!)}`,
    blockExplorerLink: `${configData.blockExplorer}/address/${collection?.collectionAddress}`,
    isPolygon,
    isArbitrum,
    blockExplorerTokenLink: `${configData.blockExplorer}/token/${collection?.collectionAddress
      }?a=${getValueFromHex(tokenId!)}`,
  };
};

export const getOpenSeaLinkFromCollection = (collection: INftCollection) => {
  const { chainId, collectionAddress } = collection;
  const isTestnet = [4, 80001].includes(chainId);
  const isPolygon = [137, 80001].includes(chainId);
  const isArbitrum = [42161, 421614].includes(chainId);
  const configData = networksConfig[chainId.toString() as TChainId];
  return {
    openSeaLink: `https://${isTestnet ? "testnets." : ""}opensea.io/assets/${configData.openSeaNetworkPart
      }${collectionAddress}/1`,
    blockExplorerLink: `${configData.blockExplorer}/address/${collectionAddress}`,
    isPolygon, isArbitrum,
  };
};

export const getChainId = () => window?.ethereum?.networkVersion || null;

export const switchNetwork = async (chainId: string) => {
  try {
    const provider = window.ethereum;
    if (!provider) throw Error(i18n.t("notifications.noMetamask"));
    if (provider?.networkVersion === +chainId) return;
    const hexChainId = web3.utils.numberToHex(chainId);
    try {
      await provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: hexChainId }],
      });
    } catch (switchError: any) {
      console.log("switchError", switchError);
      if (
        switchError.code === 4902 ||
        switchError.message.includes("Unrecognized chain ID")
      ) {
        try {
          const config = networksConfig[chainId as TChainId];
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: hexChainId,
                chainName: config.name,
                rpcUrls: [config.rpcUrl],
                blockExplorerUrls: [config.blockExplorer],
                nativeCurrency: config.nativeCurrency,
              },
            ],
          });
        } catch (addError: any) {
          throw Error(addError.message);
          // handle "add" error
          // throw Error;
        }
      }
      if (switchError.code === 4001) {
        // handle other "switch" errors
        throw Error(switchError.message);
      }
    }
  } catch (err: any) {
    console.log("switch network error", err);
    throw Error(err.message);
  }
};

export const isValidAddress = (address?: string) =>
  !address ? false : web3.utils.isAddress(address);

export const addListenerForWaitTxConfirmation = (
  hash: IUnconfirmedTx["hash"],
  callback?: () => void
) => {
  const provider = new ethers.providers.Web3Provider(window?.ethereum, "any");

  provider.once(hash, transaction => {
    deleteUnconfirmedTx(hash);
    toast.success(i18n.t("notifications.txConfirmed"));
    if (callback instanceof Function) {
      callback();
    }
  });
};

export const getIntAndFactor = (v: number | string) => {
  const value = +v;
  if (!(value % 1)) {
    return [value, 1];
  }
  const decimalsCount = getDecimalsCount(value);
  return [Math.round(value * 10 ** decimalsCount), 10 ** decimalsCount];
};

export const effectErrorHandler = (err: any, checkReason?: boolean): string => {
  console.dir(err);
  if (err.code === "UNSUPPORTED_OPERATION") {
    toast.info(i18n.t("notifications.openMetamask"));
    return "Please open Metamask";
  }
  if (checkReason && err?.reason) {
    toast.info(err?.reason);
    return err?.reason;
  }
  const msg =
    err?.response?.data?.Errors?.[0]?.Msg ||
    err?.data?.message ||
    err?.message ||
    i18n.t("notifications.unknownError");
  toast.info(msg);
  return msg;
};

export const isMyAddress = (address: string) =>
  window?.ethereum?.selectedAddress?.toLowerCase() === address;

export const objFromArr = (key: string, arr?: any, field?: string): any =>
  arr?.reduce(
    (acc: any, cur: any) => ({ ...acc, [cur[key]]: field ? cur[field] : cur }),
    {}
  );

export const getGameCancelReason = (cancelReason: GameCancelReason) => {
  switch (cancelReason) {
    case GameCancelReason.Admin:
      return i18n.t("gameCancelReasons.byAdmin");
    case GameCancelReason.CreatorCancel:
      return i18n.t("gameCancelReasons.byCreator");
    case GameCancelReason.ByTime:
      return i18n.t("gameCancelReasons.timeIsOver");
    case GameCancelReason.SellAll:
      return i18n.t("gameCancelReasons.sellAll");
    case GameCancelReason.NotReady:
      return i18n.t("gameCancelReasons.notEnoughUsers");
    default:
      return null;
  }
};

export const groupBy = (xs: any, key: any) =>
  xs.reduce((rv: any, x: any) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});

export const getShortAddress = (address?: string | null) =>
  address ? `${address.slice(0, 5)}...${address.slice(-5)}` : "";
