import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./index.scss";
import Button from "src/components/common/Button";
import Layout from "src/components/common/Layout";
import { quickGuidePageLinks } from "src/config/links";

const QuickGuide = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { hash } = useLocation();

  useEffect(() => {
    if (!hash) {
      window.scrollTo(0, 0);
    } else {
      const el = document.getElementById(hash.slice(1));
      el && el.scrollIntoView({ behavior: "smooth" });
    }
  }, [hash]);

  const steps = [
    {
      label: t("pages.quickGuide.step1"),
    },
    {
      label: t("pages.quickGuide.step2"),
    },
    {
      label: t("pages.quickGuide.step3"),
    },
    {
      label: t("pages.quickGuide.step4"),
    },
    {
      label: t("pages.quickGuide.step5"),
    },
    {
      label: t("pages.quickGuide.step6"),
    },
    {
      label: t("pages.quickGuide.step7"),
    },
  ];

  return (
    <Layout className="gradient-layout">
      <div className="quick-guide">
        <div className="quick-guide__title">{t("pages.quickGuide.title")}</div>
        <div className="quick-guide__steps">
          <ol>
            {steps.map(({ label }) => (
              <li key={label}>{label}</li>
            ))}
          </ol>
        </div>
        <div className="quick-guide__video">
          <iframe
            src={quickGuidePageLinks.mainVideoLink}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            title="video"
            allowFullScreen
          />
        </div>
        <Button
          className="quick-guide__btn"
          onClick={() => history.push("/how-to-play")}
        >
          {t("buttons.gamePlayDetails")}
        </Button>
      </div>
    </Layout>
  );
};

export default QuickGuide;
