import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { SelectOption } from "src/types/common";
import { IUser } from "./users";

export interface INftCollection {
  blockchainRoyaltyAddress: string;
  blockchainRoyaltyPercent: string;
  chainId: number;
  collectionAddress: string;
  contractDescription: string;
  contractExternalLink: string;
  contractImage: string;
  contractName: string;
  fail: boolean;
  id: string;
  mediaLink: string;
  owner: string;
  ownerLoginTime: string;
  royaltyAddress: string;
  royaltyPercent: string;
  totalRoyaltyCollect: number;
  user: IUser;
  whiteListed: boolean;
  discordCommunity: string;
  twitterCommunity: string;

  countGames: number;
  countImportedNFT: number;
  countUniqNFTsInGames: number;
  categoryId: string;
  category: {
    id: string;
    name: string;
  };
  countLiveGames: number;
}

export const NFT_COLLECTIONS = gql`
  query NftCollection(
    $where: NFTCollectionFilterInput
    $first: Int
    $last: Int
    $before: String
    $after: String
    $searchString: String
  ) {
    nFTCollection(
      where: $where
      first: $first
      last: $last
      before: $before
      after: $after
      searchString: $searchString
    ) {
      nodes {
        id
        chainId
        collectionAddress
        contractImage
        contractName
        royaltyPercent
        blockchainRoyaltyPercent
        whiteListed
        category {
          id
          name
        }
      }
      totalCount
      pageInfo {
        startCursor
        hasPreviousPage
        endCursor
        hasNextPage
      }
    }
  }
`;

export const NFT_COLLECTIONS_FOR_FILTER = gql`
  query NftCollection {
    upcomingOnlyNFTCollection(first: 1000) {
      nodes {
        id
        contractName
        category {
          id
          name
        }
        countLiveGames
      }
    }
  }
`;

export const NFT_COLLECTIONS_ADMIN = gql`
  query NftCollection(
    $where: NFTCollectionFilterInput
    $order: [NFTCollectionSortInput!]
    $first: Int
    $last: Int
    $before: String
    $after: String
    $searchString: String
  ) {
    nFTCollection(
      where: $where
      order: $order
      first: $first
      last: $last
      before: $before
      after: $after
      searchString: $searchString
    ) {
      nodes {
        id
        contractName
        contractImage
        collectionAddress
        chainId
        blockchainRoyaltyPercent
        totalRoyaltyCollect
        owner
        ownerLoginTime
        discordCommunity
        twitterCommunity
        countGames
        countImportedNFT
        countUniqNFTsInGames
        category {
          id
          name
        }
        royaltyPercent
      }
      totalCount
      pageInfo {
        startCursor
        hasPreviousPage
        endCursor
        hasNextPage
      }
    }
  }
`;

export const NFT_COLLECTIONS_TOTAL = gql`
  query NftCollection($where: NFTCollectionFilterInput, $first: Int) {
    nFTCollection(where: $where, first: $first) {
      totalCount
    }
  }
`;

// export const NFT_COLLECTIONS_IDS = gql`
//   query NftCollection($where: NFTCollectionFilterInput, $first: Int) {
//     nFTCollection(where: $where, first: $first) {
//       nodes {
//         id
//       }
//     }
//   }
// `;

export const NFT_COLLECTIONS_ADDRESS_CHAIN = gql`
  query NftCollection($where: NFTCollectionFilterInput, $first: Int) {
    nFTCollection(where: $where, first: $first) {
      nodes {
        collectionAddress
        chainId
      }
    }
  }
`;

export const useTotalCollectionsCount = (owner?: string | null) => {
  const { data, error, loading } = useQuery<{
    nFTCollection: {
      totalCount: number;
    };
  }>(NFT_COLLECTIONS_TOTAL, {
    variables: {
      where: {
        owner: { eq: owner },
      },
    },
  });

  return {
    totalCollectionsCount: data?.nFTCollection?.totalCount || 0,
    error,
    loading,
  };
};

export const useWhiteListedCollections = () => {
  const { data, error, loading } = useQuery<{
    nFTCollection: {
      nodes: INftCollection[];
    };
  }>(NFT_COLLECTIONS, {
    variables: {
      first: 1000,
      where: {
        whiteListed: { eq: true },
      },
    },
  });

  return {
    whitelistedCollections: data?.nFTCollection?.nodes || [],
    error,
    loading,
  };
};

export const useWhiteListedCollectionsAddressChain = () => {
  const { data, error, loading } = useQuery<{
    nFTCollection: {
      nodes: INftCollection[];
    };
  }>(NFT_COLLECTIONS_ADDRESS_CHAIN, {
    variables: {
      first: 1000,
      where: {
        whiteListed: { eq: true },
      },
    },
  });

  return {
    whitelistedCollections: data?.nFTCollection?.nodes || [],
    error,
    loading,
  };
};

export const useCollectionsForFilter = (params: { skip?: boolean } | void) => {
  const [getData, { data, error, loading }] = useLazyQuery<{
    upcomingOnlyNFTCollection: {
      nodes: INftCollection[];
    };
  }>(NFT_COLLECTIONS_FOR_FILTER);

  useEffect(() => {
    !params?.skip && getData();
  }, [params?.skip, getData]);

  return {
    collectionsForSelect: data?.upcomingOnlyNFTCollection?.nodes
      ? [
          {
            label: `All (${data?.upcomingOnlyNFTCollection?.nodes?.reduce(
              (acc: number, cur: INftCollection) => {
                acc += cur.countLiveGames;
                return acc;
              },
              0
            )})`,
            value: "",
          },
          ...data?.upcomingOnlyNFTCollection?.nodes
            ?.map((i: INftCollection) => ({
              label: `${i.contractName} (${i.countLiveGames})`,
              value: i.id,
            }))
            .sort((a: SelectOption, b: SelectOption) =>
              a.label.localeCompare(b.label)
            ),
        ]
      : [],
    collections: data?.upcomingOnlyNFTCollection?.nodes || [],
    error,
    loadingCollections: loading,
  };
};
