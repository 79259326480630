import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";

import Loader from "src/components/common/Loader";
import PdfReader from "src/pages/PdfReader";
import Privacy from "src/assets/documents/PuzzledPrivacyPolicy.pdf";
import Terms from "src/assets/documents/PuzzledTermsConditions.pdf";
import Transactions from "src/pages/Transactions";
import { $isAuthChecked, $loggedIn } from "src/store/profile";
import PrivateRoute from "src/routes/PrivateRoute";
import GamesTxs from "src/components/transactions/GamesTxs";
import BlockchainTxs from "src/components/transactions/BlockchainTxs";
import Layout from "src/components/common/Layout";
import DiscordConnect from "src/pages/Discord";
import { $account } from "src/store/wallet";

// import PageNotFound from "src/components/common/PageNotFound";
// import Home from "src/pages/Home";
// import Game from "src/pages/Game";
// import CreateGame from "src/pages/CreateGame";
// import EndGame from "src/pages/EndGame";
// import Nfts from "src/pages/Nfts";
// import MyGameHistory from "src/pages/MyGameHistory";
// import HowToPlay from "src/pages/HowToPlay";
// import AboutUs from "src/pages/AboutUs";
// import Faq from "src/pages/FAQ";
// import ContactUs from "src/pages/ContactUs";
// import HowToCreate from "src/pages/HowToCreate";
// import Collections from "src/pages/Collections";
import AdminRoutes from "src/admin";
import QuickGuide from "src/pages/QuickGuide";
import ForCollections from "src/pages/ForCollections";

const Home = lazy(() => import("src/pages/Home"));
const Game = lazy(() => import("src/pages/Game"));
const CreateGame = lazy(() => import("src/pages/CreateGame"));
const EndGame = lazy(() => import("src/pages/EndGame"));
const Nfts = lazy(() => import("src/pages/Nfts"));
const MyGameHistory = lazy(() => import("src/pages/MyGameHistory"));
const HowToPlay = lazy(() => import("src/pages/HowToPlay"));
const AboutUs = lazy(() => import("src/pages/AboutUs"));
const Faq = lazy(() => import("src/pages/FAQ"));
const ContactUs = lazy(() => import("src/pages/ContactUs"));
const HowToCreate = lazy(() => import("src/pages/HowToCreate"));
const HowToValidateNft = lazy(() => import("src/pages/HowToValidateNFT"));
const Collections = lazy(() => import("src/pages/Collections"));
const AccountPage = lazy(() => import("src/pages/AccountPage"));
const PageNotFound = lazy(() => import("src/components/common/PageNotFound"));
const GamesHistory = lazy(() => import("src/pages/GamesHistory"));

// const AdminRoutes = lazy(() => import("src/admin"));
const ApprovedCollections = lazy(() => import("src/admin/collections"));

const Routes = () => {
  const { t } = useTranslation();

  const isAuthChecked = useStore($isAuthChecked);
  const loggedIn = useStore($loggedIn);
  const account = useStore($account);

  return (
    <Suspense fallback={<Loader className="page-loader page-loader_with-bg" />}>
      <div id="page-wrap">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/game/:gameId" component={Game} />
          <Route
            exact
            path="/select-nft"
            render={() => <Nfts title={t("pages.nfts.selectNftTitle")} />}
          />
          <Route exact path="/create-game/:nftId" component={CreateGame} />
          <Route exact path="/bulk-game-creation" component={CreateGame} />
          <Route exact path="/end-game/:gameId" component={EndGame} />

          <Route
            path="/approved-collections"
            render={() => (
              <Layout className="gradient-layout">
                <ApprovedCollections />
              </Layout>
            )}
          />

          <Route path="/quick-guide" component={QuickGuide} />
          <Route path="/how-to-play" component={HowToPlay} />
          <Route path="/how-to-create" component={HowToCreate} />
          <Route path="/games-history" component={GamesHistory} />
          <Route
            exact
            path="/how-to-validate-nft-authenticity"
            component={HowToValidateNft}
          />
          <Route path="/faq" component={Faq} />
          <Route path="/for-collections" component={ForCollections} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/contact-us" component={ContactUs} />
          <Route path="/privacy" render={() => <PdfReader file={Privacy} />} />
          <Route path="/terms" render={() => <PdfReader file={Terms} />} />

          <PrivateRoute
            isAllowed={isAuthChecked && loggedIn && !!account}
            loading={!isAuthChecked}
          >
            <Route
              exact
              path="/my-nfts"
              render={() => (
                <Nfts title={t("pages.nfts.myNftsTitle")} isMyNfts />
              )}
            />
            <Route exact path="/my-collections" component={Collections} />
            <Route path="/discord" component={DiscordConnect} />
            <Route path="/admin" component={AdminRoutes} />
            <Route path="/account" component={AccountPage} />
            <Route path="/my-games" component={MyGameHistory} />
            <Route
              exact
              path="/transactions"
              render={() => (
                <Transactions>
                  <BlockchainTxs />
                </Transactions>
              )}
            />
            <Route
              path="/games-transactions"
              render={() => (
                <Transactions>
                  <GamesTxs />
                </Transactions>
              )}
            />
          </PrivateRoute>

          <Route component={PageNotFound} />
        </Switch>
      </div>
    </Suspense>
  );
};

export default Routes;
