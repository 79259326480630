import { MapId } from "./penguinMaps";
import { gql } from "@apollo/client";
import { IAuctionPlayerInfo } from "src/graphQl/auctionPlayerInfos";
import { GameStatus } from "src/types/games";
import { INftInCustody } from "./nftInCustody";
import { IUser } from "./users";
import { IAccountOperation } from "./accountOperations";

// export enum AuctionGameStatus {
//   CREATED = "CREATED",
//   CANCELED = "CANCELED",
//   STARTED = "STARTED",
//   COMPLETE = "COMPLETE",
// }

export enum GameCancelReason {
    Admin = "ADMIN",
    SellAll = "SELL_ALL",
    CreatorCancel = "CREATOR_CANCEL",
    ByTime = "BY_TIME",
    NotReady = "NOT_READY",
}

export interface PenguinMap {
    trackId: string;
}

export interface IAuctionGame {
    accountOperations: IAccountOperation[];
    artist: string;
    penguinPlayerInfos: IAuctionPlayerInfo[];
    baseBidPrice: number;
    canceled: boolean;
    cancelProcessed: boolean;
    cancelReason: GameCancelReason;
    createdTime: string;
    creator: string;
    description: string;
    entryCount: number[];
    entryPrices: number[];
    featured: boolean;
    id: string;
    maxEndGameTime: string;
    maxGameDurationSeconds: number;
    maxPlayersCount: number;
    minBidStep: number;
    minPlayersCount: number;
    nFTInCustody: INftInCustody;
    nFTInCustodyId: string;
    numberOfPieces: number;
    platformFee: number;
    playersCount: number;
    privateGame: boolean;
    readyToStart: boolean;
    royaltyAddress: string;
    royaltyPercent: string;
    secondsForWinBid: number;
    selectPlayers: string[];
    startTime: string;
    status: GameStatus;
    statusChangeTime: string;
    systemPrices: number[];
    winner: string;
    winnerUser: IUser;
    creatorUser: IUser;
    penguinMap: PenguinMap;

    discordCategoryId: number;
    discordProcessed: boolean;
    discordRoleId: number;
    discordTextChannelId: number;
    discordVoiceChannelId: number;
    discordInviteUrl: string;

    forfeitPlayersRevenue: number;
    zeroEntryPrice: number;
    oneEntryPrice: number;
    twoEntryPrice: number;
    threeEntryPrice: number;
    totalGameCreatorRevenue: number;
    totalGameRevenue: number;
    totalRoyaltyRevenue: number;
    totalSystemRevenue: number;
    totalPayed: number;
}

export const GAMES = gql`
    query Games(
        $where: PenguinGameFilterInput
        $first: Int
        $order: [PenguinGameSortInput!]
        $after: String
        $address: String
        $searchString: String
    ) {
        auctionGames(
            where: $where
            first: $first
            order: $order
            after: $after
            address: $address
            searchString: $searchString
        ) {
            nodes {
                creator
                description
                id
                nFTInCustody {
                    name
                    image280
                    chainId
                    collection {
                        contractName
                    }
                }

                startTime
                status
                maxGameDurationSeconds
                cancelReason
                winnerUser {
                    username
                    address
                }
            }
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }
`;

export const GAMES_FULL_INFO = gql`
    query Games(
        $where: PenguinGameFilterInput
        $first: Int
        $order: [PenguinGameSortInput!]
        $after: String
        $searchString: String
    ) {
        auctionGames(
            where: $where
            first: $first
            order: $order
            after: $after
            searchString: $searchString
        ) {
            nodes {
                artist
                penguinPlayerInfos {
                    address
                    user {
                        address
                        avatarUrl
                        username
                    }
                }
                creator
                description
                entryPrices
                featured
                id
                maxPlayersCount
                minPlayersCount
                nFTInCustody {
                    id
                    from
                    tokenId
                    value
                    custodianContractAddress
                    chainId
                    status
                    owner
                    title
                    name
                    type
                    description
                    image280
                    image715
                    currentHexRoyalty
                    collection {
                        contractImage
                        contractName
                        collectionAddress
                        discordCommunity
                        twitterCommunity
                        owner
                    }
                }
                penguinMap {
                    trackId
                }
                startTime
                status
                winner
                maxGameDurationSeconds
                playersCount
                statusChangeTime
                entryCount
                cancelReason
                royaltyPercent
            }
        }
    }
`;
// export const PENGUIN_PLAYERS_INFOS = gql`
//     query penguinPlayerInfos {
//         penguinPlayerInfos(
//             where: $where
//             first: $first
//             order: $order
//             after: $after
//             searchString: $searchString
//         ) {
//             id
//             penguinTime
//             address
//             gameId
//             completeRaceCount
//         }
//     }
// `;

export const ADMIN_GAMES_FULL_INFO = gql`
    query Games(
        $where: PenguinGameFilterInput
        $first: Int
        $order: [PenguinGameSortInput!]
        $after: String
    ) {
        auctionGames(
            where: $where
            first: $first
            order: $order
            after: $after
        ) {
            nodes {
                artist
                penguinPlayerInfos {
                    address
                    user {
                        address
                        avatarUrl
                        username
                    }
                }
                creator
                description
                entryPrices
                featured
                id
                maxPlayersCount
                minPlayersCount
                nFTInCustody {
                    id
                    from
                    tokenId
                    value
                    custodianContractAddress
                    chainId
                    status
                    owner
                    title
                    name
                    type
                    description
                    image280
                    currentHexRoyalty
                    collection {
                        contractImage
                        contractName
                        collectionAddress
                        discordCommunity
                        twitterCommunity
                        chainId
                        royaltyAddress
                        royaltyPercent
                    }
                }
                startTime
                status
                winner
                maxGameDurationSeconds
                playersCount
                statusChangeTime
                entryCount
                cancelReason
                royaltyPercent
            }
        }
    }
`;

export const ADMIN_GAMES = gql`
    query Games(
        $where: PenguinGameFilterInput
        $first: Int
        $last: Int
        $order: [PenguinGameSortInput!]
        $before: String
        $after: String
    ) {
        auctionGames(
            where: $where
            first: $first
            last: $last
            order: $order
            before: $before
            after: $after
        ) {
            nodes {
                artist
                baseBidPrice
                creator
                description
                entryPrices
                featured
                id
                maxPlayersCount
                minBidStep
                minPlayersCount
                nFTInCustody {
                    id
                    owner
                    name
                    description
                    image280
                    collection {
                        contractName
                        collectionAddress
                    }
                    tokenId
                }
                numberOfPieces
                secondsForWinBid
                startTime
                status
                winner
                maxGameDurationSeconds
                playersCount
                entryCount
                createdTime
                platformFee
                statusChangeTime
                cancelReason
                royaltyPercent
                royaltyAddress
            }
            totalCount
            pageInfo {
                startCursor
                hasPreviousPage
                endCursor
                hasNextPage
            }
        }
    }
`;

export const GAMES_STATUS_PLAYERS = gql`
    query Games(
        $where: PenguinGameFilterInput
        $first: Int
        $order: [PenguinGameSortInput!]
    ) {
        auctionGames(where: $where, first: $first, order: $order) {
            nodes {
                status
                playersCount
            }
        }
    }
`;

export const ENDED_GAMES_DATA = gql`
    query Games(
        $where: PenguinGameFilterInput
        $first: Int
        $order: [PenguinGameSortInput!]
    ) {
        auctionGames(where: $where, first: $first, order: $order) {
            nodes {
                winner
                winnerUser {
                    username
                }
                nFTInCustody {
                    image280
                }
                status
                cancelReason
                totalPayed
                penguinPlayerInfos {
                    address
                }
            }
        }
    }
`;

export const GAMES_TRANSACTIONS_DATA = gql`
    query Games(
        $where: PenguinGameFilterInput
        $first: Int
        $last: Int
        $order: [PenguinGameSortInput!]
        $address: String
        $after: String
        $before: String
    ) {
        auctionGames(
            where: $where
            first: $first
            last: $last
            order: $order
            address: $address
            after: $after
            before: $before
        ) {
            totalCount
            pageInfo {
                startCursor
                hasPreviousPage
                endCursor
                hasNextPage
            }
            nodes {
                id
                entryPrices
                accountOperations {
                    relatedPiece
                    totalAmount
                    type
                    userAddress
                    userAddress2
                    transactions {
                        accountTransactionState
                        amount
                        fromAccount {
                            address
                        }
                        id
                        toAccount {
                            address
                        }
                        type
                    }
                    time
                }
                featured
                nFTInCustody {
                    name
                    collection {
                        contractName
                        owner
                    }
                }
                startTime
                royaltyPercent
                creator
                royaltyAddress
            }
        }
    }
`;

export const GAMES_DISCORD_INVITE_URL = gql`
    query Games(
        $where: PenguinGameFilterInput
        $first: Int
        $order: [PenguinGameSortInput!]
    ) {
        auctionGames(where: $where, first: $first, order: $order) {
            nodes {
                discordInviteUrl
            }
        }
    }
`;

// export const useDiscordInviteUrl = (gameId: string | null) => {
//   const { data, error, loading } = useQuery<{
//     auctionGames: {
//       nodes: IAuctionGame[];
//     };
//   }>(GAMES_DISCORD_INVITE_URL, {
//     variables: {
//       where: {
//         id: { eq: gameId },
//       },
//     },
//   });

//   return {
//     discordInviteUrl: gameId
//       ? data?.auctionGames?.nodes?.[0]?.discordInviteUrl
//       : null,
//     error,
//     loading,
//   };
// };

export const GAMES_HISTORY = gql`
    query Games(
        $where: PenguinGameFilterInput
        $first: Int
        $last: Int
        $order: [PenguinGameSortInput!]
        $before: String
        $after: String
    ) {
        auctionGames(
            where: $where
            first: $first
            last: $last
            order: $order
            before: $before
            after: $after
        ) {
            nodes {
                creator
                creatorUser {
                    address
                    username
                }
                nFTInCustody {
                    owner
                    name
                    image280
                    collection {
                        contractName
                        chainId
                        category {
                            name
                        }
                    }
                }
                startTime
                winnerUser {
                    address
                    username
                }
                totalPayed
            }
            totalCount
            pageInfo {
                startCursor
                hasPreviousPage
                endCursor
                hasNextPage
            }
        }
    }
`;
