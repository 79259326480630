import { gql, useQuery } from "@apollo/client";
import { round } from "src/utils/helpers";
import { IUser } from "./users";

export interface IAccount {
  address: string;
  balance: number;
  clone: IAccount;
  escrow: number;
  id: string;
  user: IUser;
}

export const ACCOUNTS = gql`
  query Account($where: AccountFilterInput, $first: Int) {
    accounts(where: $where, first: $first) {
      nodes {
        id
        address
        balance
        escrow
      }
    }
  }
`;

export const ACCOUNTS_BALANCES = gql`
  query Account($where: AccountFilterInput, $first: Int) {
    accounts(where: $where, first: $first) {
      nodes {
        address
        balance
      }
    }
  }
`;

export const useAccountBalance = (address?: string | null) => {
  const { data, error, loading } = useQuery<{
    accounts: {
      nodes: IAccount[];
    };
  }>(ACCOUNTS_BALANCES, {
    variables: {
      where: {
        address: { eq: address },
      },
    },
  });

  const balance = data?.accounts?.nodes[0]?.balance;

  return {
    balance: balance ? round(balance, 0.000001) : 0,
    error,
    loading,
  };
};
