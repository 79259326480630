import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Button from "../../common/Button";
import "./index.scss";
import { setModal } from "src/store/app";

const StartsGameJoinAgree = () => {
  const { t } = useTranslation();

  return (
    <div className="started-game-join-agree">
      <div className="started-game-join-agree__text">
        {t("modals.startGameJoinAgree.text")}{" "}
        <Link to="/terms" target="_blank" rel="noopener noreferrer">
          {t("modals.startGameJoinAgree.terms")}
        </Link>
      </div>
      <Button
        className="started-game-join-agree__btn"
        onClick={() => setModal(null)}
      >
        {t("modals.startGameJoinAgree.confirmBtn")}
      </Button>
    </div>
  );
};

export default StartsGameJoinAgree;
