import React, { FC, ReactElement } from "react";
import BigNumber from "bignumber.js";
import dayjs from "dayjs";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import "./index.scss";
import Button from "src/components/common/Button";
import { GameStatus } from "src/types/games";
import {
    getGameCancelReason,
    getLinksFromNftItem,
    getOpenSeaLinkFromCollection,
} from "src/utils/helpers";
import { getValueFromHex } from "src/utils/ethers";
import { DEFAULT_DATE_TIME_FORMAT } from "src/config/constants";
import {
    IAuctionPlayerInfo,
    PLAYERS_INFO_ADMIN,
} from "src/graphQl/auctionPlayerInfos";
import { ADMIN_GAMES_FULL_INFO } from "src/graphQl/auctionGames";
import { Link, useHistory, useParams } from "react-router-dom";
import Loader from "src/components/common/Loader";
import networksConfig from "src/utils/networksConfig";
import { TChainId } from "src/types/common";

interface ItemProps {
    label: string | ReactElement;
    value: string | ReactElement;
}

const Item: FC<ItemProps> = ({ label, value }) => (
    <div className="admin-game-analytics__item">
        <div className="admin-game-analytics__item-label">{label}</div>
        <div className="admin-game-analytics__item-value">{value}</div>
    </div>
);

const GameAnalytics = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { gameId } = useParams<{ gameId: string }>();

    const { data: playersInfo } = useQuery(PLAYERS_INFO_ADMIN, {
        variables: {
            where: {
                gameId: { eq: gameId },
            },
        },
    });

    const playersData = playersInfo?.auctionPlayerInfos || [];

    const { data: games } = useQuery(ADMIN_GAMES_FULL_INFO, {
        variables: {
            where: {
                id: { eq: gameId },
            },
        },
    });

    const game = games?.auctionGames?.nodes[0];

    if (!game) {
        return <Loader />;
    }

    const {
        nFTInCustody,
        artist,
        startTime,
        description,
        creator,
        featured,
        entryPrices,
        minPlayersCount,
        maxPlayersCount,
        playersCount,
        status,
        maxGameDurationSeconds,
        winner,
        createdTime,
        statusChangeTime,
        cancelReason,
        royaltyPercent,

        // zeroEntryPrice,
        // oneEntryPrice,
        // twoEntryPrice,
        // threeEntryPrice,
    } = game;

    const gameEnded =
        status === GameStatus.CANCELED || status === GameStatus.COMPLETE;

    const royalty = royaltyPercent
        ? new BigNumber(getValueFromHex(royaltyPercent))
              .dividedBy(100)
              .toNumber()
        : 0;

    const configData =
        networksConfig[nFTInCustody?.chainId.toString() as TChainId];

    const playersByEntryPrices = playersData.reduce(
        (acc: any, cur: IAuctionPlayerInfo) => {
            const ind = entryPrices.indexOf(cur.entryPrice);
            if (ind === 2) {
                acc.gold += 1;
            }
            if (ind === 1) {
                acc.silver += 1;
            }
            if (ind === 0) {
                acc.bronze += 1;
            }
            return acc;
        },
        { bronze: 0, silver: 0, gold: 0 }
    );

    const fields = [
        {
            label: t("tables.gameAnalytics.status"),
            value: `${status} ${
                status === GameStatus.CANCELED && cancelReason
                    ? getGameCancelReason(cancelReason)
                    : ""
            }`,
        },
        {
            label: t("tables.gameAnalytics.nftCollectionName"),
            value: (
                <a
                    href={
                        getOpenSeaLinkFromCollection(nFTInCustody?.collection)
                            ?.blockExplorerLink
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {nFTInCustody?.collection?.contractName}
                </a>
            ),
        },
        {
            label: t("tables.gameAnalytics.nftName"),
            value: (
                <a
                    href={
                        getOpenSeaLinkFromCollection(nFTInCustody?.collection)
                            ?.openSeaLink
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {nFTInCustody?.name}
                </a>
            ),
        },
        {
            label: t("tables.gameAnalytics.nftAddress"),
            value: (
                <a
                    href={
                        getLinksFromNftItem(nFTInCustody)
                            ?.blockExplorerTokenLink
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {nFTInCustody?.collection?.collectionAddress}
                </a>
            ),
        },
        {
            label: t("tables.gameAnalytics.collectionRoyalty"),
            value: `${royalty}%`,
        },
        {
            label: t("tables.gameAnalytics.collectionRoyaltyAddress"),
            value: (
                <Link
                    to={`/admin/transactions?nftName=${nFTInCustody?.name}&userTo=${winner}`}
                >
                    {nFTInCustody?.collection?.royaltyAddress}
                </Link>
            ),
        },
        {
            label: t("tables.gameAnalytics.nftProvider"),
            value: (
                <Link
                    to={`/admin/transactions?nftName=${nFTInCustody?.name}&userTo=${creator}`}
                >
                    {creator}
                </Link>
            ),
        },
        {
            label: t("tables.gameAnalytics.gameWinner"),
            value: (
                <Link
                    to={`/admin/transactions?nftName=${nFTInCustody?.name}&userTo=${winner}`}
                >
                    {winner}
                </Link>
            ),
        },
        {
            label: t("tables.gameAnalytics.nftOwner"),
            value: (
                <a
                    href={`${configData.blockExplorer}/address/${nFTInCustody?.owner}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {nFTInCustody?.owner}
                </a>
            ),
        },
        {
            label: t("tables.gameAnalytics.nftArtist"),
            value: artist,
        },
        {
            label: t("tables.gameAnalytics.gameDescription"),
            value: description,
        },
        {
            label: t("tables.gameAnalytics.registeredPlayers"),
            value: (
                <div className="admin-game-analytics__flex-column">
                    {playersData.map((i: any) => {
                        const ind = entryPrices.indexOf(i.entryPrice);
                        return (
                            <Link
                                key={i.address}
                                to={`/admin/transactions?nftName=${nFTInCustody?.name}&userFrom=${i.address}`}
                            >
                                {i.address} -{" "}
                                {ind === 2
                                    ? "Gold"
                                    : ind === 1
                                    ? "Silver"
                                    : "Bronze"}
                            </Link>
                        );
                    })}
                </div>
            ),
        },

        {
            label: t("tables.gameAnalytics.gameCreated"),
            value: dayjs(createdTime).format(DEFAULT_DATE_TIME_FORMAT),
        },
        {
            label: t("tables.gameAnalytics.gameStartTime"),
            value: dayjs(startTime).format(DEFAULT_DATE_TIME_FORMAT),
        },
        {
            label: t("tables.gameAnalytics.gameEndTime"),
            value:
                gameEnded &&
                dayjs(statusChangeTime).format(DEFAULT_DATE_TIME_FORMAT),
        },
        {
            label: t("tables.gameAnalytics.maxGameDuration"),
            value: `${new BigNumber(maxGameDurationSeconds)
                .dividedBy(60)
                .toNumber()} min`,
        },
        {
            label: t("tables.gameAnalytics.minPlayers"),
            value: minPlayersCount,
        },
        {
            label: t("tables.gameAnalytics.maxPlayers"),
            value: maxPlayersCount || "Unlimited",
        },
        {
            label: t("tables.gameAnalytics.registeredPlayersCount"),
            value: playersCount,
        },
        {
            label: t("tables.gameAnalytics.activePlayersCount"),
            value: "0",
        },
        {
            label: t("tables.gameAnalytics.goldPlayers"),
            value: playersByEntryPrices.gold,
        },
        {
            label: t("tables.gameAnalytics.silverPlayers"),
            value: playersByEntryPrices.silver,
        },
        {
            label: t("tables.gameAnalytics.bronzePlayers"),
            value: playersByEntryPrices.bronze,
        },
        {
            label: t("tables.gameAnalytics.featuredGame"),
            value: featured ? "yes" : "no",
        },

        // {
        //   label: t("tables.gameAnalytics.goldEntry"),
        //   value: `${forfeitPlayersRevenue} Matic`,
        // },
        // {
        //   label: t("tables.gameAnalytics.silverEntry"),
        //   value: `${minBidStep} Matic`,
        // },
        // {
        //   label: t("tables.gameAnalytics.regularBids"),
        //   value: `${minBidStep} Matic`,
        // },
        // {
        //   label: t("tables.gameAnalytics.forfeitBids"),
        //   value: `${minBidStep} Matic`,
        // },

        // {
        //   label: t("tables.gameAnalytics.nftProviderGoldEntry"),
        //   value: `${minBidStep} Matic`,
        // },
        // {
        //   label: t("tables.gameAnalytics.nftProviderSilverEntry"),
        //   value: `${minBidStep} Matic`,
        // },
        // {
        //   label: t("tables.gameAnalytics.nftProviderRegularBids"),
        //   value: `${minBidStep} Matic`,
        // },
        // {
        //   label: t("tables.gameAnalytics.nftProviderForfeitBids"),
        //   value: `${minBidStep} Matic`,
        // },

        // {
        //   label: t("tables.gameAnalytics.collectionOwnerGoldEntry"),
        //   value: `${minBidStep} Matic`,
        // },
        // {
        //   label: t("tables.gameAnalytics.collectionOwnerSilverEntry"),
        //   value: `${minBidStep} Matic`,
        // },
        // {
        //   label: t("tables.gameAnalytics.collectionOwnerRegularBids"),
        //   value: `${minBidStep} Matic`,
        // },
        // {
        //   label: t("tables.gameAnalytics.collectionOwnerForfeitBids"),
        //   value: `${minBidStep} Matic`,
        // },
        // {
        //   label: t("tables.gameAnalytics.minExpectedRevenues"),
        //   value: `${minBidStep} Matic`,
        // },
        // {
        //   label: t("tables.gameAnalytics.totalMinusMinExpected"),
        //   value: `${minBidStep} Matic`,
        // },
        // {
        //   label: <b>{t("tables.gameAnalytics.totalVsMinExpected")}</b>,
        //   value: `${minBidStep} Matic`,
        // },
    ];

    return (
        <div className="admin-game-analytics">
            <div className="admin-game-analytics__items">
                <div className="admin-game-analytics__item">
                    <div className="admin-game-analytics__item-label">
                        <b>Item</b>
                    </div>
                    <div className="admin-game-analytics__item-value">
                        <b>Value</b>
                    </div>
                </div>
                {fields.map((props, ind) => (
                    <Item key={ind} {...props} />
                ))}
            </div>
            <Button
                onClick={() => history.goBack()}
                className="admin-game-analytics__btn"
            >
                Go Back
            </Button>
        </div>
    );
};

export default GameAnalytics;
