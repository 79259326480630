import { round } from "src/utils/helpers";

export const ENTRY_PRICES = [0, 0.01, 0.02];
export const GAME_CONFIG = {
  minPlayersCount: 1,
  minGameDurationSec: 600,
  maxGameDurationSec: 604800,
  minAutoRestartDelayMinutes: 10,
  bulkMinIntervalMinutes: 10,
};
export const CONTACT_EMAIL = "support@puzzled.space";
export const MAX_COLLECTION_ROYALTY = 50;
export const DEFAULT_DATE_FORMAT = "DD MMM YYYY";
export const DEFAULT_DATE_TIME_FORMAT = "DD MMM YYYY, HH:mm";
export const GAME_START_TIMESTAMP = Date.now() + 1000 * 60 * 60;
export const MAX_PLATFORM_FEE = 0.5;
export const MAX_ARTIST_NAME_LENGTH = 20;
export const MIN_WIN_BID_TIME_S = 5;
export const MIN_WIN_BID_TIME_M = round(MIN_WIN_BID_TIME_S / 60, 0.001);
export const MAX_USERNAME_LENGTH = 20;
// export const GAMES_ON_HOME_PAGE = 4;

export const CONTRACT_TYPES = [
  {
    title: "ERC 721",
    key: "721",
  },
  {
    title: "ERC 1155",
    key: "1155",
  },
];

export const MIN_NFTS_FOR_CREATE_MULTIPLE_GAMES = 2;
export const MIN_NFTS_FOR_MULTIPLE_TRANSFER = 1;
