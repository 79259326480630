import React, { FC, useMemo } from "react";
import cs from "classnames";
import { useGate, useStore } from "effector-react";
import { useTranslation } from "react-i18next";

import "./index.scss";
import {
  $fields,
  CollectionGate,
  setCategory,
  setFields,
} from "../../../store/collections";
import { useCollectionCategories } from "src/graphQl/nftCollectionCategories";
import SelectComponent from "src/components/common/Select";
import { SelectOption } from "src/types/common";

interface IProps {}

const SetCategory: FC<IProps> = () => {
  useGate(CollectionGate);
  const { t } = useTranslation();
  const { collection, categoryId } = useStore($fields);
  const updating = useStore(setCategory.pending);
  const { categories, loadingCategories } = useCollectionCategories();

  const defaultValueIndex = useMemo(
    () => categories.findIndex((i: SelectOption) => i.value === categoryId),
    [categories, categoryId]
  );

  const loading = updating || loadingCategories;

  return (
    <div className="set-category">
      <div className="set-category__title">
        {collection?.contractName || collection?.collectionAddress}
      </div>
      <div className="set-category__field">
        <div className="set-category__field-label">
          {t("modals.importCollection.collectionCategoryLabel")}
        </div>
        <SelectComponent
          options={categories}
          onChange={({ value }: any) => {
            setFields({ categoryId: value });
            setCategory(value);
          }}
          defaultValueIndex={defaultValueIndex}
          className={cs(
            "set-category__field-value set-category__select-category"
          )}
          isLoading={loading}
          isDisabled={loading}
          value={categories[defaultValueIndex]}
        />
      </div>
    </div>
  );
};

export default SetCategory;
