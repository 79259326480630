import { createEffect, createEvent, createStore, restore } from "effector";
import { createGate } from "effector-react";
import { GAME_START_TIMESTAMP } from "src/config/constants";

import { ICreateGameForm } from "../../types/games";

export const CreateGameGate = createGate();

// export const setNftList = createEvent<INftInCustody[]>();
// export const toggleNft = createEvent<INftInCustody>();
// export const $nftList = restore(setNftList, [])
//   .on(toggleNft, (_, nftItem) => {
//     const index = _.findIndex(i => i.id === nftItem.id);
//     const newArray = [..._];
//     if (index !== -1) {
//       newArray.splice(index, 1);
//     } else {
//       newArray.push(nftItem);
//     }
//     return newArray;
//   })
//   .reset([CreateGameGate.close, setNftsPageMode]);

export const $createGameForm = createStore<ICreateGameForm>({
    minPlayersCount: 2,
    maxPlayersCount: 20,
    numberOfPieces: 9,
    startTime: GAME_START_TIMESTAMP,
    mapId: "",
    description: "",
    artist: "",
    maxGameDuration: 120,
    featured: false,
    entryPrices: 50,
});

export const setGameStyle = createEvent<number>();
export const $gameStyle = restore(setGameStyle, 1);

export const setCreateGameStep = createEvent<number>();
export const $createGameStep = restore(setCreateGameStep, 1).reset(
    CreateGameGate.close
);

// export const submitCreateGame = createEvent();
export const setField = createEvent<{ key: string; value: any }>();

export const setFields = createEffect<
    { createGameForm: ICreateGameForm; isFreeGame: boolean },
    ICreateGameForm
>();
export const createGame = createEffect<any, void>();

export const setIsFreeGame = createEvent<boolean>();
export const $isFreeGame = restore(setIsFreeGame, true);
