export interface IPuzzleItem {
    id: string;
    piece: number;
}

export type TNumberOfPieces = 4 | 9 | 16;

export interface ICreateGameForm {
    numberOfPieces: TNumberOfPieces;
    minPlayersCount: number;
    maxPlayersCount: number;
    startTime: number;
    mapId: string;
    description: string;
    artist: string;
    featured: boolean;
    entryPrices: number;
    maxGameDuration: number;
}

export enum GameStatus {
    CREATED = "CREATED",
    CANCELED = "CANCELED",
    STARTED = "STARTED",
    COMPLETE = "COMPLETE",
}
