import { createEffect, createEvent, restore } from "effector";
import { createGate } from "effector-react";
import {
  ChangeCollectionImageParams,
  IUpdateMediaLinkParams,
  IWhitelistCollection,
} from "src/services/AdminService";

export const AdminPageGate = createGate();

export const setItem = createEvent<any>();
export const $item = restore(setItem, null);

export const setDateRange = createEvent<any>();
export const $dateRange = restore(setDateRange, [null, null]).reset([
  AdminPageGate.close,
]);

export const setFilters = createEvent<any>();
export const $filters = restore(setFilters, []);

export const setSortBy = createEvent<any>();
export const $sortBy = restore(setSortBy, []);

export const cancelGame = createEffect<string, void>();
export const addCollectionToWhitelist = createEffect<
  IWhitelistCollection,
  void
>();
export const deleteCollectionFromWhitelist = createEffect<string, void>();
export const setPlatformFee = createEffect<
  { gameId: string; fee: number },
  void
>();

export const updateMediaLink = createEffect<IUpdateMediaLinkParams, void>();

export const changeCollectionImage = createEffect<ChangeCollectionImageParams, void>();
