import { createEffect, createEvent, createStore, restore } from "effector";

import { TFileWithPreview } from "src/types/common";

export const $loggedIn = createStore<boolean>(false);
export const $registered = createStore<boolean>(false);

export const login = createEffect<void, void>();
export const logout = createEffect<void, void>();
export const checkAuth = createEffect<void, boolean>();

export const setAuthChecked = createEvent<boolean>();
export const $isAuthChecked = restore(setAuthChecked, false)
  .on(checkAuth.done, () => true)
  .on(checkAuth.fail, () => true);

export const register = createEffect<
  { username?: string; avatar?: TFileWithPreview; isCanadian: boolean },
  void
>();

export const deleteAvatar = createEffect<void, void>();
