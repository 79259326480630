import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";

import "./index.scss";
import { CAPTCHA_SITEKEY } from "src/config";
import { setJoinGameParams } from "src/store/games";

interface IProps {}

const CaptchaModal: FC<IProps> = () => {
  const { t } = useTranslation();

  return (
    <div className="captcha-modal">
      <div className="captcha-modal__title">{t("modals.captcha.title")}</div>
      <ReCAPTCHA
        sitekey={CAPTCHA_SITEKEY}
        onChange={captchaToken =>
          captchaToken && setJoinGameParams({ captchaToken })
        }
      />
    </div>
  );
};

export default CaptchaModal;
