import { toast } from "react-toastify";
import { TChainId } from "./../../types/common";
import NftInCustodyService from "src/services/NftInCustodyService";
import NftContractService from "src/services/NftContractService";
import {
  $addNftForm,
  $selectedChainId,
  $transferError,
  addNftFormSetField,
  bulkNftUpload,
  reloadNftsInCustody,
  setSelectedChainId,
  setTransferError,
  transferNft,
  transferNftsToOtherUser,
  withdrawNft,
} from "src/store/nft";
import { $account, addUnconfirmedTx } from "src/store/wallet";
import {
  addressesAreEquals,
  delay,
  effectErrorHandler,
  isCommaSeparatedNumbers,
  isValidAddress,
  parseOpenSeaLink,
  switchNetwork,
} from "src/utils/helpers";
import { GET_NFTS_IN_CUSTODY } from "src/graphQl/nftInCustody";
import PuzzledSpaceNFTCustodianService from "src/services/PuzzledSpaceNFTCustodianService";
import { apolloClient } from "src/graphQl";
import i18n from "src/i18n";

class WhitelistedError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "WhitelistedError";
  }
}

transferNft.use(async ({ bridgeContracts, whitelistedCollections }) => {
  try {
    setTransferError(false);
    const data = $addNftForm.getState();
    const account = $account.getState();
    if (!account) {
      throw Error(i18n.t("notifications.loginPlease"));
    }
    if (data.openSeaLink) {
      const nftData = parseOpenSeaLink(data.openSeaLink);
      if (!nftData) {
        throw Error(i18n.t("notifications.nfts.openSeaLinkErr"));
      }
      const { chainId, nftAddress, tokenId } = nftData;
      if (!isValidAddress(nftAddress)) {
        throw Error(i18n.t("notifications.nfts.nftAddressOpenSeaErr"));
      }
      const whitelisted = whitelistedCollections.find(
        i =>
          addressesAreEquals(i.collectionAddress, nftAddress) &&
          +chainId === i.chainId
      );
      setSelectedChainId(chainId);
      await switchNetwork(chainId);
      if (!whitelisted) {
        throw new WhitelistedError();
      }
      const [isErc721, isErc1155] = await Promise.all([
        NftContractService.supportsInterface(nftAddress),
        NftContractService.supportsInterface(nftAddress, true),
      ]);
      if (!isErc721 && !isErc1155) {
        throw Error(i18n.t("notifications.nfts.unsupportedTokenStandard"));
      }
      const bridgeContract = bridgeContracts.find(i => i.chainId === +chainId);
      if (!bridgeContract) {
        throw Error(i18n.t("notifications.systemError"));
      }
      const unconfirmedTx = await NftContractService.safeTransferFrom(
        nftAddress,
        account,
        bridgeContract.address,
        tokenId,
        isErc1155
      );
      addUnconfirmedTx({ tx: { ...unconfirmedTx, chainId } });
      await unconfirmedTx.wait();
    } else {
      const { nftAddress, tokenId } = data;
      if (!isValidAddress(nftAddress)) {
        throw Error(i18n.t("notifications.nfts.nftAddressErr"));
      }
      const selectedChainId = $selectedChainId.getState();
      const whitelisted = whitelistedCollections.find(
        i =>
          addressesAreEquals(i.collectionAddress, nftAddress) &&
          +selectedChainId === i.chainId
      );
      await switchNetwork(selectedChainId);
      if (!whitelisted) {
        throw new WhitelistedError();
      }
      const [isErc721, isErc1155] = await Promise.all([
        NftContractService.supportsInterface(nftAddress),
        NftContractService.supportsInterface(nftAddress, true),
      ]);
      if (!isErc721 && !isErc1155) {
        throw Error(i18n.t("notifications.nfts.unsupportedTokenStandard"));
      }
      const bridgeContract = bridgeContracts.find(
        i => i.chainId === +selectedChainId
      );
      if (!bridgeContract) {
        throw Error(i18n.t("notifications.systemError"));
      }
      const unconfirmedTx = await NftContractService.safeTransferFrom(
        nftAddress,
        account,
        bridgeContract.address,
        tokenId,
        isErc1155
      );
      addUnconfirmedTx({ tx: { ...unconfirmedTx, chainId: selectedChainId } });
      await unconfirmedTx.wait();
    }
    toast.info(i18n.t("notifications.nfts.deposited"));
  } catch (err: any) {
    if (err instanceof WhitelistedError) {
      throw new WhitelistedError();
    }
    if (
      err?.error?.code === -32603 &&
      err?.error?.message ===
        "execution reverted: ERC721: transfer caller is not owner nor approved"
    ) {
      throw toast.info(i18n.t("notifications.nfts.noOwnerErr"));
    }
    if (
      err?.error?.code === -32603 &&
      err?.error?.message ===
        "execution reverted: ERC721: operator query for nonexistent token"
    ) {
      throw toast.info(i18n.t("notifications.nfts.notExistErr"));
    }
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

$addNftForm.on(transferNft.doneData, () => ({
  openSeaLink: "",
  nftAddress: "",
  tokenId: "",
}));

$transferError.on(
  transferNft.failData,
  (_, err) => err instanceof WhitelistedError
);

$addNftForm.on(addNftFormSetField, (_, { key, value }) => ({
  ..._,
  [key]: value,
}));

withdrawNft.use(async ({ contractAddress, nftId, to }) => {
  try {
    const { data } = await NftInCustodyService.withdraw({ nftId, to });
    console.log(data);
    await switchNetwork(data.chainId.toString());
    const { nftContractAddress, tokenId, validTo, id, signature } = data;
    const unconfirmedTx = await PuzzledSpaceNFTCustodianService.withdraw({
      contractAddress,
      nftAddress: nftContractAddress,
      tokenId,
      to,
      signatureExpirationTime: validTo,
      id,
      signature,
    });
    addUnconfirmedTx({
      tx: { ...unconfirmedTx, chainId: data.chainId.toString() as TChainId },
    });
    await unconfirmedTx.wait();
    await delay(1000);
    await apolloClient.refetchQueries({
      include: [GET_NFTS_IN_CUSTODY],
    });
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

transferNftsToOtherUser.use(async params => {
  try {
    if (!params.to || !isValidAddress(params.to)) {
      throw Error(i18n.t("notifications.nfts.invalidAddress"));
    }
    await NftInCustodyService.transferToOtherUser(params);
    await apolloClient.refetchQueries({
      include: [GET_NFTS_IN_CUSTODY],
    });
    toast.success(i18n.t("notifications.nfts.transferredSuccessfully"));
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

reloadNftsInCustody.use(async () => {
  try {
    await apolloClient.refetchQueries({
      include: [GET_NFTS_IN_CUSTODY],
    });
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

bulkNftUpload.use(
  async ({
    bridgeContracts,
    collectionAddress,
    chainId,
    nftIds,
    nftCounts,
  }) => {
    try {
      const account = $account.getState();
      if (!account) {
        throw Error(i18n.t("notifications.loginPlease"));
      }
      if (!collectionAddress || !isValidAddress(collectionAddress)) {
        throw Error(i18n.t("notifications.invalidCollectionAddress"));
      }
      if (!isCommaSeparatedNumbers(nftIds)) {
        throw Error(i18n.t("notifications.notCommaSeparatedNumbers"));
      }
      const nftIdsArr = nftIds.split(",");
      await switchNetwork(chainId);
      const [isErc721, isErc1155] = await Promise.all([
        NftContractService.supportsInterface(collectionAddress),
        NftContractService.supportsInterface(collectionAddress, true),
      ]);
      if (!isErc721 && !isErc1155) {
        throw Error(i18n.t("notifications.nfts.unsupportedTokenStandard"));
      }
      const bridgeContract = bridgeContracts.find(
        i => i.chainId.toString() === chainId
      );
      if (!bridgeContract) {
        throw Error(i18n.t("notifications.systemError"));
      }
      if (isErc721) {
        for (const nftId of nftIdsArr) {
          try {
            const owner = await NftContractService.ownerOf({
              tokenContractAddress: collectionAddress,
              nftId,
            });
            if (!addressesAreEquals(owner, account)) {
              throw Error(i18n.t("notifications.notNftOwner", { nftId }));
            }
            const unconfirmedTx = await NftContractService.safeTransferFrom(
              collectionAddress,
              account,
              bridgeContract.address,
              nftId,
              isErc1155
            );
            addUnconfirmedTx({ tx: { ...unconfirmedTx, chainId } });
            unconfirmedTx.wait();
          } catch (err: any) {
            effectErrorHandler(err, true);
          }
        }
      } else {
        if (!isCommaSeparatedNumbers(nftCounts)) {
          throw Error(i18n.t("notifications.notCommaSeparatedNumbers"));
        }
        const nftCountsArr = nftCounts.split(",");
        if (nftCountsArr.includes("0")) {
          throw Error(i18n.t("notifications.invalidAmount"));
        }
        for (let i = 0; i < nftIdsArr.length; i++) {
          const balance = await NftContractService.balanceOf1155({
            tokenContractAddress: collectionAddress,
            address: account,
            id: nftIdsArr[i],
          });
          if (balance.isZero() || balance.lt(nftCountsArr[i])) {
            throw Error(
              i18n.t("notifications.nfts.notEnoughBalance", {
                id: nftIdsArr[i],
              })
            );
          }
        }

        // const balances = await Promise.all(queries);
        // for (let i = 0; i < balances.length; i++) {
        //   console.log(balances[i].lt(nftCountsArr[i]));
        //   // if (balance) {

        //   // }
        // }
        // console.log({ balances });

        const unconfirmedTx = await NftContractService.safeBatchTransferFrom(
          collectionAddress,
          account,
          bridgeContract.address,
          nftIdsArr,
          nftCountsArr
        );
        addUnconfirmedTx({ tx: { ...unconfirmedTx, chainId } });
        await unconfirmedTx.wait();
      }
    } catch (err) {
      const errMsg = effectErrorHandler(err);
      throw Error(errMsg);
    }
  }
);
