import { ethers } from "ethers";
import PuzzledSpaceNFTCustodianAbi from "src/contracts/PuzzledSpaceNFTCustodian.json";
import { IUnconfirmedTx } from "src/types/wallet";

export interface IWithdraw {
  contractAddress: string;
  nftAddress: string;
  tokenId: string;
  to: string;
  signatureExpirationTime: number;
  id: string;
  signature: string;
}

const getContract = (contractAddress: string) => {
  const provider: any = window.ethereum
    ? new ethers.providers.Web3Provider(window?.ethereum, "any")
    : null;
  const signer = provider?.getSigner();
  const contractInstance = new ethers.Contract(
    contractAddress,
    PuzzledSpaceNFTCustodianAbi,
    provider
  );
  const contractInstanceWithSigner = contractInstance?.connect(signer);
  return contractInstanceWithSigner;
};

export default class PuzzledSpaceNFTCustodianService {
  static withdraw({
    contractAddress,
    nftAddress,
    tokenId,
    to,
    signatureExpirationTime,
    id,
    signature,
  }: IWithdraw): Promise<IUnconfirmedTx> {
    console.log(
      nftAddress,
      tokenId,
      1,
      to,
      signatureExpirationTime,
      id,
      signature
    );
    return getContract(contractAddress)?.withdrawal(
      nftAddress,
      tokenId,
      1,
      to,
      signatureExpirationTime,
      id,
      signature
    );
  }
}
