import React, { FC } from "react";
import DatePicker from "react-datepicker";
import cs from "classnames";
import "react-datepicker/dist/react-datepicker.css";

import "./index.scss";
import CustomInput from "./CustomInput";

interface SelectProps {
  onChange: (date: Date | [Date | null, Date | null] | null) => void;
  className?: string;
  wrapperClassName?: string;
  selected: Date | undefined | null;
  showTimeSelect?: boolean;
  dateFormat?: string;
  minDate?: Date;
  maxDate?: Date;
  placeholder?: string;
  isClearable?: boolean;
  isButton?: boolean;
}

const Datepicker: FC<SelectProps> = props => {
  const {
    selected,
    onChange,
    className,
    showTimeSelect,
    minDate,
    dateFormat,
    wrapperClassName,
    placeholder,
    isClearable,
    maxDate,
    isButton,
  } = props;
  const filterPassedTime = (time: Date) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <div className={cs("datepicker-wrapper", wrapperClassName)}>
      <DatePicker
        className={cs("datepicker", className)}
        selected={selected}
        onChange={date => onChange(date)}
        showTimeSelect={showTimeSelect}
        minDate={minDate}
        dateFormat={dateFormat || "dd MMM yyyy HH:mm"}
        timeFormat="HH:mm"
        filterTime={filterPassedTime}
        placeholderText={placeholder}
        isClearable={isClearable}
        maxDate={maxDate}
        customInput={
          isButton ? <CustomInput placeholder={placeholder} /> : undefined
        }
      />
    </div>
  );
};

export default Datepicker;
