import web3 from "web3";
import { guard } from "effector";
import { toast } from "react-toastify";

// import PuzzledSpaceTokenService from "../../services/PuzzledSpaceTokenService";
import { signer } from "src/utils/ethers";
import {
  addListenerForWaitTxConfirmation,
  effectErrorHandler,
  getChainId,
  switchNetwork,
} from "src/utils/helpers";
import {
  $account,
  $chainId,
  // $tokenBalance,
  $unconfirmedTxs,
  addUnconfirmedTx,
  changeAccount,
  checkConnectWallet,
  connectWallet,
  deleteUnconfirmedTx,
  // getBalance,
  changeNetwork,
  deposit,
  reloadBalance,
  withdraw,
} from "./index";
import PuzzledSpaceWalletService from "src/services/PuzzledSpaceWalletService";
import { ACCOUNTS, ACCOUNTS_BALANCES } from "src/graphQl/accounts";
import WalletService from "src/services/WalletService";
import { apolloClient } from "src/graphQl";
import i18n from "src/i18n";

guard({
  clock: checkConnectWallet,
  filter: () => localStorage.getItem("walletConnected") === "true",
  target: connectWallet,
});

connectWallet.use(async () => {
  try {
    if (!window?.ethereum) {
      throw toast.info(i18n.t("notifications.noMetamask"));
    }
    if (window?.ethereum?.selectedAddress) {
      localStorage.setItem("walletConnected", "true");
      return window.ethereum.selectedAddress.toLowerCase();
    }
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    localStorage.setItem("walletConnected", "true");
    return accounts[0].toLowerCase();
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

$account
  .on(connectWallet.doneData, (_, account) => account.toLowerCase())
  .on(changeAccount, (_, account) => account?.toLowerCase());

// if (window.ethereum) {
//   window.ethereum.on("accountsChanged", async ([account]: string[]) => {
//     try {
//       changeAccount(account);
//       await checkConnectWallet();
//       await checkAuth();
//     } catch (err) {
//       console.log("accountsChanged error", err);
//     }
//   });
// }

$unconfirmedTxs.on(addUnconfirmedTx, (unconfirmedTxs, { tx, callback }) => {
  unconfirmedTxs.push(tx);
  addListenerForWaitTxConfirmation(tx.hash, callback);
  return [...unconfirmedTxs];
});

$unconfirmedTxs.on(deleteUnconfirmedTx, (unconfirmedTxs, txHash) => {
  return unconfirmedTxs.filter(tx => tx.hash !== txHash);
});

// getBalance.use(async ({ tokenContractAddress }) => {
//   try {
//     const address = await signer?.getAddress();
//     if (!signer || !address) {
//       return "0";
//     }
//     const bigNumber = await PuzzledSpaceTokenService.balanceOf({
//       tokenContractAddress,
//       address,
//     });
//     return web3.utils.fromWei(bigNumber.toString());
//   } catch (err) {
//     const errMsg = effectErrorHandler(err);
//     throw Error(errMsg);
//   }
// });

// $tokenBalance.on(getBalance.doneData, (_, newBalance) => newBalance);

changeNetwork.use(async chainId => {
  try {
    await switchNetwork(chainId);
    return chainId;
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

$chainId
  .on(connectWallet.doneData, () => window?.ethereum?.networkVersion)
  .on(changeAccount, () => window?.ethereum?.networkVersion)
  .on(changeNetwork.doneData, (_, chainId) => chainId);

deposit.use(async params => {
  try {
    const amount = +params.amount;
    if (!signer) {
      throw Error(i18n.t("notifications.connectWalletPlease"));
    }
    if (amount <= 0) {
      throw Error(i18n.t("notifications.amountErr"));
    }
    const balance = await signer.getBalance();
    if (+web3.utils.fromWei(balance.toString()) <= amount) {
      throw Error(i18n.t("notifications.deposit.smallBalanceErr"));
    }
    const unconfirmedTx = await PuzzledSpaceWalletService.deposit(params);
    addUnconfirmedTx({ tx: { ...unconfirmedTx, chainId: getChainId() } });
    await unconfirmedTx.wait();
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

withdraw.use(
  async ({
    contractId,
    amount,
    contractAddress,
    nonExecutedWalletOperation,
  }) => {
    try {
      if (!signer) {
        throw Error(i18n.t("notifications.connectWalletPlease"));
      }
      if (+amount <= 0) {
        throw Error(i18n.t("notifications.amountErr"));
      }
      let data = nonExecutedWalletOperation;
      if (!data) {
        const res = await WalletService.withdraw({ contractId, amount });
        data = res.data;
      }
      console.log(data);
      const unconfirmedTx = await PuzzledSpaceWalletService.withdraw({
        contractAddress,
        to: data.to,
        amount: data.amount,
        signatureExpirationTime: data.expirationTime,
        operationId: data.id,
        signature: data.signature,
      });
      addUnconfirmedTx({ tx: { ...unconfirmedTx, chainId: getChainId() } });
      await unconfirmedTx.wait();
    } catch (err) {
      const errMsg = effectErrorHandler(err);
      throw Error(errMsg);
    }
  }
);

reloadBalance.use(async () => {
  try {
    await apolloClient.refetchQueries({
      include: [ACCOUNTS, ACCOUNTS_BALANCES],
    });
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});
