import { toast } from "react-toastify";

import { delay, effectErrorHandler } from "src/utils/helpers";
import { changeAccount } from "../wallet";
import {
  $selectedGame,
  bid,
  $selectedPuzzle,
  joinGame,
  $joined,
  checkGamesChanges,
  // checkNewLotChanges,
  sellAll,
  sendReport,
  $reportMessage,
  setJoining,
  checkGameChanges,
  cancelGame,
  setBidUnavailable,
  discordConnect,
  setBidError,
  setResetBidAmountPiece,
  setJoinGameParams,
  $joinGameParams,
  IJoinGameParams,
  // setShowAgreeModal,
} from "./index";
import { apolloClient } from "src/graphQl";
import ReportService from "src/services/ReportService";
import { GAMES, GAMES_FULL_INFO } from "src/graphQl/auctionGames";
import AuctionGameService from "src/services/AuctionGameService";
import { PLAYERS_INFO_WITH_ACCOUNT } from "src/graphQl/auctionPlayerInfos";
import { GAME_LOTS, MY_GAME_LOTS } from "src/graphQl/auctionGameLots";
import { MY_GAMES_COUNT } from "src/graphQl/countAuctionGames";
import { OPERATIONS_AMOUNT_SPENT } from "src/graphQl/accountOperations";
import DiscordService from "src/services/Discord";
import { sample } from "effector";
import i18n from "src/i18n";
// import { WALLET_HISTORY } from "src/graphQl/walletHistory";

bid.use(async ({ gameId, lotId, amount }) => {
  try {
    await AuctionGameService.bid({
      gameId,
      lotId,
      bid: +amount,
    });
    return { puzzleItemsInMarket: [], newSelectedPuzzle: undefined };
  } catch (err: any) {
    if (
      err?.response?.status === 418 &&
      (err?.response?.data?.type === 2 || err?.response?.data?.type === 3)
    ) {
      setBidUnavailable(true);
      throw Error();
    }
    if (err?.response?.status === 418 && err?.response?.data?.type === 1) {
      toast.info(i18n.t("notifications.game.smallBidWarn"));
      throw Error();
    }
    if (
      err?.response?.status === 403 &&
      err?.response?.data?.Errors?.[0]?.Code === 22
    ) {
      toast.info(err?.response?.data?.Errors?.[0]?.Msg);
      setBidError(err?.response?.data?.Errors?.[0]?.Msg);
      setResetBidAmountPiece(lotId);
      throw Error();
    }
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

$selectedPuzzle.on(
  bid.doneData,
  (_, { newSelectedPuzzle }) => newSelectedPuzzle
);

joinGame.use(async params => {
  try {
    setJoining(params.entryPriceIndex);
    await AuctionGameService.join(params);
    // console.log(res);
    await delay(100);
    await apolloClient.refetchQueries({
      include: [GAMES_FULL_INFO],
    });
  } catch (err: any) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

$joined.on(joinGame.doneData, () => true);

// $selectedGame.on(GameGate.close, () => null);

$joined.on(changeAccount, () => false);

checkGameChanges.use(async () => {
  try {
    await delay(1000);
    await apolloClient.refetchQueries({
      include: [
        GAMES_FULL_INFO,
        PLAYERS_INFO_WITH_ACCOUNT,
        OPERATIONS_AMOUNT_SPENT,
      ],
    });
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

checkGamesChanges.use(async () => {
  try {
    await delay(1000);
    await apolloClient.refetchQueries({
      include: [MY_GAMES_COUNT, GAMES],
    });
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

// checkNewLotChanges.use(async () => {
//   try {
//     // await delay(1000);
//     await apolloClient.refetchQueries({
//       include: [GAME_LOTS, WALLET_HISTORY],
//     });
//   } catch (err) {
//     const errMsg = effectErrorHandler(err);
//     throw Error(errMsg);
//   }
// });

sellAll.use(async ({ gameId }) => {
  try {
    await AuctionGameService.sellAll({
      gameId,
    });
    // await delay(2000);
    await apolloClient.refetchQueries({
      include: [
        GAME_LOTS,
        MY_GAME_LOTS,
        GAMES_FULL_INFO,
        PLAYERS_INFO_WITH_ACCOUNT,
      ],
    });
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

sendReport.use(async () => {
  try {
    const game = $selectedGame.getState();
    if (!game) {
      throw Error(i18n.t("notifications.game.reportSentNoGameErr"));
    }
    const message = $reportMessage.getState();
    if (!message) {
      throw Error(i18n.t("notifications.game.reportSentNoMsgErr"));
    }
    await ReportService.sendReport({ gameId: game.id, message });
    toast.success(i18n.t("notifications.game.reportSentSuccess"));
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

cancelGame.use(async gameId => {
  try {
    await AuctionGameService.cancel(gameId);
    await apolloClient.refetchQueries({
      include: [GAMES_FULL_INFO],
    });
    toast.success(i18n.t("notifications.game.gameCancelled"));
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

$reportMessage.on(sendReport.doneData, () => "");

discordConnect.use(async params => {
  try {
    await DiscordService.connect(params);
    // const lastOpenedGameId = localStorage.getItem("lastOpenedGameId");
    // const href = lastOpenedGameId ? `/game/${lastOpenedGameId}` : "/";
    // window.open(href, "_self");
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

sample({
  source: $joinGameParams,
  clock: setJoinGameParams,
  filter: ({ gameId, captchaToken, entryPriceIndex }: IJoinGameParams) =>
    !!gameId &&
    !!captchaToken &&
    typeof entryPriceIndex === "number" &&
    [0, 1, 2].includes(entryPriceIndex),
  target: joinGame,
});

// guard({
//   clock: joinGame.done,
//   filter: $selectedGameStarted,
//   target: setShowAgreeModal,
// });

// setShowAgreeModal.use(() => {
//   setModal("startedGameJoinAgree");
// });
