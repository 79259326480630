import React, { FC, useRef, useState, ReactNode } from "react";
import { BsTriangleFill } from "react-icons/bs";
import cs from "classnames";

import "./index.scss";
import useOutsideClick from "src/hooks/useOutsideClick";

interface IProps {
  title: string | ReactNode;
  hideArrow?: boolean;
  className?: string;
}

const Dropdown: FC<IProps> = ({ title, hideArrow, className, children }) => {
  const [opened, setOpened] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => setOpened(false));

  return (
    <div className={cs("dropdown", className)} ref={wrapperRef}>
      <div onClick={() => setOpened(ps => !ps)} className="dropdown__title">
        {title} {!hideArrow && <BsTriangleFill className="dropdown__arrow" />}
      </div>
      {opened && (
        <div onClick={() => setOpened(false)} className="dropdown__content">
          {children}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
