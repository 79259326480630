import React, { useEffect, useState } from "react";
import cs from "classnames";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";

import Button from "../../common/Button";
import FileDropzone from "../../common/FileDropzone";
import { TFileWithPreview } from "src/types/common";
import HelpInfoIcon from "src/components/common/HelpInfoIcon";
import { $item, changeCollectionImage } from "src/store/admin";

const MAX_FILE_SIZE_MB = 10;

const ChangeCollectionImage = () => {
  const { t } = useTranslation();
  const [file, setFile] = useState<TFileWithPreview | undefined>(undefined);
  const loading = useStore(changeCollectionImage.pending);
  const collection = useStore($item);

  const onAttachmentChange = async (files: TFileWithPreview[]) => {
    if (files.length) {
      setFile(
        Object.assign(files[0], {
          preview: URL.createObjectURL(files[0]),
        })
      );
    }
  };

  useEffect(
    () => () => {
      file?.preview && URL.revokeObjectURL(file.preview);
    },
    [file]
  );

  const renderFile = (file: TFileWithPreview) => {
    if (file?.type.includes("image") && file?.preview) {
      return <img src={file?.preview} alt="Nft" />;
    }
  };

  return (
    <div className="profile">
      <div className="profile__field">
        <div className="profile__field-label">
          {t("modals.changeCollectionImage.imageLabel")}{" "}
          <HelpInfoIcon
            bold
            data-multiline
            data-tip={t("tooltips.changeCollectionImage.image")}
          />
        </div>
        <FileDropzone
          className={cs("profile__dropzone", {
            "profile__dropzone_with-file": file || collection?.contractImage,
          })}
          child={
            file ? (
              renderFile(file)
            ) : collection?.contractImage ? (
              <img src={collection?.contractImage} alt="Nft" />
            ) : (
              <>
                <div>
                  {t("modals.changeCollectionImage.imagePlaceholderPart1")}
                </div>
                <div>
                  {t("modals.changeCollectionImage.imagePlaceholderPart2", {
                    fileSize: MAX_FILE_SIZE_MB,
                  })}
                </div>
              </>
            )
          }
          settings={{
            accept: "image/*",
            maxFiles: 1,
            maxSize: MAX_FILE_SIZE_MB * 1024 * 1024,
          }}
          onDropFile={files => onAttachmentChange(files)}
        />
      </div>
      <Button
        className="profile__btn"
        onClick={() =>
          file &&
          changeCollectionImage({ nftCollectionId: collection.id, image: file })
        }
        loading={loading}
        disabled={!file || loading}
      >
        {t("modals.changeCollectionImage.save")}
      </Button>
    </div>
  );
};

export default ChangeCollectionImage;
