import React, { FC, useEffect, useState } from "react";
import cs from "classnames";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";

import Button from "../../common/Button";
import "./index.scss";
import { $item, updateMediaLink } from "src/store/admin";

interface IProps {}

const UpdateMediaLink: FC<IProps> = () => {
  const { item: collection, updateField, title, label } = useStore($item);
  const loading = useStore(updateMediaLink.pending);
  const { t } = useTranslation();
  const [value, setValue] = useState("");

  useEffect(() => {
    const initValue = collection[updateField] || "";
    setValue(initValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="update-media-link">
      <div className="update-media-link__title">{title}</div>
      <div className="update-media-link__field">
        <div className="update-media-link__field-label">{label}</div>
        <input
          value={value}
          onChange={e => setValue(e.target.value)}
          className={cs("update-media-link__field-value")}
          placeholder={t("modals.updateMediaLink.mediaLinkPlaceholder")}
        />
      </div>
      <Button
        className="update-media-link__btn"
        onClick={() =>
          updateMediaLink({
            mediaLink: value,
            nftCollectionId: collection.id,
            field: updateField,
          })
        }
        loading={loading}
        disabled={loading}
      >
        {t("modals.updateMediaLink.confirmBtn")}
      </Button>
    </div>
  );
};

export default UpdateMediaLink;
