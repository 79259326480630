import $api from "../http";
import { AxiosResponse } from "axios";
import { IAuthParams, IAuthResponse } from "../types/auth";

export default class AuthService {
  static checkAuth(): Promise<AxiosResponse<string>> {
    return $api.get<string>("/auth/current");
  }
  static getNonce(address: string): Promise<AxiosResponse<{ nonce: string }>> {
    return $api.get<{ nonce: string }>("/auth", { params: { address } });
  }
  static auth(params: IAuthParams): Promise<AxiosResponse<IAuthResponse>> {
    return $api.post<IAuthResponse>("/auth", params);
  }
  // static register(params: IAuthParams): Promise<AxiosResponse<IAuthResponse>> {
  //   return $api.post<IAuthResponse>("/auth/register", params);
  // }
}
