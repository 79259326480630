import React, { ChangeEvent, FC, useState } from "react";
import { useGate, useStore } from "effector-react";
import { useQuery } from "@apollo/client";
// import web3 from "web3";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./index.scss";
import Button from "src/components/common/Button";
import { getOnlyNumberValue, isMobile, round } from "src/utils/helpers";
import {
  $account,
  $walletBalance,
  deposit,
  GetBalanceGate,
} from "src/store/wallet";
import { GET_CONTRACTS, ContractTypes } from "src/graphQl/contracts";
import { useAccountBalance } from "src/graphQl/accounts";
// import { signer } from "src/utils/ethers";
import { setModal } from "src/store/app";
import HelpInfoIcon from "src/components/common/HelpInfoIcon";
import ChainIcon from "src/components/common/ChainIcon";
import { ARBITRUM_CHAIN_ID, POLYGON_CHAIN_ID } from "src/config";

interface IProps { }

const TopUp: FC<IProps> = () => {
  useGate(GetBalanceGate);
  const { t } = useTranslation();
  const [amount, setAmount] = useState("");
  // const [walletBalance, setWalletBalance] = useState("0");
  const loading = useStore(deposit.pending);
  const account = useStore($account);
  // const chainId = useStore($chainId);
  const walletBalance = useStore($walletBalance);

  const { data: contracts } = useQuery(GET_CONTRACTS, {
    variables: {
      where: {
        active: { eq: true },
        type: { eq: ContractTypes.WALLET },
      },
    },
  });

  // useEffect(() => {
  //   const getBalance = async () => {
  //     if (!signer) return;
  //     const balance = await signer.getBalance();
  //     setWalletBalance(web3.utils.fromWei(balance.toString()));
  //   };

  //   signer && getBalance();
  // }, [chainId]);

  const { balance } = useAccountBalance(account);
  const contractAddress = contracts?.contracts?.nodes?.[0]?.address;

  const changeAmount = (e: ChangeEvent<HTMLInputElement>) => {
    const validValue = getOnlyNumberValue(e.target.value);
    setAmount(validValue);
  };

  return (
    <div className="top-up">
      <div className="top-up__title">
        {t("modals.deposit.title")}{" "}
        <HelpInfoIcon bold data-multiline data-tip={t("tooltips.deposit")} />
      </div>
      <Link
        to="#!"
        className="top-up__link"
        onClick={() => setModal("withdraw")}
      >
        {t("modals.deposit.withdrawLink")}
      </Link>
      <div className="top-up__balance">
        <div>{t("modals.deposit.puzzledBalance")}:</div>{" "}
        {POLYGON_CHAIN_ID && <ChainIcon
          chainId={POLYGON_CHAIN_ID}
          tooltip={t("tooltips.topUpWithdrawPolygonSign")}
          className="top-up__polygon-icon"
        />}
        {ARBITRUM_CHAIN_ID && <ChainIcon
          chainId={ARBITRUM_CHAIN_ID}
          tooltip={t("tooltips.topUpWithdrawArbitrumSign")}
          className="top-up__arbitrum-icon"
        />}
        {balance}
      </div>
      <div className="top-up__balance">
        <div>{t("modals.deposit.walletBalance")}:</div>{" "}
        {POLYGON_CHAIN_ID && <ChainIcon
          chainId={POLYGON_CHAIN_ID}
          tooltip={t("tooltips.topUpWithdrawPolygonSign")}
          className="top-up__polygon-icon"
        />}
        {ARBITRUM_CHAIN_ID && <ChainIcon
          chainId={ARBITRUM_CHAIN_ID}
          tooltip={t("tooltips.topUpWithdrawArbitrumSign")}
          className="top-up__arbitrum-icon"
        />}
        {round(+walletBalance, 0.000001)}
      </div>
      <div className="top-up__field">
        <div className="top-up__field-label">
          {t("modals.deposit.amountLabel")}
        </div>
        <input
          value={amount}
          onChange={changeAmount}
          className="top-up__field-value"
          placeholder={t("modals.deposit.amountPlaceholder")}
          autoFocus={!isMobile()}
        />
        {POLYGON_CHAIN_ID && <ChainIcon
          chainId={POLYGON_CHAIN_ID}
          tooltip={t("tooltips.topUpWithdrawPolygonSign")}
          className="top-up__polygon-icon-unit"
        />}
        {ARBITRUM_CHAIN_ID && <ChainIcon
          chainId={ARBITRUM_CHAIN_ID}
          tooltip={t("tooltips.topUpWithdrawArbitrumSign")}
          className="top-up__arbitrum-icon-unit"
        />}
      </div>
      <Button
        className="top-up__btn"
        onClick={() => deposit({ contractAddress, address: account!, amount })}
        loading={loading}
        disabled={loading}
        walletRequired
      >
        {t("modals.deposit.confirmBtn")}
      </Button>
    </div>
  );
};

export default TopUp;
