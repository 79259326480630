import React, { FC } from "react";
import cs from "classnames";
import { useGate, useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { SiEthereum } from "react-icons/si";

import { ReactComponent as PolygonIcon } from "src/assets/PolygonIcon.svg";
import { ReactComponent as ArbitrumIcon } from "src/assets/ArbitrumIcon.svg";
import Button from "../../common/Button";
import "./index.scss";
import HelpInfoIcon from "../../common/HelpInfoIcon";
import {
  $fields,
  addIdsAmountFields,
  CollectionGate,
  removeIdsAmountFields,
  setFields,
} from "../../../store/collections";
import { ContractTypes, GET_CONTRACTS } from "src/graphQl/contracts";
import {
  $selectedChainId,
  bulkNftUpload,
  setSelectedChainId,
} from "src/store/nft";
// import NftContractService from "src/services/NftContractService";
import { CONTRACT_TYPES } from "src/config/constants";
import { FiTrash2 } from "react-icons/fi";
import { ARBITRUM_CHAIN_ID, ETH_CHAIN_ID, POLYGON_CHAIN_ID } from "src/config";

const BLOCKCHAINS: any[] = [];
if (ETH_CHAIN_ID) {
  BLOCKCHAINS.push(
    {
      title: "Ethereum",
      icon: <SiEthereum className="add-nft-modal__icon" />,
      key: ETH_CHAIN_ID,
    }
  );
}
if (POLYGON_CHAIN_ID) {
  BLOCKCHAINS.push(
    {
      title: "Polygon",
      icon: <PolygonIcon className="add-nft-modal__icon" />,
      key: POLYGON_CHAIN_ID,
    }
  );
}
if (ARBITRUM_CHAIN_ID) {
  BLOCKCHAINS.push(
    {
      title: "Arbitrum",
      icon: <ArbitrumIcon className="add-nft-modal__icon _not-fill" />,
      key: ARBITRUM_CHAIN_ID,
    }
  );
}

interface IProps { }

const BulkNftUpload: FC<IProps> = () => {
  useGate(CollectionGate);
  const { t } = useTranslation();
  const { nftIds, idsAmount, contractType, collectionAddress } =
    useStore($fields);
  const selectedChainId = useStore($selectedChainId);
  const uploading = useStore(bulkNftUpload.pending);
  // const [title, setTitle] = useState("modals.bulkNftUpload.title721");

  // useEffect(() => {
  //   const checkStandard = async () => {
  //     const [isErc721, isErc1155] = await Promise.all([
  //       NftContractService.supportsInterface(collection!.collectionAddress),
  //       NftContractService.supportsInterface(
  //         collection!.collectionAddress,
  //         true
  //       ),
  //     ]);
  //     if (!isErc721 && !isErc1155) {
  //       setTitle("modals.bulkNftUpload.titleUnsupported");
  //     }
  //     if (isErc1155) {
  //       setTitle("modals.bulkNftUpload.title1155");
  //     }
  //   };
  //   collection && checkStandard();
  // }, [collection]);

  const { loading: loadingContracts, data } = useQuery(GET_CONTRACTS, {
    variables: {
      where: {
        active: { eq: true },
        type: { eq: ContractTypes.BRIDGE },
      },
    },
  });

  const bridgeContracts = data?.contracts?.nodes;

  const loading = uploading || loadingContracts;

  const is721 = contractType === "721";
  const is1155 = contractType === "1155";

  return (
    <div className="bulk-nft-upload">
      <div className="bulk-nft-upload__title">
        {t("modals.bulkNftUpload.title")}
        <HelpInfoIcon
          bold
          data-tip={t("tooltips.bulkNftUpload.title")}
          data-multiline
        />
      </div>
      <div className="bulk-nft-upload__field bulk-nft-upload__field_flex">
        <div className="bulk-nft-upload__field-label">
          {t("modals.bulkNftUpload.chooseBlockchain")}
          <HelpInfoIcon
            bold
            data-tip={t("tooltips.bulkNftUpload.blockchain")}
            data-multiline
          />
        </div>
        <div className="bulk-nft-upload__contract-types-container">
          {BLOCKCHAINS.map(i => (
            <div
              key={i.key}
              className={cs("add-nft-modal__chain-btn", {
                "add-nft-modal__chain-btn_selected": i.key === selectedChainId,
              })}
              onClick={() => setSelectedChainId(i.key)}
            >
              {i.icon} {i.title}
            </div>
          ))}
        </div>
      </div>
      <div className="bulk-nft-upload__field bulk-nft-upload__field_flex">
        <div className="bulk-nft-upload__field-label">
          {t("modals.bulkNftUpload.contractTypeLabel")}
          <HelpInfoIcon
            bold
            data-tip={t("tooltips.bulkNftUpload.contractType")}
            data-multiline
          />
        </div>
        <div className="bulk-nft-upload__contract-types-container">
          {CONTRACT_TYPES.map(i => (
            <div
              key={i.key}
              className={cs(
                "add-nft-modal__chain-btn bulk-nft-upload__contract-type-btn",
                {
                  "add-nft-modal__chain-btn_selected": i.key === contractType,
                }
              )}
              onClick={() => setFields({ contractType: i.key })}
            >
              {i.title}
            </div>
          ))}
        </div>
      </div>
      <div className="bulk-nft-upload__field">
        <div className="bulk-nft-upload__field-label">
          {t("modals.bulkNftUpload.collectionAddressLabel")}
          <HelpInfoIcon
            bold
            data-tip={t("tooltips.bulkNftUpload.collectionAddress")}
            data-multiline
          />
        </div>
        <input
          value={collectionAddress}
          onChange={e => setFields({ collectionAddress: e.target.value })}
          className={cs("bulk-nft-upload__field-value")}
          placeholder={t("modals.bulkNftUpload.collectionAddressPlaceholder")}
        />
      </div>
      {is721 && (
        <div className="bulk-nft-upload__field">
          <div className="bulk-nft-upload__field-label">
            {t("modals.bulkNftUpload.idsFieldLabel")}
            <HelpInfoIcon
              bold
              data-tip={t("tooltips.bulkNftUpload.nftIds")}
              data-multiline
            />
          </div>
          <input
            value={nftIds}
            onChange={e => setFields({ nftIds: e.target.value })}
            className={cs("bulk-nft-upload__field-value")}
            placeholder={t("modals.bulkNftUpload.idsFieldPlaceholder")}
          />
        </div>
      )}
      {is1155 && (
        <div className="bulk-nft-upload__field">
          <div className="bulk-nft-upload__field-label">
            {t("modals.bulkNftUpload.idsAmountFieldsLabel")}
            <HelpInfoIcon
              bold
              data-tip={t("tooltips.bulkNftUpload.idsAmountFields")}
              data-multiline
            />
          </div>
          <div className="bulk-nft-upload__1155-fields-container">
            {idsAmount.map((item, index) => (
              <div key={index} className="bulk-nft-upload__1155-fields">
                <input
                  value={item.id}
                  onChange={e => {
                    const newIdsAmount = [...idsAmount];
                    newIdsAmount.splice(index, 1, {
                      ...newIdsAmount[index],
                      id: e.target.value,
                    });
                    setFields({ idsAmount: newIdsAmount });
                  }}
                  className={cs("bulk-nft-upload__field-value")}
                  placeholder={t("modals.bulkNftUpload.idFieldPlaceholder")}
                />
                <input
                  value={item.amount}
                  onChange={e => {
                    const newIdsAmount = [...idsAmount];
                    newIdsAmount.splice(index, 1, {
                      ...newIdsAmount[index],
                      amount: e.target.value,
                    });
                    setFields({ idsAmount: newIdsAmount });
                  }}
                  className={cs("bulk-nft-upload__field-value")}
                  placeholder={t("modals.bulkNftUpload.amountFieldPlaceholder")}
                />
                {index === 0 && (
                  <div
                    onClick={() => addIdsAmountFields()}
                    className="bulk-nft-upload__1155-add-fields"
                  >
                    +
                  </div>
                )}
                {index !== 0 && (
                  <div
                    onClick={() => removeIdsAmountFields(index)}
                    className="bulk-nft-upload__1155-remove-fields"
                  >
                    <FiTrash2 />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      <Button
        className="bulk-nft-upload__btn"
        onClick={() =>
          bulkNftUpload({
            bridgeContracts,
            collectionAddress,
            chainId: selectedChainId,
            nftIds: is1155 ? idsAmount.map(i => i.id).join(",") : nftIds,
            nftCounts: idsAmount.map(i => i.amount).join(","),
          })
        }
        loading={loading}
        disabled={loading}
        walletRequired
        ignoreChain
      >
        {t("modals.bulkNftUpload.confirmBtn")}
      </Button>
    </div>
  );
};

export default BulkNftUpload;
