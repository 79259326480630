import { SelectOption } from "src/types/common";
import {
    createEffect,
    createEvent,
    createStore,
    forward,
    restore,
} from "effector";
import { createGate } from "effector-react";
import { IAuctionGameLot } from "src/graphQl/auctionGameLots";
import { IAuctionGame } from "src/graphQl/auctionGames";
import { IDiscordConnectParams } from "src/services/Discord";
import { GameStatus } from "src/types/games";
// import { GAMES_ON_HOME_PAGE } from "src/config/constants";

export const GameGate = createGate();
export const JoinGameGate = createGate();
export const BidModalGate = createGate();
export const SearchGate = createGate();

export const updateLeaderboard = createEvent();
export const refetchLeaderboard = createEffect<void, void>();

forward({
    from: updateLeaderboard,
    to: refetchLeaderboard,
});

export const selectGame = createEvent<IAuctionGame | null>();
export const $selectedGame = restore(selectGame, null).reset(GameGate.close);
export const $selectedGameStarted = $selectedGame.map(
    (game) => game?.status === GameStatus.STARTED
);

export const setSelectedPuzzle = createEvent<IAuctionGameLot | null>();
export const $selectedPuzzle = restore(setSelectedPuzzle, null);

export const bid = createEffect<
    { gameId: string; lotId: string; amount: string },
    {
        puzzleItemsInMarket: IAuctionGameLot[];
        newSelectedPuzzle?: IAuctionGameLot;
    }
>();

export const setBidUnavailable = createEvent<boolean>();
export const $bidUnavailable = restore(setBidUnavailable, false).reset(
    BidModalGate.close
);

export const setBidError = createEvent<string>();
export const $bidError = restore(setBidError, "").reset(BidModalGate.close);

export const setResetBidAmountPiece = createEvent<string | null>();
export const $resetBidAmountPiece = restore(setResetBidAmountPiece, null);

export const joinGame = createEffect<any, void>();
export const $joined = createStore(false);

export const setJoining = createEvent<number | null>();
export const $joining = restore(setJoining, null).reset([
    joinGame.doneData,
    joinGame.failData,
]);

export const checkGameChanges = createEffect<void, void>();
export const checkGamesChanges = createEffect<void, void>();
// export const checkNewLotChanges = createEffect<void, void>();

export const setReportMessage = createEvent<string>();
export const $reportMessage = restore(setReportMessage, "");

export const sellAll = createEffect<{ gameId: string }, void>();

export const sendReport = createEffect<void, void>();
export const cancelGame = createEffect<string, void>();

export const setConfirmOpened = createEvent<boolean>();
export const $confirmOpened = restore(setConfirmOpened, false);

export const setBidAllowed = createEvent<boolean>();
export const $bidAllowed = restore(setBidAllowed, true);

export const setYourBid = createEvent<string>();
export const $yourBid = restore(setYourBid, "");

export const discordConnect = createEffect<IDiscordConnectParams, void>();

export interface IJoinGameParams {
    gameId?: string;
    captchaToken?: string;
    entryPriceIndex?: number;
}

export const setJoinGameParams = createEvent<IJoinGameParams>();
export const $joinGameParams = createStore({})
    .on(setJoinGameParams, (_, fields) => ({
        ..._,
        ...fields,
    }))
    .reset([joinGame.done]);

// export const setShowAgreeModal = createEffect<void, void>();
export const setSearchGames = createEvent<string>();
export const $searchGames = restore(setSearchGames, "").reset(SearchGate.close);

export const setHideEmptyPlaceholderOnHomePage = createEvent<boolean>();
// export const incEmptyGamesOnHomePage = createEvent();
export const $hideEmptyPlaceholderOnHomePage = restore(
    setHideEmptyPlaceholderOnHomePage,
    true
);
// export const $emptyGamesOnHomePage = restore(setEmptyGamesOnHomePage, 0)
//   .on(incEmptyGamesOnHomePage, value => ++value)
//   .reset([setSearchGames]);

// export const $homePageNoGames = $emptyGamesOnHomePage.map(
//   count => count === GAMES_ON_HOME_PAGE
// );

// $emptyGamesOnHomePage.watch((state, payload) =>
//   console.log({ state, payload })
// );

export const setCategory = createEvent<SelectOption>();
export const $category = restore(setCategory, {
    label: "All",
    value: "",
}).reset(SearchGate.close);

export const setCollection = createEvent<SelectOption>();
export const $collection = restore(setCollection, {
    label: "All",
    value: "",
}).reset(SearchGate.close);
