import $api from "../http";
import { AxiosResponse } from "axios";
import { ISetProfileParams, ISetAvatarParams } from "../types/profile";

export default class ProfileService {
  static setAvatar(params: ISetAvatarParams): Promise<AxiosResponse<void>> {
    const data = new FormData();
    data.set("avatar", params.avatar);
    return $api.post<void>("/profile/avatar", data);
  }
  static setProfile(params: ISetProfileParams): Promise<AxiosResponse<void>> {
    return $api.post<void>("/profile/profile", params);
  }
  static deleteAvatar(): Promise<AxiosResponse<void>> {
    return $api.delete<void>("/profile/profile");
  }
}
