import React, { FC, useEffect, useMemo, useState } from "react";
import { useStore } from "effector-react";
import { useLazyQuery } from "@apollo/client";
import cs from "classnames";
import BigNumber from "bignumber.js";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import "./index.scss";
import Loader from "src/components/common/Loader";
import { $account, $txsFilterDate } from "src/store/wallet";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
} from "src/config/constants";
import { groupBy } from "src/utils/helpers";
import EmptyPlaceholder from "src/components/common/EmptyPlaceholder";
import { TRANSACTIONS, TransactionType } from "src/graphQl/transactions";
import Pagination from "src/components/common/Pagination";

interface IProps {}

const BlockchainTxs: FC<IProps> = () => {
  const { t } = useTranslation();
  const account = useStore($account);
  const txsFilterDate = useStore($txsFilterDate);
  const [opened, setOpened] = useState<string[]>([]);
  const [pageSize, setPageSize] = useState(10);

  const [getHistory, { loading, data: transactions, fetchMore }] =
    useLazyQuery(TRANSACTIONS);

  const variables: any = useMemo(() => {
    let where: any = {
      or: [
        {
          fromAccount: { address: { eq: account } },
        },
        {
          toAccount: { address: { eq: account } },
        },
      ],
      operation: {
        gameId: { eq: null },
        time: undefined,
      },
      type: {
        in: [TransactionType.DEPOSIT, TransactionType.WITHDRAWAL],
      },
    };
    if (txsFilterDate) {
      const gte = new Date(txsFilterDate);
      const lte = new Date(txsFilterDate);
      gte.setHours(0, 0, 0, 0);
      lte.setHours(24, 0, 0, 0);
      where = {
        ...where,
        operation: {
          ...where.operation,
          time: {
            gte,
            lte,
          },
        },
      };
    }
    return {
      address: account,
      where,
      order: { operation: { time: "DESC" } },
    };
  }, [account, txsFilterDate]);

  // if (txsFilterDate) {
  //   const gte = new Date(txsFilterDate);
  //   const lte = new Date(txsFilterDate);
  //   gte.setHours(0, 0, 0, 0);
  //   lte.setHours(24, 0, 0, 0);
  //   variables.where.operation.time = {
  //     gte,
  //     lte,
  //   };
  // }

  // useEffect(() => {
  //   getHistory({
  //     variables: { ...variables, first: 1 },
  //     notifyOnNetworkStatusChange: true,
  //   });
  // }, []);
  useEffect(() => {
    getHistory({
      variables: { ...variables, first: pageSize },
      notifyOnNetworkStatusChange: true,
    });
  }, [pageSize, txsFilterDate, variables, getHistory]);

  // const { loading, data: walletHistory } = useQuery(WALLET_HISTORY, {
  //   variables: {
  //     address: account,
  //     where: {
  //       wallet: { address: { eq: account } },
  //       relatedGameId: { eq: null },
  //       type: {
  //         in: [
  //           TransactionOperationType.DEPOSIT,
  //           TransactionOperationType.WITHDRAWAL_COMPLETE,
  //         ],
  //       },
  //     },
  //     order: { timeCreated: "DESC" },
  //     first: 1000,
  //   },
  // });

  const totalCount = transactions?.transactions?.totalCount || 0;
  const pageInfo = transactions?.transactions?.pageInfo;

  const txs = useMemo(
    () => transactions?.transactions?.nodes || [],
    [transactions?.transactions?.nodes]
  );

  const formattedTxs = useMemo(
    () =>
      txs.map((i: any) => {
        const { operation } = i;
        const titleDate = dayjs(operation.time).format(DEFAULT_DATE_FORMAT);
        return {
          ...i,
          timeCreated: dayjs(operation.time).format(DEFAULT_DATE_TIME_FORMAT),
          title: `${titleDate} ${t("blockchainTxs.depositWithdrawTxs")}`,
          titleFormatted: `${titleDate} ${t(
            "blockchainTxs.depositWithdrawTxs"
          )}`,
          sum: i.type === TransactionType.WITHDRAWAL ? -i.amount : i.amount,
        };
      }),
    [txs, t]
  );

  const groupedTxs = useMemo(
    () => groupBy(formattedTxs, "title"),
    [formattedTxs]
  );

  if (loading) {
    return <Loader className="transactions__loader" />;
  }

  if (Object.entries(groupedTxs).length === 0) {
    return (
      <EmptyPlaceholder
        content={
          <>
            {t("blockchainTxs.noTxsP1")}
            <br />
            {t("blockchainTxs.noTxsP2")}{" "}
            <a href="/" className="empty-placeholder__link">
              {t("blockchainTxs.noTxsP3")}
            </a>{" "}
            {t("blockchainTxs.noTxsP4")}
          </>
        }
      />
    );
  }

  return (
    <>
      <div className="transactions__list">
        {Object.entries(groupedTxs)?.map((i: any) => {
          const totalSum = i[1].reduce((acc: any, cur: any) => {
            acc = new BigNumber(acc).plus(cur.sum).toNumber();
            return acc;
          }, 0);
          return (
            <div key={i[0]} className="transactions__item">
              <div
                className={cs("transactions__item-title", {
                  "transactions__item-title_opened": opened.includes(i[0]),
                })}
                onClick={() =>
                  setOpened(ps => {
                    if (ps.includes(i[0])) {
                      return ps.filter(j => j !== i[0]);
                    }
                    return [...ps, i[0]];
                  })
                }
              >
                <div>{i[1][0].titleFormatted} </div>
                <div
                  style={{
                    padding: "1px 5px",
                    borderRadius: 4,
                    color: totalSum >= 0 ? "#00f" : "#f00",
                  }}
                >
                  {totalSum > 0 && "+"}
                  {totalSum} Matic
                </div>
              </div>
              <div
                className={cs("transactions__item-info-wrapper", {
                  "transactions__item-info-wrapper_opened": opened.includes(
                    i[0]
                  ),
                })}
              >
                {i[1]?.map((j: any, ind: number) => (
                  <div key={ind} className="transactions__item-info">
                    <div>{j.timeCreated}</div>{" "}
                    <div>
                      {j.type === TransactionType.DEPOSIT
                        ? t("blockchainTxs.deposit")
                        : t("blockchainTxs.withdrawal")}
                    </div>{" "}
                    <div
                      style={{
                        color:
                          j.type === TransactionType.DEPOSIT ? "#00f" : "#f00",
                      }}
                    >
                      {j.type === TransactionType.DEPOSIT && "+"}
                      {j.sum} MATIC
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
      <Pagination
        pageSize={pageSize}
        totalCount={totalCount}
        pageInfo={pageInfo}
        fetchMore={fetchMore}
        variables={variables}
        setPageSize={setPageSize}
      />
    </>
  );
};

export default BlockchainTxs;
