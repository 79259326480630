import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { ARBITRUM_CHAIN_ID, POLYGON_CHAIN_ID } from "./config";
import en from "src/locales/en.json";
import polygonEn from "src/locales/en.polygon.json";
import arbitrumEn from "src/locales/en.arbitrum.json";
import { merge } from "lodash-es";

let translation = {};
if (POLYGON_CHAIN_ID) {
  translation = merge(en, polygonEn);
} else if (ARBITRUM_CHAIN_ID) {
  translation = merge(en, arbitrumEn);
}

console.log({POLYGON_CHAIN_ID, ARBITRUM_CHAIN_ID});

const resources = {
  en: {
    translation: translation,
  },
};

export const langs = Object.keys(resources);

i18n.use(initReactI18next).init({
  debug: true,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});

export default i18n;
