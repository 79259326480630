import { useEffect, useMemo } from "react";
import { useGate, useStore } from "effector-react";
import cs from "classnames";
import { SiEthereum } from "react-icons/si";
import { useTranslation } from "react-i18next";

import "./index.scss";
import { ReactComponent as PolygonIcon } from "src/assets/PolygonIcon.svg";
import { ReactComponent as ArbitrumIcon } from "src/assets/ArbitrumIcon.svg";
import Button from "../../common/Button";
import {
  $importCollectionForm,
  $selectedChainId,
  importCollectionFormSetField,
  setSelectedChainId,
  importCollection,
  ImportCollectionModalGate,
} from "src/store/collections";
import { getOnlyNumberValue } from "src/utils/helpers";
import { ARBITRUM_CHAIN_ID, ETH_CHAIN_ID, POLYGON_CHAIN_ID } from "src/config";
import HelpInfoIcon from "src/components/common/HelpInfoIcon";
import { useQuery } from "@apollo/client";
import { USERS } from "src/graphQl/users";
import { $account } from "src/store/wallet";
import { setModal } from "src/store/app";
import SelectComponent from "src/components/common/Select";
import { useCollectionCategories } from "src/graphQl/nftCollectionCategories";
import { SelectOption } from "src/types/common";

const BLOCKCHAINS: any[] = [];
if (ETH_CHAIN_ID) {
  BLOCKCHAINS.push(
    {
      title: "Ethereum",
      icon: <SiEthereum />,
      key: ETH_CHAIN_ID,
    }
  );
}
if (POLYGON_CHAIN_ID) {
  BLOCKCHAINS.push(
    {
      title: "Polygon",
      icon: <PolygonIcon />,
      key: POLYGON_CHAIN_ID,
    }
  );
}
if (ARBITRUM_CHAIN_ID) {
  BLOCKCHAINS.push(
    {
      title: "Arbitrum",
      icon: <ArbitrumIcon className="_not-fill"/>,
      key: ARBITRUM_CHAIN_ID,
    }
  );
}

const ImportCollectionModal = () => {
  useGate(ImportCollectionModalGate);
  const { t } = useTranslation();
  const selectedChainId = useStore($selectedChainId);
  const importing = useStore(importCollection.pending);
  const account = useStore($account);
  const importForm = useStore($importCollectionForm);

  const { categories, loadingCategories } = useCollectionCategories();

  const { data: users, loading: loadingUsers } = useQuery(USERS, {
    variables: {
      where: {
        address: { eq: account },
      },
    },
  });

  const accountInfo = users?.users?.nodes?.[0];
  const unRegistered = accountInfo?.username === null;

  const importHandler = () => {
    importCollection();
  };

  const defaultValueIndex = useMemo(
    () => categories.findIndex((i: SelectOption) => i.label === "Other"),
    [categories]
  );

  useEffect(() => {
    importForm.categoryId === "" &&
      defaultValueIndex !== -1 &&
      importCollectionFormSetField({
        key: "categoryId",
        value: categories[defaultValueIndex].value,
      });
  }, [defaultValueIndex, categories, importForm.categoryId]);

  return (
    <div className="import-collection">
      <div className="import-collection__field">
        <div className="import-collection__field-label">
          {t("modals.importCollection.chooseBlockchain")}
        </div>
        <div className="import-collection__chains">
          {BLOCKCHAINS.map(i => (
            <div
              key={i.key}
              className={cs("import-collection__chain-btn", {
                "import-collection__chain-btn_selected":
                  i.key === selectedChainId,
              })}
              onClick={() => setSelectedChainId(i.key)}
            >
              {i.icon} {i.title}
            </div>
          ))}
        </div>
      </div>
      <div className="import-collection__nft-links">
        <div className="import-collection__field">
          <div className="import-collection__field-label">
            {t("modals.importCollection.collectionAddressLabel")}
          </div>
          <input
            name="collectionAddress"
            value={importForm.collectionAddress}
            onChange={e =>
              importCollectionFormSetField({
                key: e.target.name,
                value: e.target.value,
              })
            }
            className={cs("import-collection__field-value")}
            placeholder={t(
              "modals.importCollection.collectionAddressPlaceholder"
            )}
          />
        </div>
        <div className="import-collection__field">
          <div className="import-collection__field-label">
            {t("modals.importCollection.royaltyLabel")}{" "}
            <HelpInfoIcon
              bold
              data-tip={t("tooltips.importCollection.royalty")}
            />
          </div>
          <input
            name="royaltyPercent"
            value={importForm.royaltyPercent}
            onChange={e => {
              const validValue = getOnlyNumberValue(e.target.value);
              importCollectionFormSetField({
                key: e.target.name,
                value: validValue,
              });
            }}
            className={cs("import-collection__field-value")}
            placeholder={t("modals.importCollection.royaltyPlaceholder")}
          />
          <div className="import-collection__field-unit">%</div>
        </div>
      </div>
      {!loadingCategories && (
        <div className="import-collection__field">
          <div className="import-collection__field-label">
            {t("modals.importCollection.collectionCategoryLabel")}
          </div>
          <SelectComponent
            options={categories}
            onChange={({ value }: any) =>
              importCollectionFormSetField({
                key: "categoryId",
                value,
              })
            }
            // className="create-game-step-2__field-value"
            defaultValueIndex={defaultValueIndex}
            className={cs(
              "import-collection__field-value import-collection__select-category"
            )}
          />
        </div>
      )}
      {unRegistered ? (
        <Button
          className="import-collection__btn"
          onClick={() => setModal("register")}
          loading={loadingUsers}
        >
          {t("buttons.register")}
        </Button>
      ) : (
        <Button
          onClick={importHandler}
          className="import-collection__btn"
          loading={importing}
          disabled={!importForm.collectionAddress || importing}
          walletRequired
          ignoreChain
        >
          {t("modals.importCollection.submitBtn")}
        </Button>
      )}
    </div>
  );
};

export default ImportCollectionModal;
