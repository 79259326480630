import { toast } from "react-toastify";

import {
  $fields,
  $importCollectionForm,
  $selectedChainId,
  importCollection,
  importCollectionFormSetField,
  setCategory,
  setFields,
  setRoyalty,
} from ".";
import {
  NFT_COLLECTIONS,
  NFT_COLLECTIONS_ADMIN,
} from "src/graphQl/nftCollections";
import NftInCustodyService from "src/services/NftInCustodyService";
import { signer } from "src/utils/ethers";
import { effectErrorHandler, isValidAddress } from "src/utils/helpers";
import { MAX_COLLECTION_ROYALTY } from "src/config/constants";
import { apolloClient } from "src/graphQl";
import i18n from "src/i18n";

importCollection.use(async () => {
  try {
    const data = $importCollectionForm.getState();
    const { collectionAddress, royaltyPercent, categoryId } = data;
    if (!isValidAddress(collectionAddress)) {
      throw Error(i18n.t("notifications.invalidAddress"));
    }
    if (+royaltyPercent > MAX_COLLECTION_ROYALTY) {
      throw Error(
        i18n.t("notifications.collections.maxRoyaltyWarn", {
          maxRoyalty: MAX_COLLECTION_ROYALTY,
        })
      );
    }
    const selectedChainId = $selectedChainId.getState();
    await NftInCustodyService.setRoyalty({
      chainId: +selectedChainId,
      collectionAddress,
      royaltyAddress: null,
      royaltyPercent: +royaltyPercent,
      categoryId,
    });
    // await delay(1000);
    toast.success(i18n.t("notifications.collections.imported"));
    await apolloClient.refetchQueries({
      include: [NFT_COLLECTIONS],
    });
  } catch (err: any) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

setRoyalty.use(async () => {
  try {
    const address = await signer?.getAddress();
    if (!signer || !address) {
      throw Error(i18n.t("notifications.loginPlease"));
    }
    const { collection, royaltyPercent } = $fields.getState();
    const { collectionAddress, chainId } = collection!;
    if (!isValidAddress(collectionAddress)) {
      throw Error(i18n.t("notifications.collections.invalidCollectionAddress"));
    }
    if (royaltyPercent === "") {
      throw Error(i18n.t("notifications.collections.noRoyaltyWarn"));
    }
    if (+royaltyPercent > MAX_COLLECTION_ROYALTY) {
      throw Error(
        i18n.t("notifications.collections.maxRoyaltyWarn", {
          maxRoyalty: MAX_COLLECTION_ROYALTY,
        })
      );
    }
    await NftInCustodyService.setRoyalty({
      chainId,
      collectionAddress,
      royaltyAddress: address,
      royaltyPercent: +royaltyPercent,
    });
    toast.success(i18n.t("notifications.collections.setRoyaltySuccess"));
    await apolloClient.refetchQueries({
      include: [NFT_COLLECTIONS],
    });
  } catch (err: any) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

$importCollectionForm.on(importCollectionFormSetField, (_, { key, value }) => ({
  ..._,
  [key]: value,
}));

$fields.on(setFields, (_, fields) => ({
  ..._,
  ...fields,
}));

setCategory.use(async categoryId => {
  try {
    const address = await signer?.getAddress();
    if (!signer || !address) {
      throw Error(i18n.t("notifications.loginPlease"));
    }
    const { collection } = $fields.getState();
    const { collectionAddress, chainId } = collection!;
    if (!isValidAddress(collectionAddress)) {
      throw Error(i18n.t("notifications.collections.invalidCollectionAddress"));
    }

    await NftInCustodyService.setRoyalty({
      chainId,
      collectionAddress,
      categoryId,
    });
    toast.success(i18n.t("notifications.collections.setCategorySuccess"));
    await apolloClient.refetchQueries({
      include: [NFT_COLLECTIONS, NFT_COLLECTIONS_ADMIN],
    });
  } catch (err: any) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});
