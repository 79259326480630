import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";

import "./index.scss";
import { useAccountBalance } from "src/graphQl/accounts";
import { $account } from "src/store/wallet";
import { setModal } from "src/store/app";
import ChainIcon from "../ChainIcon";
import { ARBITRUM_CHAIN_ID, POLYGON_CHAIN_ID, ETH_CHAIN_ID } from "src/config";

const AccountBalance = () => {
  const { t } = useTranslation();
  const account = useStore($account);

  const { balance } = useAccountBalance(account);

  if (!account) {
    return null;
  }

  return (
    <div className="account-balance" onClick={() => setModal("deposit")}>
      {t("dropdown.accountMenu.balance")}{" "}
      {ETH_CHAIN_ID && <ChainIcon
        chainId={ETH_CHAIN_ID}
        className="account-balance__puzzle-icon"
      />}
      {POLYGON_CHAIN_ID && <ChainIcon
        chainId={POLYGON_CHAIN_ID}
        className="account-balance__puzzle-icon"
      />}
      {ARBITRUM_CHAIN_ID && <ChainIcon
        chainId={ARBITRUM_CHAIN_ID}
        className="account-balance__puzzle-icon _not-fill"
      />}
      {balance}
    </div>
  );
};

export default AccountBalance;
