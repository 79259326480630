import React, { useState } from "react";
import { useStore } from "effector-react";
import cs from "classnames";
import { SiEthereum } from "react-icons/si";
import { useTranslation } from "react-i18next";

import "./index.scss";
import { ReactComponent as PolygonIcon } from "src/assets/PolygonIcon.svg";
import { ReactComponent as ArbitrumIcon } from "src/assets/ArbitrumIcon.svg";
import Button from "src/components/common/Button";
import { ARBITRUM_CHAIN_ID, ETH_CHAIN_ID, POLYGON_CHAIN_ID } from "src/config";
import { addCollectionToWhitelist } from "src/store/admin";

let BLOCKCHAINS: any[] = [];
if (ETH_CHAIN_ID) {
  BLOCKCHAINS.push({
    title: "Ethereum",
    icon: <SiEthereum />,
    key: ETH_CHAIN_ID,
  });
}
if (POLYGON_CHAIN_ID) {
  BLOCKCHAINS.push({
    title: "Polygon",
    icon: <PolygonIcon />,
    key: POLYGON_CHAIN_ID,
  });
}
if (ARBITRUM_CHAIN_ID) {
  BLOCKCHAINS.push({
    title: "Arbitrum",
    icon: <ArbitrumIcon className="_not-fill"/>,
    key: ARBITRUM_CHAIN_ID,
  });
}

const AddCollectionModal = () => {
  const { t } = useTranslation();
  const [address, setAddress] = useState("");
  const [discordCommunity, setDiscordCommunity] = useState("");
  const [twitterCommunity, setTwitterCommunity] = useState("");
  const [chainId, setChainId] = useState(BLOCKCHAINS[0].key);
  // const [openSeaLink, setOpenSeaLink] = useState("");
  const importing = useStore(addCollectionToWhitelist.pending);

  return (
    <div className="add-collection-admin">
      <div className="add-collection-admin__field">
        <div className="add-collection-admin__field-label">
          {t("modals.adminAddCollection.chooseBlockchain")}
        </div>
        <div className="add-collection-admin__chains">
          {BLOCKCHAINS.map(i => (
            <div
              key={i.key}
              className={cs("add-collection-admin__chain-btn", {
                "add-collection-admin__chain-btn_selected": i.key === chainId,
              })}
              onClick={() => setChainId(i.key)}
            >
              {i.icon} {i.title}
            </div>
          ))}
        </div>
      </div>
      <div className="add-collection-admin__field">
        <div className="add-collection-admin__field-label">
          {t("modals.adminAddCollection.collectionAddressLabel")}
        </div>
        <input
          value={address}
          onChange={e => setAddress(e.target.value)}
          className={cs("add-collection-admin__field-value")}
          placeholder={t(
            "modals.adminAddCollection.collectionAddressPlaceholder"
          )}
        />
      </div>
      <div className="add-collection-admin__field">
        <div className="add-collection-admin__field-label">
          {t("modals.adminAddCollection.discordCommunityLabel")}
        </div>
        <input
          value={discordCommunity}
          onChange={e => setDiscordCommunity(e.target.value)}
          className={cs("add-collection-admin__field-value")}
          placeholder={t(
            "modals.adminAddCollection.discordCommunityPlaceholder"
          )}
        />
      </div>
      <div className="add-collection-admin__field">
        <div className="add-collection-admin__field-label">
          {t("modals.adminAddCollection.twitterCommunityLabel")}
        </div>
        <input
          value={twitterCommunity}
          onChange={e => setTwitterCommunity(e.target.value)}
          className={cs("add-collection-admin__field-value")}
          placeholder={t(
            "modals.adminAddCollection.twitterCommunityPlaceholder"
          )}
        />
      </div>
      <Button
        onClick={() =>
          addCollectionToWhitelist({
            address,
            chainId: +chainId,
            discordCommunity,
            twitterCommunity,
          })
        }
        className="add-collection-admin__btn"
        loading={importing}
        disabled={!address || importing}
        walletRequired
        ignoreChain
      >
        {t("modals.adminAddCollection.submitBtn")}
      </Button>
    </div>
  );
};

export default AddCollectionModal;
