import React, { FC, ReactElement, useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import "./index.scss";
import Loader from "src/components/common/Loader";
import { $dateRange, AdminPageGate } from "src/store/admin";
import { useGate, useStore } from "effector-react";
import { GAMES_REPORT, NumbersCountDictionary } from "src/graphQl/report";

interface ItemProps {
  label: string | ReactElement;
  value: string | ReactElement;
}

const Item: FC<ItemProps> = ({ label, value }) => (
  <div className="admin-games-analytics__item">
    <div className="admin-games-analytics__item-value">{value}</div>
    <div className="admin-games-analytics__item-label">{label}</div>
  </div>
);

const GamesAnalytics = () => {
  useGate(AdminPageGate);
  const { t } = useTranslation();
  const [startTime, setStartTime] = useState(new Date("01-01-2022"));
  const [endTime, setEndTime] = useState(new Date());
  const dateRange = useStore($dateRange);
  const [startDate, endDate] = dateRange;

  const [getReport, { loading, data }] = useLazyQuery(GAMES_REPORT);

  useEffect(() => {
    if (startDate && endDate) {
      const gte = new Date(startDate);
      const lte = new Date(endDate);
      gte.setHours(0, 0, 0, 0);
      lte.setHours(24, 0, 0, 0);
      setStartTime(gte);
      setEndTime(lte);
    } else if (!startDate && !endDate) {
      setStartTime(new Date("01-01-2022"));
      setEndTime(new Date());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    getReport({
      variables: {
        startTime,
        endTime,
      },
    });
  }, [startTime, endTime, getReport]);

  const report = data?.report;

  if (loading || !report) {
    return <Loader />;
  }

  const {
    averageActivePlayerCount,
    averageBronzeCount,
    averageCreatedTimeHours,
    // averageDurationHours,
    averageDurationSeconds,
    averageForfeitPlayersRevenues,
    // averageFreeCount,
    averageGameCreatorRevenues,
    averageGoldCount,
    averageMaxPlayersCount,
    averageMinPlayersCount,
    averagePlayersCount,
    averageRoyalty,
    averageSilverCount,
    averageStartHours,
    // averageSystemFees,
    averageTotalGameRevenue,
    canceledCount,
    completeCount,
    createdCount,
    featuredGamesCount,
    numbersCountDictionary,
    privateGamesCount,
    startedCount,
    totalGamesCount,
  } = report;

  const puzzleSizes = numbersCountDictionary
    .map((i: NumbersCountDictionary) => {
      const numPiecesSqrt = Math.sqrt(i.key);
      return `(${numPiecesSqrt}x${numPiecesSqrt}) - ${i.value}`;
    })
    .join(", ");

  const featuredGamesPercent = new BigNumber(featuredGamesCount)
    .multipliedBy(100)
    .div(totalGamesCount)
    .dp(4)
    .toString();

  const privateGamesPercent = new BigNumber(privateGamesCount)
    .multipliedBy(100)
    .div(totalGamesCount)
    .dp(4)
    .toString();

  const fields = [
    {
      label: t("tables.gamesAnalytics.statusCount"),
      value: `Created - ${createdCount}, Started - ${startedCount}, Completed - ${completeCount}, Cancelled - ${canceledCount}`,
    },
    {
      label: t("tables.gamesAnalytics.avgCollectionRoyalty"),
      value: averageRoyalty,
    },
    {
      label: t("tables.gamesAnalytics.avgGameCreatedTime"),
      value: averageCreatedTimeHours,
    },
    {
      label: t("tables.gamesAnalytics.avgGameStartTime"),
      value: averageStartHours,
    },
    // {
    //   label: t("tables.gamesAnalytics.avgGameEndTime"),
    //   value: averageEndHours,
    // },
    {
      label: t("tables.gamesAnalytics.avgMaxGameDuration"),
      value: `${new BigNumber(averageDurationSeconds)
        .dividedBy(60)
        .dp(4)
        .toNumber()} min`,
    },
    {
      label: t("tables.gamesAnalytics.avgMinPlayers"),
      value: averageMinPlayersCount,
    },
    {
      label: t("tables.gamesAnalytics.avgMaxPlayers"),
      value: averageMaxPlayersCount,
    },
    {
      label: t("tables.gamesAnalytics.avgPlayersCount"),
      value: averagePlayersCount,
    },
    {
      label: t("tables.gamesAnalytics.avgActivePlayersCount"),
      value: averageActivePlayerCount,
    },
    {
      label: t("tables.gamesAnalytics.averageGoldCount"),
      value: averageGoldCount,
    },
    {
      label: t("tables.gamesAnalytics.averageSilverCount"),
      value: averageSilverCount,
    },
    {
      label: t("tables.gamesAnalytics.averageBronzeCount"),
      value: averageBronzeCount,
    },
    {
      label: t("tables.gamesAnalytics.featuredGamesPercent"),
      value: `${featuredGamesPercent}%`,
    },
    {
      label: t("tables.gamesAnalytics.privateGamesPercent"),
      value: `${privateGamesPercent}%`,
    },
    {
      label: t("tables.gamesAnalytics.puzzleSizes"),
      value: `${puzzleSizes}`,
    },
    // {
    //   label: t("tables.gamesAnalytics.avgMinBidPrice"),
    //   value: avgMinBidPrice,
    // },
    // {
    //   label: t("tables.gamesAnalytics.avgMinBidIncrement"),
    //   value: avgMinBidIncrement,
    // },
    // {
    //   label: t("tables.gamesAnalytics.avgMinutesToWinBid"),
    //   value: avgMinutesToWinBid,
    // },
    // {
    //   label: t("tables.gameAnalytics.goldEntry"),
    //   value: `${forfeitPlayersRevenue} Matic`,
    // },
    // {
    //   label: t("tables.gameAnalytics.silverEntry"),
    //   value: `${minBidStep} Matic`,
    // },
    // {
    //   label: t("tables.gameAnalytics.regularBids"),
    //   value: `${minBidStep} Matic`,
    // },
    // {
    //   label: t("tables.gameAnalytics.forfeitBids"),
    //   value: `${minBidStep} Matic`,
    // },
    // {
    //   label: <b>{t("tables.gameAnalytics.puzzledTotalRevenue")}</b>,
    //   value: `${totalSystemRevenue} Matic`,
    // },

    // {
    //   label: t("tables.gameAnalytics.nftProviderGoldEntry"),
    //   value: `${minBidStep} Matic`,
    // },
    // {
    //   label: t("tables.gameAnalytics.nftProviderSilverEntry"),
    //   value: `${minBidStep} Matic`,
    // },
    // {
    //   label: t("tables.gameAnalytics.nftProviderRegularBids"),
    //   value: `${minBidStep} Matic`,
    // },
    // {
    //   label: t("tables.gameAnalytics.nftProviderForfeitBids"),
    //   value: `${minBidStep} Matic`,
    // },
    {
      label: <b>{t("tables.gameAnalytics.nftProviderTotalRevenue")}</b>,
      value: `${averageGameCreatorRevenues} Matic`,
    },

    // {
    //   label: t("tables.gameAnalytics.collectionOwnerGoldEntry"),
    //   value: `${minBidStep} Matic`,
    // },
    // {
    //   label: t("tables.gameAnalytics.collectionOwnerSilverEntry"),
    //   value: `${minBidStep} Matic`,
    // },
    // {
    //   label: t("tables.gameAnalytics.collectionOwnerRegularBids"),
    //   value: `${minBidStep} Matic`,
    // },
    // {
    //   label: t("tables.gameAnalytics.collectionOwnerForfeitBids"),
    //   value: `${minBidStep} Matic`,
    // },
    // {
    //   label: <b>{t("tables.gameAnalytics.collectionOwnerTotalRevenue")}</b>,
    //   value: `${totalRoyaltyRevenue} Matic`,
    // },

    {
      label: <b>{t("tables.gameAnalytics.playerForfeitTotalRevenue")}</b>,
      value: `${averageForfeitPlayersRevenues} Matic`,
    },
    {
      label: <b>{t("tables.gameAnalytics.totalGameRevenues")}</b>,
      value: `${averageTotalGameRevenue} Matic`,
    },
    // {
    //   label: t("tables.gameAnalytics.minExpectedRevenues"),
    //   value: `${minBidStep} Matic`,
    // },
    // {
    //   label: t("tables.gameAnalytics.totalMinusMinExpected"),
    //   value: `${minBidStep} Matic`,
    // },
    // {
    //   label: <b>{t("tables.gameAnalytics.totalVsMinExpected")}</b>,
    //   value: `${minBidStep} Matic`,
    // },
  ];

  return (
    <div className="admin-games-analytics">
      <div className="admin-games-analytics__items">
        <div className="admin-games-analytics__item">
          <div className="admin-games-analytics__item-value">
            <b>Value</b>
          </div>
          <div className="admin-games-analytics__item-label">
            <b>Explanation</b>
          </div>
        </div>
        {fields.map((props, ind) => (
          <Item key={ind} {...props} />
        ))}
      </div>
    </div>
  );
};

export default GamesAnalytics;
