import React, { FC, useEffect, useMemo, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useGate, useStore } from "effector-react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import "./index.scss";
import { ADMIN_GAMES } from "src/graphQl/auctionGames";
import Table from "src/components/common/Table";
import Button from "src/components/common/Button";
import { GameStatus } from "src/types/games";
import { setModal } from "src/store/app";
import {
  $dateRange,
  $filters,
  $item,
  $sortBy,
  AdminPageGate,
  cancelGame,
  setFilters,
  setItem,
  setSortBy,
} from "src/store/admin";
import { SortEnumType } from "src/types/graphQlCommon";
import { DEFAULT_DATE_TIME_FORMAT } from "src/config/constants";
import PopConfirm from "src/components/common/PopConfirm";
import { getGameCancelReason, objFromArr } from "src/utils/helpers";
import Pagination from "src/components/common/Pagination";
import SelectComponent from "src/components/common/Select";
import { getValueFromHex } from "src/utils/ethers";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { IoCopyOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

interface IProps {}

const Games: FC<IProps> = () => {
  useGate(AdminPageGate);
  const { t } = useTranslation();
  const item = useStore($item);
  const [pageSize, setPageSize] = useState(10);
  const [whereFilters, setWhereFilters] = useState({});
  const [order, setOrder] = useState([{ startTime: SortEnumType.DESC }]);

  const filters = useStore($filters);
  const sortBy = useStore($sortBy);
  const dateRange = useStore($dateRange);
  const [startDate, endDate] = dateRange;

  let variables: any = useMemo(() => {
    if (!!Object.keys(whereFilters).length) {
      return {
        order,
        where: whereFilters,
      };
    }
    return {
      order,
    };
  }, [whereFilters, order]);

  const [getGames, { loading, data: auctionGames, fetchMore }] =
    useLazyQuery(ADMIN_GAMES);

  const games = auctionGames?.auctionGames?.nodes;
  const totalCount = auctionGames?.auctionGames?.totalCount || 0;
  const pageInfo = auctionGames?.auctionGames?.pageInfo;

  useEffect(() => {
    let order: any = [];
    !!sortBy.length &&
      sortBy.forEach((sorter: { id: string; desc: boolean }) => {
        if (sorter.id === "nFTInCustody") {
          order = [
            ...order,
            {
              nFTInCustody: {
                collection: {
                  contractName: sorter.desc
                    ? SortEnumType.DESC
                    : SortEnumType.ASC,
                },
              },
            },
          ];
        }
        if (sorter.id === "createdTime") {
          order = [
            ...order,
            {
              createdTime: sorter.desc ? SortEnumType.DESC : SortEnumType.ASC,
            },
          ];
        }
        if (sorter.id === "startTime") {
          order = [
            ...order,
            {
              startTime: sorter.desc ? SortEnumType.DESC : SortEnumType.ASC,
            },
          ];
        }
      });
    if (!!Object.keys(order).length) {
      setOrder(order);
    }
    if (sortBy.length === 0) {
      setOrder([{ startTime: SortEnumType.DESC }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  useEffect(() => {
    const filtersObj = objFromArr("id", filters, "value");
    const nFTInCustodyFilter =
      filtersObj["nFTInCustody.collection.collectionAddress"] ||
      filtersObj["nFTInCustody.owner"] ||
      filtersObj["nFTInCustody.tokenId"]
        ? {
            collection: filtersObj["nFTInCustody.collection.collectionAddress"]
              ? {
                  collectionAddress: {
                    contains:
                      filtersObj[
                        "nFTInCustody.collection.collectionAddress"
                      ].toLowerCase(),
                  },
                }
              : undefined,
            owner: filtersObj["nFTInCustody.owner"]
              ? { contains: filtersObj["nFTInCustody.owner"].toLowerCase() }
              : undefined,
            tokenId: filtersObj["nFTInCustody.tokenId"]
              ? {
                  eq: `0x${parseInt(
                    filtersObj["nFTInCustody.tokenId"],
                    10
                  ).toString(16)}`,
                }
              : undefined,
          }
        : undefined;
    const where = {
      ...whereFilters,
      nFTInCustody: nFTInCustodyFilter,
      or: filtersObj["nFTInCustody"]
        ? [
            {
              nFTInCustody: {
                name: { contains: filtersObj["nFTInCustody"] },
              },
            },
            {
              nFTInCustody: {
                collection: {
                  contractName: { contains: filtersObj["nFTInCustody"] },
                },
              },
            },
          ]
        : undefined,
    };
    // let where: any = variables?.where || {};
    // !!filters.length &&
    //   filters.forEach((filter: { id: string; value: string }) => {
    // if (filter.id === "nFTInCustody.collection.collectionAddress") {
    //   where = {
    //     ...where,
    //     nFTInCustody: {
    //       collection: { collectionAddress: { contains: filter.value } },
    //     },
    //   };
    // }
    // if (filter.id === "nFTInCustody.owner") {
    //   where = {
    //     ...where,
    //     nFTInCustody: {
    //       owner: { contains: filter.value },
    //     },
    //   };
    // }
    // if (filter.id === "nFTInCustody.tokenId") {
    //   where = {
    //     ...where,
    //     nFTInCustody: {
    //       tokenId: { eq: `0x${parseInt(filter.value, 10).toString(16)}` },
    //     },
    //   };
    // }
    // if (filter.id === "nFTInCustody") {
    //   where = {
    //     ...where,
    //     or: [
    //       {
    //         nFTInCustody: {
    //           name: { contains: filter.value },
    //         },
    //       },
    //       {
    //         nFTInCustody: {
    //           collection: {
    //             contractName: { contains: filter.value },
    //           },
    //         },
    //       },
    //     ],
    //   };
    // }
    // });
    // if (!!Object.keys(where).length) {
    //   setWhereFilters(where);
    // }
    // if (filters.length === 0) {
    //   setWhereFilters({});
    // }
    setWhereFilters(JSON.parse(JSON.stringify(where)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    let where: any = { ...whereFilters };
    if (startDate && endDate) {
      const gte = new Date(startDate);
      const lte = new Date(endDate);
      gte.setHours(0, 0, 0, 0);
      lte.setHours(24, 0, 0, 0);
      where = {
        ...where,
        or: [
          {
            createdTime: { gte, lte },
          },
          {
            startTime: { gte, lte },
          },
        ],
      };
      setWhereFilters(JSON.parse(JSON.stringify(where)));
    } else if (!startDate && !endDate) {
      where = {
        ...where,
        or: undefined,
      };
      setWhereFilters(JSON.parse(JSON.stringify(where)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    getGames({
      variables: {
        order: !!Object.keys(order).length ? order : undefined,
        where: !!Object.keys(whereFilters).length ? whereFilters : undefined,
        first: pageSize,
      },
      notifyOnNetworkStatusChange: true,
    });
  }, [pageSize, getGames, whereFilters, order]);

  useEffect(() => {
    if (item?.id && games) {
      const newItem = games.find(
        (i: any) => i.id === item.id && i.platformFee !== item.platformFee
      );
      newItem && setItem(newItem);
    }
  }, [games, item]);

  const gameStatuses = Object.values(GameStatus);

  const statusesFilterOptions = useMemo(
    () => [
      { label: t("dropdown.gameStatus.all"), value: "" },
      ...gameStatuses.map(status => ({
        label: t(`dropdown.gameStatus.${status}`),
        value: status,
      })),
    ],
    [gameStatuses, t]
  );

  const statusIndex = useMemo(
    () =>
      variables?.where?.status?.eq
        ? gameStatuses.indexOf(variables?.where?.status?.eq) + 1
        : 0,
    [gameStatuses, variables?.where?.status?.eq]
  );

  const data = React.useMemo(() => games || [], [games]);
  const columns: any = React.useMemo(
    () => [
      {
        Header: t("tables.games.nftAddress"),
        accessor: "nFTInCustody.collection.collectionAddress",
        disableSortBy: true,
        width: 200,
        maxWidth: 200,
        Cell: ({ value }: any) =>
          value ? (
            <CopyToClipboard
              text={value}
              onCopy={() =>
                toast.success(
                  t("notifications.copiedToClipboard", { item: "Address" })
                )
              }
            >
              <div className="admin-games__address">
                {value} <IoCopyOutline />
              </div>
            </CopyToClipboard>
          ) : null,
      },
      {
        Header: t("tables.games.nftImage"),
        accessor: "nFTInCustody.image280",
        Cell: ({ value }: any) => {
          return (
            <div className="admin-games__image-wrapper">
              <img src={value} alt="Collection" />
            </div>
          );
        },
        disableFilters: true,
        disableSortBy: true,
        width: 100,
        maxWidth: 100,
      },
      {
        Header: t("tables.games.nftId"),
        accessor: "nFTInCustody.tokenId",
        disableSortBy: true,
        width: 80,
        maxWidth: 80,
        Cell: ({ value }: any) => <>{getValueFromHex(value)}</>,
      },
      {
        Header: t("tables.games.name"),
        accessor: "nFTInCustody",
        width: 100,
        maxWidth: 100,
        // Cell: ({ value }: any) => (
        //   <div className="">
        //     {value.collection.contractName}: {value.name}
        //   </div>
        // ),
        Cell: ({ value, row }: any) =>
          row?.original && value ? (
            <Link
              style={{ fontWeight: 500 }}
              to={`/admin/game/${row.original.id}/analytics`}
            >
              {value.collection.contractName}: {value.name}
            </Link>
          ) : null,
      },
      {
        Header: t("tables.games.provider"),
        accessor: "creator",
        disableSortBy: true,
        width: 200,
        maxWidth: 200,
        Cell: ({ value }: any) =>
          value ? (
            <CopyToClipboard
              text={value}
              onCopy={() =>
                toast.success(
                  t("notifications.copiedToClipboard", { item: "Address" })
                )
              }
            >
              <div className="admin-games__address">
                {value} <IoCopyOutline />
              </div>
            </CopyToClipboard>
          ) : null,
      },
      {
        Header: t("tables.games.createdTime"),
        accessor: "createdTime",
        Cell: ({ value }: any) => (
          <div className="">
            {dayjs(value).format(DEFAULT_DATE_TIME_FORMAT)}
          </div>
        ),
        disableFilters: true,
        width: 120,
        maxWidth: 120,
      },
      {
        Header: t("tables.games.startTime"),
        accessor: "startTime",
        Cell: ({ value }: any) => (
          <div className="">
            {dayjs(value).format(DEFAULT_DATE_TIME_FORMAT)}
          </div>
        ),
        disableFilters: true,
        width: 120,
        maxWidth: 120,
      },
      {
        Header: t("tables.games.status"),
        accessor: "status",
        Filter: () => (
          <SelectComponent
            options={statusesFilterOptions}
            onChange={(option: any) =>
              setWhereFilters(where => ({
                ...where,
                status:
                  option?.value === ""
                    ? { in: gameStatuses }
                    : { eq: option?.value },
              }))
            }
            className="admin-filter-select"
            defaultValueIndex={statusIndex}
          />
        ),
        // filter: "includes",
        disableSortBy: true,
        width: 120,
        maxWidth: 120,
        Cell: ({ value, row }: any) => (
          <>
            {value}{" "}
            {value === GameStatus.CANCELED &&
              row?.original?.cancelReason &&
              getGameCancelReason(row?.original?.cancelReason)}
          </>
        ),
      },
      {
        Header: t("tables.games.actions"),
        accessor: "game",
        disableFilters: true,
        disableSortBy: true,
        width: 70,
        maxWidth: 70,
        Cell: ({ row }: any) => (
          <>
            <Button
              className="admin-games__action"
              onClick={() => {
                setItem(row?.original);
                setModal("gameDetails");
              }}
            >
              {t("buttons.details")}
            </Button>
            {(row?.original?.status === GameStatus.CREATED ||
              row?.original?.status === GameStatus.STARTED) && (
              <PopConfirm
                onConfirm={() => cancelGame(row?.original?.id)}
                title={t("popConfirms.cancelGame")}
                className="admin-collections__delete-pop-confirm"
              >
                <Button
                  className="admin-games__action admin-games__action_cancel"
                  loading={item === row?.original?.id}
                  disabled={item === row?.original?.id}
                >
                  {t("buttons.cancel")}
                </Button>
              </PopConfirm>
            )}
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // if (loading || !data) {
  //   return <Loader className="page-loader" />;
  // }

  return (
    <div className="admin-games">
      <div className="admin-games__header">
        <div className="admin-games__title">Games</div>
      </div>
      <div className="admin-games__table-wrap">
        <Table
          columns={columns}
          data={data}
          setFilters={setFilters}
          setSortBy={setSortBy}
          loading={loading}
          pageSize={pageSize}
        />
        {totalCount > 0 && (
          <Pagination
            pageSize={pageSize}
            totalCount={totalCount}
            pageInfo={pageInfo}
            fetchMore={fetchMore}
            variables={variables}
            setPageSize={setPageSize}
          />
        )}
      </div>
    </div>
  );
};

export default Games;
