import { forward } from "effector";
import { checkConnectWallet } from "../wallet";
import { checkAuth } from "../profile";
import { $modal, checkWalletConnectOnInit } from "src/store/app";
import { register } from "src/store/profile";
import { deposit, withdraw } from "src/store/wallet";
import { importCollection, setRoyalty } from "src/store/collections";
import {
  bulkNftUpload,
  transferNft,
  transferNftsToOtherUser,
} from "src/store/nft";
import { bid, joinGame, sendReport } from "src/store/games";
import {
  addCollectionToWhitelist,
  cancelGame,
  changeCollectionImage,
  updateMediaLink,
} from "src/store/admin";

forward({
  from: checkWalletConnectOnInit,
  to: [checkConnectWallet, checkAuth],
});

$modal.reset([
  transferNft.doneData,
  sendReport.doneData,
  importCollection.doneData,
  setRoyalty.doneData,
  register.doneData,
  deposit.doneData,
  bid.doneData,
  withdraw.doneData,
  addCollectionToWhitelist.doneData,
  cancelGame.doneData,
  updateMediaLink.done,
  joinGame.done,
  bulkNftUpload.done,
  changeCollectionImage.done,
  transferNftsToOtherUser.done,
]);
