import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import "./index.scss";
import Layout from "src/components/common/Layout";
import { Link } from "react-router-dom";

const ForCollections = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const block1List = [
    t("pages.forCollections.block1.point1"),
    t("pages.forCollections.block1.point2"),
    t("pages.forCollections.block1.point3"),
    t("pages.forCollections.block1.point4"),
    t("pages.forCollections.block1.point5"),
    t("pages.forCollections.block1.point6"),
    t("pages.forCollections.block1.point7"),
    t("pages.forCollections.block1.point8"),
    t("pages.forCollections.block1.point9"),
    t("pages.forCollections.block1.point10"),
    t("pages.forCollections.block1.point11"),
  ];

  return (
    <Layout className="gradient-layout">
      <div className="for-collections">
        <div className="for-collections__title">
          {t("pages.forCollections.title")}
        </div>
        <div className="for-collections__blocks">
          <div className="for-collections__block">
            <div className="for-collections__block-title">
              {t("pages.forCollections.block1.title")}
            </div>
            <ul className="for-collections__block-text">
              {block1List.map(i => (
                <li key={i}>{i}</li>
              ))}
            </ul>
          </div>
          <div className="for-collections__block">
            <div className="for-collections__block-title">
              {t("pages.forCollections.block2.title")}
            </div>
            <ul className="for-collections__block-text">
              <li>
                <Trans i18nKey="pages.forCollections.block2.point1">
                  Depositing Multiple NFTs on Puzzled.
                  <p style={{ margin: 0 }}>
                    Puzzled allows approved users, especially approved
                    collection owners, the ability to deposit multiple NFTs from
                    a single collection at a time. It is required to have your
                    collection smart contract address number and a list of id
                    numbers of the NFTs you wish to deposit.
                  </p>
                </Trans>
              </li>
              <li style={{ margin: "10px 0" }}>
                <Trans i18nKey="pages.forCollections.block2.point2">
                  Creating Multiple Games at a time on Puzzled.
                  <p style={{ margin: 0 }}>
                    Puzzled allows approved users the ability to create multiple
                    games from the NFTs they have already custodied on Puzzled.
                    Select the NFTs and then put them in the order you want to
                    create games for them. You will need to set the Start Time
                    for the first game and the interval between this game and
                    the following game.
                  </p>
                </Trans>
              </li>
              <li>
                <Trans i18nKey="pages.forCollections.block2.point3">
                  Transferring Multiple NFTs on Puzzled.
                  <p style={{ margin: 0 }}>
                    Puzzled allows approved users the ability to transfer
                    multiple NFTs from the NFTs they have already custodied on
                    Puzzled. Select the NFTs and then paste the address of the
                    user on Puzzled you wish to send these NFTs.
                  </p>
                </Trans>
              </li>
            </ul>
          </div>
          <div className="for-collections__block">
            <div className="for-collections__block-text">
              <Trans i18nKey="pages.forCollections.contactUs">
                Just
                <Link to="/contact-us">contact us</Link>
                to request access to one or more of these abilities.
              </Trans>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ForCollections;
