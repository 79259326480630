import React, { useState } from "react";
import cs from "classnames";
import { useGate, useStore } from "effector-react";
import { useTranslation } from "react-i18next";

import "./index.scss";
import Button from "../../common/Button";
import HelpInfoIcon from "../../common/HelpInfoIcon";
import { CollectionGate } from "../../../store/collections";
import { $nftIds, $nftList, transferNftsToOtherUser } from "src/store/nft";

const TransferNftsToOtherUser = () => {
  useGate(CollectionGate);
  const { t } = useTranslation();
  const nftList = useStore($nftList);
  const nftIds = useStore($nftIds);
  const [to, setTo] = useState("");
  const loading = useStore(transferNftsToOtherUser.pending);
  const isBulk = nftIds.length > 1;

  const confirmTransfer = () => {
    transferNftsToOtherUser({ to, nftIds });
  };

  const title = isBulk
    ? t("modals.transferNftsToUser.title")
    : t("modals.transferNftToUser.title");

  const addressLabel = isBulk
    ? t("modals.transferNftsToUser.addressLabel")
    : t("modals.transferNftToUser.addressLabel");

  const addressPlaceholder = isBulk
    ? t("modals.transferNftsToUser.addressPlaceholder")
    : t("modals.transferNftToUser.addressPlaceholder");

  const addressTooltip = isBulk
    ? t("tooltips.transferNftsToUser.address")
    : t("tooltips.transferNftToUser.address");

  const confirmBtn = isBulk
    ? t("modals.transferNftsToUser.confirmBtn")
    : t("modals.transferNftToUser.confirmBtn");

  return (
    <div className="transfer-nfts-to-other-user">
      <div className="transfer-nfts-to-other-user__title">{title}</div>
      <div className="transfer-nfts-to-other-user__nfts-container">
        {nftList.map(nft => (
          <div
            key={nft.id}
            className="transfer-nfts-to-other-user__nft-image-container"
          >
            <img src={nft.image280} alt="NFT" />
          </div>
        ))}
      </div>
      <div className="transfer-nfts-to-other-user__field">
        <div className="transfer-nfts-to-other-user__field-label">
          {addressLabel}
          <HelpInfoIcon bold data-tip={addressTooltip} data-multiline />
        </div>
        <input
          value={to}
          onChange={e => setTo(e.target.value)}
          className={cs("transfer-nfts-to-other-user__field-value")}
          placeholder={addressPlaceholder}
        />
      </div>
      <Button
        className="transfer-nfts-to-other-user__btn"
        onClick={confirmTransfer}
        loading={loading}
        disabled={loading}
        walletRequired
        ignoreChain
      >
        {confirmBtn}
      </Button>
    </div>
  );
};

export default TransferNftsToOtherUser;
