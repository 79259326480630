import React from "react";
import Logo from "../common/Logo";
import "./index.scss";

const MobileDemoApp = () => {
  return (
    <div className="mobile-demo-app">
      <Logo className="mobile-demo-app__logo" />
      <div>
        Mobile browser version still under development
        <br />
        <br />
        Please access Puzzled using a desktop browser
      </div>
    </div>
  );
};

export default MobileDemoApp;
