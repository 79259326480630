import React, { FC } from "react";
import {
  FaDiscord,
  // FaInstagram,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
// import { AiFillMediumCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import cs from "classnames";

import "./index.scss";
import { socialsLinks } from "src/config/links";

const socials = [
  {
    link: socialsLinks.twitter,
    icon: <FaTwitter />,
  },
  // {
  //   link: socialsLinks.telegram,
  //   icon: <FaTelegram />,
  // },
  {
    link: socialsLinks.discord,
    icon: <FaDiscord />,
  },
  // {
    // link: socialsLinks.reddit,
    // icon: <FaReddit />,
  // },
  {
    link: socialsLinks.youtube,
    icon: <FaYoutube />,
  },
  // {
  //   link: "#!",
  //   icon: <FaInstagram />,
  // },
  // {
  //   link: "#!",
  //   icon: <AiFillMediumCircle />,
  // },
];

interface IProps {
  className?: string;
}

const Socials: FC<IProps> = ({ className }) => {
  return (
    <div className={cs("socials", className)}>
      {socials.map((i, ind) => (
        <a
          key={ind}
          className="socials__item"
          href={i.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {i.icon}
        </a>
      ))}
      {/* <a
        className="socials__newsletter"
        href={socialsLinks.newsletter}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t("newsletter")}
      </a> */}
    </div>
  );
};

export default Socials;
