import React, { FC, Fragment, useEffect } from "react";
// import {
//   BsChevronDoubleLeft,
//   BsChevronDoubleRight,
//   BsChevronLeft,
//   BsChevronRight,
// } from "react-icons/bs";
import { MdOutlineFilterList } from "react-icons/md";
import {
  useExpanded,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useTranslation } from "react-i18next";

import "./index.scss";

interface IProps {
  columns: [];
  data: [];
  setFilters?: (filters: any) => void;
  setSortBy?: (filters: any) => void;
  loading?: boolean;
  pageSize: number;
  initFilters?: { id: string; value: string }[];
}

function DefaultColumnFilter({ column: { filterValue, setFilter } }: any) {
  const { t } = useTranslation();

  return (
    <input
      value={filterValue || ""}
      onChange={e => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={t("table.defaultFilters")}
      className="table__filter-input"
    />
  );
}

const Table: FC<IProps> = ({
  columns,
  data,
  setFilters,
  setSortBy,
  // fetchData,
  loading,
  pageSize,
  initFilters,
  // pageCount: controlledPageCount,
  // typeFilter = "",
  // statusFilter = "created",
  // totalItems = 0,
  // addressFilter = "",
  // sortBy = null,
}) => {
  const { t } = useTranslation();
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // pageCount,
    // gotoPage,
    // nextPage,
    // previousPage,
    // setPageSize,
    state: { filters, sortBy },
    visibleColumns,
    setAllFilters,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize,
      },
      manualFilters: true,
      manualSortBy: true,
      // disableMultiSort: false,
      manualPagination: true,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  // useEffect(() => {
  //   const res =
  //     propsFilters &&
  //     propsFilters.filter(
  //       (f1: any) =>
  //         !filters.find(f2 => f1.id === f2.id && f1.value === f2.value)
  //     );
  //   console.log({ propsFilters, filters, res });
  //   // if (propsFilters) {
  //   // }
  // }, [propsFilters]);
  useEffect(() => {
    initFilters && setAllFilters(initFilters);
  }, [initFilters, setAllFilters]);

  useEffect(() => {
    setFilters && setFilters(filters);
    // console.log("Table filters", filters);
  }, [filters, setFilters]);

  useEffect(() => {
    setSortBy && setSortBy(sortBy);
    // console.log("Table sortBy", sortBy);
  }, [sortBy, setSortBy]);

  return (
    <>
      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  {...column.getHeaderProps({
                    style: {
                      minWidth: column.minWidth,
                      width: column.width,
                      maxWidth: column.maxWidth,
                    },
                  })}
                >
                  {column.render("Header")}
                  <span className="table__order-icon">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <MdOutlineFilterList />
                      ) : (
                        <MdOutlineFilterList
                          style={{ transform: "rotate(180deg)" }}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {!loading && (
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <Fragment key={i}>
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td
                          {...cell.getCellProps({
                            style: {
                              minWidth: cell.column.minWidth,
                              width: cell.column.width,
                              maxWidth: cell.column.maxWidth,
                            },
                          })}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                  {row.isExpanded ? (
                    <tr>
                      <td colSpan={visibleColumns.length}>
                        {/* {renderRowSubComponent({ row })} */}
                      </td>
                    </tr>
                  ) : null}
                </Fragment>
              );
            })}
          </tbody>
        )}
      </table>
      {loading && <div className="table-loader">{t("table.loading")}</div>}
      {!loading && data.length === 0 && (
        <div className="table-empty">{t("table.notFound")}</div>
      )}
      {/* <div className="table-pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          <BsChevronDoubleLeft />
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          <BsChevronLeft />
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          <BsChevronRight />
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          <BsChevronDoubleRight />
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          Go to page{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          />
        </span>{" "}
        Show
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
      </div> */}
    </>
  );
};

export default Table;
