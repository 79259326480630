import web3 from "web3";
import { ethers, BigNumber } from "ethers";
// import {
//   Multicall,
//   ContractCallResults,
//   ContractCallContext,
// } from "ethereum-multicall";

import {
  changeAccount,
  checkConnectWallet,
  setChainId,
} from "src/store/wallet";
import { logout } from "src/store/profile";
import { checkWalletConnectOnInit } from "src/store/app";
// import Erc721Abi from "src/contracts/Erc721Abi.json";

export const provider = window.ethereum
  ? new ethers.providers.Web3Provider(window.ethereum, "any")
  : null;
export const signer = provider ? provider.getSigner() : null;

export const ethersInit = (params: { disableReload?: boolean } | void) => {
  window?.ethereum?.on("accountsChanged", async ([account]: string[]) => {
    try {
      console.log("accountsChanged");
      changeAccount(account);
      await checkConnectWallet();
      if (!params?.disableReload) {
        logout();
        window.location.href = "/";
      }
      // await checkAuth();
    } catch (err) {
      console.log("accountsChanged error", err);
    }
  });
  provider?.on("network", (newNetwork, oldNetwork) => {
    // console.log("newNetwork", newNetwork, oldNetwork);
    if (!oldNetwork && newNetwork) {
      checkWalletConnectOnInit();
    }
    if (oldNetwork && newNetwork && oldNetwork.chainId !== newNetwork.chainId) {
      setChainId(newNetwork.chainId.toString());
    }
    // if (oldNetwork) {
    //   window.location.reload();
    // }
  });
  // provider.on("block", (blockNumber) => {
  //   console.log("new block", blockNumber);
  //   isValidNetwork() && fetchPairsFx();
  // });
};

export const getNumberValueFromHex = (value?: string) =>
  !value ? 0 : +web3.utils.fromWei(BigNumber.from(value).toString());

export const getValueFromHex = (value?: string) =>
  !value ? "" : BigNumber.from(value).toString();

// export const test = async () => {
//   if (!provider) {
//     return;
//   }
//   const multicall = new Multicall({
//     ethersProvider: provider,
//     tryAggregate: true,
//   });

//   const contractCallContext: ContractCallContext[] = [
//     {
//       reference: "testContract",
//       contractAddress: "0xe78927c5f03cec6d1c5217a958c159b1e42b9343",
//       abi: Erc721Abi,
//       calls: [
//         {
//           reference: "safeTransferFrom",
//           methodName: "safeTransferFrom(address,address,uint256)",
//           methodParameters: [
//             "0xab3d19A29a4a95E51abAeE18Bba2DFb62e4ddBb8",
//             "0x2b11F90FB732197B11A51C402F6E78c901Fc0D1F",
//             1,
//           ],
//         },
//         // {
//         //   reference: "safeTransferFrom",
//         //   methodName: "safeTransferFrom(address,address,uint256)",
//         //   methodParameters: ["0xab3d19A29a4a95E51abAeE18Bba2DFb62e4ddBb8"],
//         // },
//       ],
//     },
//   ];

//   const results: ContractCallResults =
//     multicall && (await multicall.call(contractCallContext));
//   console.log(results);
// };

// (async () => setTimeout(() => test(), 3000))();
