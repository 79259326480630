import React, { FC, ReactNode } from "react";
import cs from "classnames";

import "./index.scss";

interface IProps {
  text?: string;
  className?: string;
  content?: ReactNode;
}

const EmptyPlaceholder: FC<IProps> = ({ text, content, className }) => {
  return (
    <div className={cs("empty-placeholder", className)}>{content || text}</div>
  );
};

export default EmptyPlaceholder;
