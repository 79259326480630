import { toast } from "react-toastify";

import { apolloClient } from "src/graphQl";
import { ADMIN_GAMES } from "src/graphQl/auctionGames";
import { NFT_COLLECTIONS_ADMIN } from "src/graphQl/nftCollections";
import i18n from "src/i18n";
import AdminService from "src/services/AdminService";
import {
  addCollectionToWhitelist,
  cancelGame,
  changeCollectionImage,
  deleteCollectionFromWhitelist,
  setItem,
  setPlatformFee,
  updateMediaLink,
} from "src/store/admin";
import { effectErrorHandler, isValidUrl } from "src/utils/helpers";
import { setModal } from "../app";

setPlatformFee.use(async ({ gameId, fee }) => {
  try {
    await AdminService.setPlatformFee(gameId, fee);
    await apolloClient.refetchQueries({
      include: [ADMIN_GAMES],
    });
    toast.success(i18n.t("notifications.admin.platformFeeChanged"));
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

cancelGame.use(async gameId => {
  try {
    setModal(null);
    setItem(gameId);
    await AdminService.cancelGame(gameId);
    await apolloClient.refetchQueries({
      include: [ADMIN_GAMES],
    });
    setItem(null);
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

addCollectionToWhitelist.use(async params => {
  try {
    if (params.discordCommunity && !isValidUrl(params.discordCommunity)) {
      throw Error(i18n.t("notifications.admin.invalidURL"));
    }
    if (params.twitterCommunity && !isValidUrl(params.twitterCommunity)) {
      throw Error(i18n.t("notifications.admin.invalidURL"));
    }
    await AdminService.addCollectionToWhitelist(params);
    await apolloClient.refetchQueries({
      include: [NFT_COLLECTIONS_ADMIN],
    });
    toast.success(i18n.t("notifications.admin.collectionAdded"));
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

deleteCollectionFromWhitelist.use(async collectionId => {
  try {
    setItem(collectionId);
    await AdminService.deleteCollectionFromWhitelist(collectionId);
    // apolloClient.cache.updateQuery({ query: NFT_COLLECTIONS }, data => ({
    //   nodes: data.nodes.filter(
    //     (collection: INftCollection) => collection.id !== collectionId
    //   ),
    // }));
    apolloClient.cache.modify({
      fields: {
        nFTCollection(existingRefs, { readField }) {
          return {
            ...existingRefs,
            nodes: existingRefs?.nodes?.filter(
              (collectionRef: any) =>
                collectionId !== readField("id", collectionRef)
            ),
            totalCount: existingRefs?.totalCount - 1,
          };
        },
      },
    });
    // await apolloClient.refetchQueries({
    //   include: [NFT_COLLECTIONS],
    // });
    setItem(null);
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

updateMediaLink.use(async params => {
  try {
    if (params.mediaLink && !isValidUrl(params.mediaLink)) {
      throw Error(i18n.t("notifications.admin.invalidMediaLink"));
    }
    if (params.field === "discordCommunity") {
      await AdminService.updateCollectionDiscordLink(params);
    } else if (params.field === "twitterCommunity") {
      await AdminService.updateCollectionTwitterLink(params);
    }
    // await AdminService.updateCollectionMediaLink(params);
    await apolloClient.refetchQueries({
      include: [NFT_COLLECTIONS_ADMIN],
    });
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});

changeCollectionImage.use(async params => {
  try {
    await AdminService.changeCollectionImage(params);
    await apolloClient.refetchQueries({
      include: [NFT_COLLECTIONS_ADMIN],
    });
    toast.success(i18n.t("notifications.admin.collectionImageChanged"));
  } catch (err) {
    const errMsg = effectErrorHandler(err);
    throw Error(errMsg);
  }
});
