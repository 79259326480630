import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import cs from "classnames";

import "./index.scss";

const CustomInput = forwardRef(
  (
    props: React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const { t } = useTranslation();

    return (
      <button
        className={cs("datepicker-button", props.className)}
        onClick={props.onClick}
        ref={ref}
      >
        {props.value || props.placeholder || t("games.dateRangePlaceholder")}
      </button>
    );
  }
);

export default CustomInput;
