import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { TChainId } from "../../../types/common";
import { IUnconfirmedTx } from "../../../types/wallet";
import networksConfig from "../../../utils/networksConfig";
import Loader from "../Loader";
import "./index.scss";

interface WaitingTxConfirmationProps {
  tx: IUnconfirmedTx;
  bottom: string;
}

const WaitingTxConfirmation: FC<WaitingTxConfirmationProps> = ({
  tx,
  bottom,
}) => {
  const { t } = useTranslation();

  const { blockExplorer } = networksConfig[tx.chainId as TChainId];
  const url = `${blockExplorer}/tx/${tx.hash}`;
  return (
    <div className="waiting-tx-confirmation" style={{ bottom }}>
      <Loader className="waiting-tx-confirmation__loader" color="#000" />
      <div className="waiting-tx-confirmation__text">
        {t("waitingTxConfirmation.txInProgress")}
      </div>
      <div className="waiting-tx-confirmation__link">
        <a href={url} target="_blank" rel="noreferrer noopener">
          {t("waitingTxConfirmation.viewOnExplorer")}
        </a>
      </div>
    </div>
  );
};

export default WaitingTxConfirmation;
