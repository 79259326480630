import React, { FC } from "react";
import { RiTimerLine } from "react-icons/ri";
import cs from "classnames";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import "./index.scss";
import CountdownComponent from "../../common/Countdown";
import { IAuctionGameLot } from "src/graphQl/auctionGameLots";
import { ReactComponent as PolygonIcon } from "src/assets/PolygonIcon.svg";
import { ReactComponent as ArbitrumIcon } from "src/assets/ArbitrumIcon.svg";
import { POLYGON_CHAIN_ID, ARBITRUM_CHAIN_ID } from "src/config";

interface IProps {
  item: IAuctionGameLot;
  className?: string;
  onlyImage?: boolean;
  numberOfPieces: number;
  image: string;
}

const MarketPuzzle: FC<IProps> = props => {
  const { t } = useTranslation();
  const { className, item, onlyImage, numberOfPieces, image } = props;
  const { piece, currentBid, winTime } = item;

  const cellRowCount = Math.sqrt(numberOfPieces);
  const row = Math.ceil((piece + 1) / cellRowCount) - 1;
  const col = piece % cellRowCount;

  const top = row * 150;
  const left = col * 150;

  // const highestBidWinTimeValue = IS_DEMO
  //   ? Date.now() + 1000 * 6
  //   : +getValueFromHex(highestBidWinTime) * 1000;

  // const highestBidValue = IS_DEMO ? 120 : +getNumberValueFromHex(currentBid);

  return (
    <div className={cs("market-puzzle", className)}>
      <div
        className="market-puzzle__image"
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: `${cellRowCount * 150}px ${cellRowCount * 150}px`,
          backgroundPositionX: -left,
          backgroundPositionY: -top,
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="market-puzzle__index">{piece + 1}</div>
      </div>
      {!onlyImage && winTime && (
        <div className="market-puzzle__description">
          <div className="market-puzzle__description-timer">
            <RiTimerLine />
            <CountdownComponent
              date={new Date(winTime).getTime()}
              renderer={({ days, hours, minutes, seconds }) => (
                <div className="market-puzzle__countdown">
                  {days > 0 ? `${days}d` : null}{" "}
                  {hours > 0 ? `${hours}h` : null}{" "}
                  {minutes > 0 ? `${minutes}m` : null} {seconds}s
                </div>
              )}
              onComplete={() => {
                toast.success(
                  <span>
                    {t("notifications.game.pieceSold", { piece, currentBid })}{" "}
                    {POLYGON_CHAIN_ID && <PolygonIcon className="market-puzzle__notification-icon" />}
                    {ARBITRUM_CHAIN_ID && <ArbitrumIcon className="market-puzzle__notification-icon _not-fill" />}
                  </span>
                );
              }}
            />
          </div>
          <div className="market-puzzle__description-price">
            {POLYGON_CHAIN_ID && <PolygonIcon className="market-puzzle__price-icon" />}
            {ARBITRUM_CHAIN_ID && <ArbitrumIcon className="market-puzzle__price-icon _not-fill" />}
            {currentBid}
          </div>
        </div>
      )}
    </div>
  );
};

export default MarketPuzzle;
