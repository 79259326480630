import React, { FC } from "react";
import Select from "react-select";
import cs from "classnames";

import { SelectOption } from "../../../types/common";
import "./index.scss";

interface SelectProps {
    options: SelectOption[];
    isDisabled?: boolean;
    isLoading?: boolean;
    onChange?: (option: SelectOption | SelectOption[] | null) => any;
    className?: string;
    defaultValueIndex?: number;
    value?: SelectOption;
    placeholder?: string | number;
}

const SelectComponent: FC<SelectProps> = (props) => {
    const {
        options,
        isDisabled = false,
        isLoading = false,
        onChange,
        className,
        defaultValueIndex = 0,
        value,
        placeholder,
    } = props;

    const formatGroupLabel = () => <div>Sort by</div>;

    return (
        <div className={cs("select-component", className)}>
            <Select
                placeholder={placeholder}
                options={options}
                className="select-component__select"
                classNamePrefix="select-component"
                defaultValue={options[defaultValueIndex]}
                value={value}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={false}
                isRtl={false}
                isSearchable={false}
                onChange={onChange}
                formatGroupLabel={formatGroupLabel}
            />
        </div>
    );
};

export default SelectComponent;
